import {
  BRANCH_GUID,
  BRANCH_TYPE,
  BRANCH_NAME,
  BRANCH_GUIDS,
  CURRENT_BRANCH,
  BRANCH_LOWERCASE,
  BRANCH_CAPITALIZE,
  OWNER_BRANCH_GUID,
  DRAFT_BRANCH_GUID,
  OWNING_BRANCH_GUID,
  SHARED_TO_BRANCHES,
  CURRENT_BRANCH_GUID,
  GRANTED_BRANCH_GUID,
} from './branch';
import {
  LOAD_TYPE_CLO,
  LOAD_TYPE_TEL,
  STOP_STATUS_TYPE_ARRIVED,
  STOP_STATUS_TYPE_COMPLETED,
  STOP_STATUS_TYPE_CHECKED_IN,
  ACCESSORIAL_DISTRICT_TYPE_US,
  ACCESSORIAL_DISTRICT_TYPE_MIDWEST,
  ACCESSORIAL_DISTRICT_TYPE_EAST_COST,
  ACCESSORIAL_DISTRICT_TYPE_GULF_COAST,
  ACCESSORIAL_DISTRICT_TYPE_WEST_COAST,
  ACCESSORIAL_DISTRICT_TYPE_CALIFORNIA,
  ACCESSORIAL_DISTRICT_TYPE_NEW_ENGLAND,
  ACCESSORIAL_DISTRICT_TYPE_ROCKY_MOUNTAIN,
  ACCESSORIAL_DISTRICT_TYPE_LOWER_ATLANTIC,
  ACCESSORIAL_DISTRICT_TYPE_CENTRAL_ATLANTIC,
  ACCESSORIAL_DISTRICT_TYPE_WEST_COAST_EXCEPT_CALIFORNIA,
} from './types';
//////////////////////////////////////////////////

export const FIELD_TO = 'to';
export const FIELD_ID = 'id';
export const FIELD_CLO = 'clo';
export const FIELD_URI = 'uri';
export const FIELD_UOM = 'uom';
export const FIELD_TEL = 'tel';
export const FIELD_EDI = 'edi';
export const FIELD_HOT = 'hot';
export const FIELD_DBA = 'dba';
export const FIELD_KEY = 'key';
export const FIELD_COST = 'cost';
export const FIELD_GVWT = 'gvwt';
export const FIELD_YEAR = 'year';
export const FIELD_SYNC = 'sync';
export const FIELD_CLOS = 'clos';
export const FIELD_LAST = 'last';
export const FIELD_FROM = 'from';
export const FIELD_NEXT = 'next';
export const FIELD_LOAD = 'load';
export const FIELD_NAME = 'name';
export const FIELD_TYPE = 'type';
export const FIELD_DATE = 'date';
export const FIELD_CODE = 'code';
export const FIELD_NOTE = 'note';
export const FIELD_HOST = 'host';
export const FIELD_SCAC = 'scac';
export const FIELD_USED = 'used';
export const FIELD_PORT = 'port';
export const FIELD_STOP = 'stop';
export const FIELD_LATE = 'late';
export const FIELD_FEES = 'fees';
export const FIELD_PARTS = 'parts';
export const FIELD_WIDTH = 'width';
export const FIELD_LOGIN = 'login';
export const FIELD_PRICE = 'price';
export const FIELD_ORDER = 'order';
export const FIELD_STOPS = 'stops';
export const FIELD_EMAIL = 'email';
export const FIELD_NOTES = 'notes';
export const FIELD_SCOPE = 'scope';
export const FIELD_TITLE = 'title';
export const FIELD_ERROR = 'error';
export const FIELD_FILES = 'files';
export const FIELD_APP_ID = 'appId';
export const FIELD_LABORS = 'labors';
export const FIELD_EMAILS = 'emails';
export const FIELD_AMOUNT = 'amount';
export const FIELD_SHARED = 'shared';
export const FIELD_HEIGHT = 'height';
export const FIELD_LENGTH = 'length';
export const FIELD_QUOTES = 'quotes';
export const FIELD_ERRORS = 'errors';
export const FIELD_FILTER = 'filter';
export const FIELD_ORIGIN = 'origin';
export const FIELD_ISSUES = 'issues';
export const FIELD_ENTITY = 'entity';
export const FIELD_STATUS = 'status';
export const FIELD_REASON = 'reason';
export const FIELD_SOURCE = 'source';
export const FIELD_PINNED = 'pinned';
export const FIELD_FORMAT = 'format';
export const FIELD_DOMAIN = 'domain';
export const FIELD_PROFIT = 'profit';
export const FIELD_TRUCKS = 'trucks';
export const FIELD_BILL_TO = 'billTo';
export const FIELD_GL_CODE = 'glCode';
export const FIELD_UNIT_ID = 'unitId';
export const FIELD_API_KEY = 'apiKey';
export const FIELD_TRIP_ID = 'tripId';
export const FIELD_SENT_BY = 'sentBy';
export const FIELD_DATE_TO = 'dateTo';
export const FIELD_MAIL_TO = 'mailTo';
export const FIELD_CHECKED = 'checked';
export const FIELD_SUMMARY = 'summary';
export const FIELD_DRIVERS = 'drivers';
export const FIELD_ENABLED = 'enabled';
export const FIELD_FILTERS = 'filters';
export const FIELD_DEFAULT = 'default';
export const FIELD_CHARGES = 'charges';
export const FIELD_OPTIONS = 'options';
export const FIELD_PAYABLE = 'payable';
export const FIELD_LIMITED = 'limited';
export const FIELD_BALANCE = 'balance';
export const FIELD_DUE_DATE = 'dueDate';
export const FIELD_GROUP_ID = 'groupId';
export const FIELD_NET_DAYS = 'netDays';
export const FIELD_TEL_GUID = 'telGuid';
export const FIELD_TEL_RATE = 'telRate';
export const FIELD_CLO_RATE = 'cloRate';
export const FIELD_CLO_GUID = 'cloGuid';
export const FIELD_VALUE_TO = 'valueTo';
export const FIELD_FILE_URI = 'fileUri';
export const FIELD_END_DATE = 'endDate';
export const FIELD_QUOTE_ID = 'quoteId';
export const FIELD_OWNER_ID = 'ownerId';
export const FIELD_ISSUE_ID = 'issueId';
export const FIELD_AUTH_KEY = 'authKey';
export const FIELD_SUB_TYPE = 'subType';
export const FIELD_PRIORITY = 'priority';
export const FIELD_SELECTED = 'selected';
export const FIELD_DISTANCE = 'distance';
export const FIELD_PASSWORD = 'password';
export const FIELD_USERNAME = 'username';
export const FIELD_EXCLUDED = 'excluded';
export const FIELD_PAYMENTS = 'payments';
export const FIELD_ASSIGNEE = 'assignee';
export const FIELD_RESERVED = 'reserved';
export const FIELD_SERVICES = 'services';
export const FIELD_START_TLS = 'startTls';
export const FIELD_DATE_FROM = 'dateFrom';
export const FIELD_SENT_DATE = 'sentDate';
export const FIELD_MC_NUMBER = 'mcNumber';
export const FIELD_LOAD_GUID = 'loadGuid';
export const FIELD_LAST_DROP = 'lastDrop';
export const FIELD_DATE_TIME = 'dateTime';
export const FIELD_FILE_NAME = 'fileName';
export const FIELD_ICON_TYPE = 'iconType';
export const FIELD_MAX_PRICE = 'maxPrice';
export const FIELD_MIN_PRICE = 'minPrice';
export const FIELD_LAST_NAME = 'lastName';
export const FIELD_NICK_NAME = 'nickName';
export const FIELD_CLIENT_ID = 'clientId';
export const FIELD_CLO_COUNT = 'cloCount';
export const FIELD_TEL_COUNT = 'telCount';
export const FIELD_FULL_NAME = 'fullName';
export const TOTAL_INCOME = 'totalIncome';
export const FIELD_SENDER_ID = 'senderId';
export const FIELD_TENANT_ID = 'tenantId';
export const FIELD_SYSTEM_ID = 'systemId';
export const FIELD_EXPORT_AS = 'exportAs';
export const FIELD_WIDTH_UOM = 'widthUom';
export const FIELD_PAYED_FOR = 'payedFor';
export const FIELD_OPERATION = 'operation';
export const FIELD_AVAILABLE = 'available';
export const FIELD_DOCUMENTS = 'documents';
export const FIELD_COMPLETED = 'completed';
export const FIELD_DIRECTION = 'direction';
export const FIELD_SUSPENDED = 'suspended';
export const FIELD_SUB_STATUS = 'subStatus';
export const FIELD_TOLL_TOTAL = 'tollTotal';
export const FIELD_FUEL_TOTAL = 'fuelTotal';
export const FIELD_DRIVER_PAY = 'driverPay';
export const FIELD_PAID_TOTAL = 'paidTotal';
export const FIELD_TARGET_RPM = 'targetRpm';
export const FIELD_ACCOUNT_ID = 'accountId';
export const FIELD_START_DATE = 'startDate';
export const FIELD_START_TIME = 'startTime';
export const FIELD_COMPANY_ID = 'companyId';
export const FIELD_FIRST_NAME = 'firstName';
export const FIELD_FAX_NUMBER = 'faxNumber';
export const FIELD_ROUTE_NAME = 'routeName';
export const FIELD_AUDIT_TYPE = 'auditType';
export const FIELD_VALUE_FROM = 'valueFrom';
export const FIELD_LABEL_TYPE = 'labelType';
export const FIELD_QUOTE_NAME = 'quoteName';
export const FIELD_OWNER_NAME = 'ownerName';
export const FIELD_IFTA_DECAL = 'iftaDecal';
export const FIELD_FULLY_PAID = 'fullyPaid';
export const FIELD_PRICE_DATE = 'priceDate';
export const FIELD_UPDATED_BY = 'updatedBy';
export const FIELD_HEIGHT_UOM = 'heightUom';
export const FIELD_LENGTH_UOM = 'lengthUom';
export const FIELD_TRIP_TOTAL = 'tripTotal';
export const FIELD_ENTRY_PORT = 'entryPort';
export const FIELD_USER_GUIDS = 'userGuids';
export const FIELD_FIELD_NAME = 'fieldName';
export const FIELD_TEL_NUMBER = 'tripNumber';
export const FIELD_EQUIPMENTS = 'equipments';
export const FIELD_COLLECTION = 'collection';
export const FIELD_DATE_RANGE = 'date-range';
export const FIELD_SUPERVISOR = 'supervisor';
export const FIELD_CONDITIONS = 'conditions';
export const FIELD_PINNED_NOTE = 'pinnedNote';
export const FIELD_PART_NUMBER = 'partNumber';
export const FIELD_RESERVED_BY = 'reservedBy';
export const FIELD_CLIENT_NAME = 'clientName';
export const FIELD_PROJECT_KEY = 'projectKey';
export const FIELD_OBJECT_TYPE = 'objectType';
export const FIELD_OBJECT_NAME = 'objectName';
export const FIELD_TASK_OBJECT = 'taskObject';
export const FIELD_TASK_NUMBER = 'taskNumber';
export const FIELD_SEND_EMAILS = 'sendEmails';
export const FIELD_SHIPMENT_ID = 'shipmentId';
export const FIELD_REPORT_NAME = 'reportName';
export const FIELD_CREATED_CLO = 'createdClo';
export const FIELD_START_PRICE = 'startPrice';
export const FIELD_CARRIER_EDI = 'carrierEdi';
export const FIELD_DRIVER_TYPE = 'driverType';
export const FIELD_API_CARRIER = 'apiCarrier';
export const FIELD_TOTAL_SPEND = 'totalSpend';
export const FIELD_EXTERNAL_ID = 'externalId';
export const FIELD_STATUS_CODE = 'statusCode';
export const FIELD_FEDERAL_EIN = 'federalEin';
export const FIELD_DUNS_NUMBER = 'dunsNumber';
export const FIELD_TEMPLATE_ID = 'templateId';
export const FIELD_SOURCE_TYPE = 'sourceType';
export const FIELD_SOURCE_NAME = 'sourceName';
export const FIELD_PAID_AMOUNT = 'paidAmount';
export const FIELD_CUSTOMER_ID = 'customerId';
export const FIELD_EXPORTED_BY = 'exportedBy';
export const FIELD_ISSUE_GUIDS = 'issueGuids';
export const FIELD_MIDDLE_NAME = 'middleName';
export const FIELD_DESCRIPTION = 'description';
export const FIELD_DISPATCHERS = 'dispatchers';
export const FIELD_DESTINATION = 'destination';
export const FIELD_GL_CODE_GUID = 'glCodeGuid';
export const FIELD_GL_CODE_TYPE = 'glCodeType';
export const FIELD_API_KEY_NAME = 'apiKeyName';
export const FIELD_DEFAULT_NAME = 'defaultName';
export const FIELD_UPDATED_DATE = 'updatedDate';
export const FIELD_EMAILS_FIELD = 'emailsField';
export const FIELD_SENDING_TYPE = 'sendingType';
export const FIELD_SENDING_FROM = 'sendingFrom';
export const FIELD_ACCOUNT_TYPE = 'accountType';
export const FIELD_ISSUES_COUNT = 'issuesCount';
export const FIELD_ACCESS_TOKEN = 'accessToken';
export const FIELD_ORDER_QUOTES = 'orderQuotes';
export const FIELD_COMPANY_CODE = 'companyCode';
export const FIELD_CALCULATE_BY = 'calculateBy';
export const FIELD_FIRST_PICKUP = 'firstPickup';
export const FIELD_REQUESTED_BY = 'requestedBy';
export const FIELD_PHONE_NUMBER = 'phoneNumber';
export const FIELD_ACCOUNT_NAME = 'accountName';
export const FIELD_NUMBER_VALUE = 'numberValue';
export const FIELD_STRING_VALUE = 'stringValue';
export const FIELD_SERVICE_GUID = 'serviceGuid';
export const FIELD_NEW_PASSWORD = 'newPassword';
export const FIELD_OLD_PASSWORD = 'oldPassword';
export const FIELD_CLO_STATUSES = 'cloStatuses';
export const FIELD_TEL_STATUSES = 'telStatuses';
export const FIELD_ORIGIN_STATE = 'originState';
export const FIELD_TOTAL_AMOUNT = 'totalAmount';
export const FIELD_PAYMENT_YEAR = 'paymentYear';
export const FIELD_CHECK_AMOUNT = 'checkAmount';
export const FIELD_COMPLETED_BY = 'completedBy';
export const FIELD_POSITING_DATE = 'postingDate';
export const FIELD_US_DOT_NUMBER = 'usDotNumber';
export const FIELD_AUTO_APPLY_TO = 'autoApplyTo';
export const FIELD_SCHEDULE_DATE = 'scheduleDate';
export const FIELD_STORED_WEIGHT = 'storedWeight';
export const FIELD_STORED_VOLUME = 'storedVolume';
export const FIELD_TEMPLATE_TYPE = 'templateType';
export const FIELD_INVOICE_TOTAL = 'invoiceTotal';
export const FIELD_DIVISION_GUID = 'divisionGuid';
export const FIELD_CONTRACT_NAME = 'contractName';
export const FIELD_WARNING_LEVEL = 'warningLevel';
export const FIELD_ENCODING_TYPE = 'encodingType';
export const FIELD_SERVICE_TYPES = 'serviceTypes';
export const FIELD_PAYMENT_MONTH = 'paymentMonth';
export const FIELD_CUSTOM_STATUS = 'customStatus';
export const FIELD_CARRIER_TOTAL = 'carrierTotal';
export const FIELD_DIVISION_NAME = 'divisionName';
export const FIELD_PROPERTY_NAME = 'propertyName';
export const FIELD_CUSTOMER_CODE = 'customerCode';
export const FIELD_BOOLEAN_VALUE = 'booleanValue';
export const FIELD_TEMPLATE_GUID = 'templateGuid';
export const FIELD_TEMPLATE_NAME = 'templateName';
export const FIELD_DEFAULT_EMAIL = 'defaultEmail';
export const FIELD_CUSTOMER_NAME = 'customerName';
export const FIELD_PICKUP_NUMBER = 'pickupNumber';
export const FIELD_CLIENT_SECRET = 'clientSecret';
export const FIELD_LICENSE_PLATE = 'licensePlate';
export const FIELD_SEND_RESPONSE = 'sendResponse';
export const FIELD_CUSTOMER_GUID = 'customerGuid';
export const FIELD_CARRIER_GUIDS = 'carrierGuids';
export const FIELD_CARRIER_PRICE = 'carrierPrice';
export const FIELD_DEPARTMENT_ID = 'departmentId';
export const FIELD_CUSTOM_MAPPER = 'customMapper';
export const FIELD_GRANTED_ROLES = 'grantedRoles';
export const FIELD_ASSIGNEE_GUID = 'assigneeGuid';
export const FIELD_MANIFEST_TYPE = 'manifestType';
export const FIELD_SHIPMENT_TYPE = 'shipmentType';
export const FIELD_DISPATCH_TYPE = 'dispatchType';
export const FIELD_ESTIMATE_DATE = 'estimateDate';
export const FIELD_TRAILER_COUNT = 'trailerCount';
export const FIELD_MAIN_USER_ROLE = 'mainUserRole';
export const FIELD_COMPLETED_DATE = 'completedDate';
export const FIELD_TOTAL_SPENDING = 'totalSpending';
export const FIELD_LAST_UPDATE_BY = 'lastUpdatedBy';
export const FIELD_APPLICABLE_FOR = 'applicableFor';
export const FIELD_ORIGIN_COUNTRY = 'originCountry';
export const FIELD_EFFECTIVE_DATE = 'effectiveDate';
export const FIELD_DIMENSIONS_UOM = 'dimensionsUom';
export const FIELD_CUSTOMER_TOTAL = 'customerTotal';
export const FIELD_INTEGRATION_ID = 'integrationId';
export const FIELD_USERNAME_CAMEL_CASE = 'userName';
export const FIELD_MIDDLE_INITIAL = 'middleInitial';
export const FIELD_FACTORING_TYPE = 'factoringType';
export const FIELD_ACCOUNT_NUMBER = 'accountNumber';
export const FIELD_EQUIPMENT_GUID = 'equipmentGuid';
export const FIELD_TOTAL_DISTANCE = 'totalDistance';
export const FIELD_AVAILABLE_DATE = 'availableDate';
export const FIELD_SERVICE_VENDOR = 'serviceVendor';
export const FIELD_RELEASE_OFFICE = 'releaseOffice';
export const FIELD_CONTROL_NUMBER = 'controlNumber';
export const FIELD_ORIGIN_OF_GOODS = 'originOfGoods';
export const FIELD_CERTIFICATIONS = 'certifications';
export const FIELD_NUMBER_OF_LOADS = 'numberOfLoads';
export const FIELD_ORDER_TYPE_GUID = 'orderTypeGuid';
export const FIELD_PAYED_FOR_TRUCK = 'payedForTruck';
export const FIELD_WORK_ORDER_GUID = 'workOrderGuid';
export const FIELD_ESTIMATE_NUMBER = 'estimateNumber';
export const FIELD_SUPERVISOR_GUID = 'supervisorGuid';
export const FIELD_INTEGRATED_DATE = 'integratedDate';
export const FIELD_ACCOUNT_COUNTRY = 'accountCountry';
export const FIELD_ENABLED_SENDING = 'enabledSending';
export const FIELD_TRACKING_NUMBER = 'trackingNumber';
export const FIELD_REFERENCE_VALUE = 'referenceValue';
export const FIELD_SEARCH_CRITERIA = 'searchCriteria';
export const FIELD_PHONE_EXTENSION = 'phoneExtension';
export const FIELD_EXPIRATION_DATE = 'expirationDate';
export const FIELD_AVAILABLE_LATER = 'availableLater';
export const FIELD_EXPIRATION_DAYS = 'expirationDays';
export const FIELD_TRUCKING_METHOD = 'truckingMethod';
export const FIELD_GVWT_WEIGHT_TYPE = 'gvwtWeightType';
export const FIELD_PAYED_FOR_DRIVER = 'payedForDriver';
export const FIELD_NEXT_START_DATES = 'nextStartDates';
export const FIELD_STATUS_CODE_GUID = 'statusCodeGuid';
export const FIELD_LAST_UPDATE_DATE = 'lastUpdateDate';
export const FIELD_MACRO_POINT_INFO = 'macroPointInfo';
export const FIELD_FILE_PUBLIC_LINK = 'filePublicLink';
export const FIELD_TEL_CARRIER_NAME = 'telCarrierName';
export const FIELD_CURRENT_TRAILERS = 'currentTrailers';
export const FIELD_EMAILS_SUSPENDED = 'emailsSuspended';
export const FIELD_INTEGRATION_TYPE = 'integrationType';
export const FIELD_INTEGRATION_INFO = 'integrationInfo';
export const FIELD_PRIMARY_DIVISION = 'primaryDivision';
export const FIELD_COMMITMENT_COUNT = 'commitmentCount';
export const FIELD_ACCOUNT_MANAGERS = 'accountManagers';
export const FIELD_DRIVER_CARD_COLOR = 'driverCardColor';
export const FIELD_FUEL_CARD_ENABLED = 'fuelCardEnabled';
export const FIELD_SENT_STATUS_CODES = 'sentStatusCodes';
export const FIELD_DEFAULT_TIME_ZONE = 'defaultTimeZone';
export const FIELD_TEL_CREATION_MODE = 'telCreationMode';
export const FIELD_ENCRYPTED_API_KEY = 'encryptedApiKey';
export const FIELD_INTERNAL_DIVISION = 'internalDivision';
export const FIELD_PRINTABLE_SECTION = 'printableSection';
export const FIELD_COMPENSATION_GUID = 'compensationGuid';
export const FIELD_STATUS_REASON_CODE = 'statusReasonCode';
export const FIELD_TOTAL_DISTANCE_UOM = 'totalDistanceUom';
export const FIELD_MIN_CUSTOMER_PRICE = 'minCustomerPrice';
export const FIELD_CUSTOMER_NAME_TYPE = 'customerNameType';
export const FIELD_STORE_BOL_DOCUMENT = 'storeBolDocument';
export const FIELD_RATE_MISMATCH_TYPE = 'rateMismatchType';
export const FIELD_CUSTOM_STATUS_GUID = 'customStatusGuid';
export const FIELD_DOCUMENT_FILE_NAME = 'documentFilename';
export const FIELD_SHOW_CUSTOMER_RATE = 'showCustomerRate';
export const FIELD_DEFAULT_ROUTE_TYPE = 'defaultRouteType';
export const FIELD_DOCUMENT_TYPE_GUID = 'documentTypeGuid';
export const FIELD_DOCUMENT_TYPE_NAME = 'documentTypeName';
export const FIELD_SIGNATURE_FILE_URI = 'signatureFileUri';
export const FIELD_FLEET_TRAILER_GUID = 'fleetTrailerGuid';
export const FIELD_MAIN_USER_ROLE_GUID = 'mainUserRoleGuid';
export const FIELD_EXTERNAL_EQUIPMENT = 'externalEquipment';
export const FIELD_GET_FUEL_FROM_ORDER = 'getFuelFromOrder';
export const FIELD_STATUS_DESCRIPTION = 'statusDescription';
export const FIELD_SEND_AUTOMATICALLY = 'sendAutomatically';
export const FIELD_SIGNATURE_FILE_NAME = 'signatureFileName';
export const FIELD_TOTAL_CUSTOMER_RATE = 'totalCustomerRate';
export const FIELD_RAIL_BILLING_STATUS = 'railBillingStatus';
export const FIELD_TEMPLATE_CREATED_BY = 'templateCreatedBy';
export const FIELD_REQUESTED_DATE_TIME = 'requestedDateTime';
export const FIELD_RATE_MISMATCH_VALUE = 'rateMismatchValue';
export const FIELD_BANK_ACCOUNT_NUMBER = 'bankAccountNumber';
export const FIELD_BANK_ROUTING_NUMBER = 'bankRoutingNumber';
export const FIELD_USER_MONITORING_APP = 'userMonitoringApp';
export const FIELD_PRIMARY_OBJECT_GUID = 'primaryObjectGuid';
export const FIELD_DOCUMENT_TYPE_GUIDS = 'documentTypeGuids';
export const FIELD_INVOICE_STATUS_GUID = 'invoiceStatusGuid';
export const FIELD_DISPATCHED_BY_LOGIN = 'dispatchedByLogin';
export const FIELD_PAYED_FOR_TRUCK_GUID = 'payedForTruckGuid';
export const FIELD_TRUCK_DAILY_LOG_GUID = 'truckDailyLogGuid';
export const FIELD_MAX_NUMBER_OF_TRUCKS = 'maxNumberOfTrucks';
export const FIELD_ADDITIONAL_ACCOUNTS = 'additionalAccounts';
export const FIELD_IMPORTER_BY_LOGIN_ID = 'importerByLoginId';
export const FIELD_RESERVATION_END_DATE = 'reservationEndDate';
export const FIELD_INVOICE_CHECK_AMOUNT = 'invoiceCheckAmount';
export const FIELD_STOP_SEQUENCE_NUMBER = 'stopSequenceNumber';
export const FIELD_SMTP_ENCRYPTION_TYPE = 'smtpEncryptionType';
export const FIELD_ORIGINAL_CONFIG_GUID = 'originalConfigGuid';
export const FIELD_EXPIRATION_DATE_TIME = 'expirationDateTime';
export const FIELD_MONTHLY_PAYMENT_GUID = 'monthlyPaymentGuid';
export const FIELD_MASTER_BRANCH_SUFFIX = 'masterBranchSuffix';
export const FIELD_MASTER_TEMPLATE_GUID = 'masterTemplateGuid';
export const FIELD_SALE_REPRESENTATIVES = 'saleRepresentatives';
export const FIELD_PAYED_FOR_DRIVER_GUID = 'payedForDriverGuid';
export const FIELD_CLO_MULTI_CREATE_DATA = 'cloMultiCreateData';
export const FIELD_DESTINATION_CURRENCY = 'destinationCurrency';
export const FIELD_TEMPLATE_DESCRIPTION = 'templateDescription';
export const FIELD_DELETE_ORIGINAL_ROUTE = 'deleteOriginalRoute';
export const FIELD_ACCOUNT_SEQUENCE_GUID = 'accountSequenceGuid';
export const FIELD_TEMPLATE_CREATED_DATE = 'templateCreatedDate';
export const FIELD_FULL_CONTAINER_WEIGHT = 'fullContainerWeight';
export const FIELD_SEND_REFERENCES_GUIDS = 'sendReferencesGuids';
export const FIELD_VENDOR_DOCUMENT_GUIDS = 'vendorDocumentGuids';
export const FIELD_INFO_FROM_INTEGRATION = 'infoFromIntegration';
export const FIELD_PICKUP_REQUESTER_GUID = 'pickupRequesterGuid';
export const FIELD_PRIMARY_DIVISION_NAME = 'primaryDivisionName';
export const FIELD_PRIMARY_DIVISION_GUID = 'primaryDivisionGuid';
export const FIELD_ACTUAL_COMPLETED_DATE = 'actualCompletedDate';
export const FIELD_TEL_GUID_DISPATCH_TYPE = 'telGuidDispatchType';
export const FIELD_RATE_PER_DISTANCE_UNIT = 'ratePerDistanceUnit';
export const FIELD_AUTO_SEND_TRIP_ENABLED = 'autoSendTripEnabled';
export const FIELD_REQUIRED_MIN_MAX_PRICE = 'requiredMinMaxPrice';
export const FIELD_EQUIPMENT_SERVICE_GUID = 'equipmentServiceGuid';
export const FIELD_CUSTOMER_BRANCH_SUFFIX = 'customerBranchSuffix';
export const FIELD_FACTORING_PAYMENT_TERM = 'factoringPaymentTerm';
export const FIELD_EMPTY_CONTAINER_WEIGHT = 'emptyContainerWeight';
export const FIELD_AUTO_ASSIGMENT_ENABLED = 'autoAssigmentEnabled';
export const FIELD_TRACKING_STATUS_CODE = 'trackingStatusCodeGuid';
export const FIELD_PRIMARY_REFERENCE_NAME = 'primaryReferenceName';
export const FIELD_EXTERNAL_DIVISION_NAME = 'externalDivisionName';
export const FIELD_HISTORICAL_PAID_AMOUNT = 'historicalPaidAmount';
export const FIELD_INTERNAL_DIVISION_GUID = 'internalDivisionGuid';
export const FIELD_INTEGRATION_TRUCK_TYPE = 'integrationTruckType';
export const FIELD_FIRST_PICKUP_START_DATE = 'firstPickupStartDate';
export const FIELD_ACCOUNTING_INTEGRATION = 'accountingIntegration';
export const FIELD_CLO_REFERENCE_TYPE_GUID = 'cloReferenceTypeGuid';
export const FIELD_COMMISSION_ASSIGNMENTS = 'commissionAssignments';
export const FIELD_WORK_ORDER_INVOICE_GUID = 'workOrderInvoiceGuid';
export const FIELD_AVAILABLE_DRIVERS_COLOR = 'availableDriversColor';
export const FIELD_EXPECTED_COMPLETED_DATE = 'expectedCompletedDate';
export const FIELD_EXTERNAL_INVOICE_NUMBER = 'externalInvoiceNumber';
export const FIELD_ACCESSORIAL_CONFIG_GUID = 'assessorialConfigGuid';
export const FIELD_SHARED_ASSESSORIAL_GUID = 'sharedAssessorialGuid';
export const FIELD_SHIPMENT_NUMBER_PRIMARY = 'shipmentNumberPrimary';
export const FIELD_STATUS_FROM_INTEGRATION = 'statusFromIntegration';
export const FIELD_INTEGRATION_CONFIG_GUID = 'integrationConfigGuid';
export const FIELD_PRIMARY_REFERENCE_VALUE = 'primaryReferenceValue';
export const FIELD_TOTAL_DEADHEAD_DISTANCE = 'totalDeadHeadDistance';
export const FIELD_CARRIER_REPRESENTATIVES = 'carrierRepresentatives';
export const FIELD_DEFAULT_AFTER_LOGIN_PAGE = 'defaultAfterLoginPage';
export const FIELD_CLO_PRIMARY_REFERENCE = 'cloPrimaryReferenceValue';
export const FIELD_EXCEL_RATING_RESULT_GUID = 'excelRatingResultGuid';
export const FIELD_LABEL_DOCUMENT_TYPE_GUID = 'labelDocumentTypeGuid';
export const FIELD_THIRD_PARTY_CONTACT_NAME = 'thirdPartyContactName';
export const FIELD_ENABLED_HOS_LOGS_STORAGE = 'enabledHosLogsStorage';
export const FIELD_CLO_SALE_REPRESENTATIVES = 'cloSaleRepresentatives';
export const FIELD_TEL_SALE_REPRESENTATIVES = 'telSaleRepresentatives';
export const FIELD_INTEGRATION_TRAILER_TYPE = 'integrationTrailerType';
export const FIELD_RESERVATION_TIME_MINUTES = 'reservationTimeMinutes';
export const FIELD_PAYROLL_ASSESSORIAL_GUID = 'payrollAssessorialGuid';
export const FIELD_TRANSPORTATION_MODE_GUID = 'transportationModeGuid';
export const FIELD_FUTURE_AVAILABILITY_DATE = 'futureAvailabilityDate';
export const FIELD_SHOWN_DOCUMENT_TYPE_GUIDS = 'shownDocumentTypeGuids';
export const FIELD_ASSIGNED_TO_DIVISION_GUID = 'assignedToDivisionGuid';
export const FIELD_THIRD_PARTY_CONTACT_PHONE = 'thirdPartyContactPhone';
export const FIELD_THIRD_PARTY_CONTACT_EMAIL = 'thirdPartyContactEmail';
export const EXTERNAL_TRANSPORTATION_MODE = 'externalTransportationMode';
export const FIELD_MAX_ISSUE_WARNING_LEVEL = 'maxIssueWarningLevelValue';
export const FIELD_SHIPMENT_NUMBER_REFERENCE = 'shipmentNumberReference';
export const FIELD_STORED_HAZMAT_TOTAL_WEIGHT = 'storedHazmatTotalWeight';
export const FIELD_VENDOR_DOCUMENT_TYPE_GUIDS = 'vendorDocumentTypeGuids';
export const FIELD_ENABLED_UPDATE_DAILY_HOURS = 'enabledUpdateDailyHours';
export const FIELD_THUMBNAIL_FILE_PUBLIC_LINK = 'thumbnailFilePublicLink';
export const FIELD_AVAILABLE_ON_DRIVER_MOBILE = 'availableOnDriverMobile';
export const FIELD_CLO_ROUTE_DEFAULT_GROUP_TYPE = 'cloRouteDefaultGroupBy';
export const FIELD_TEL_ROUTE_DEFAULT_GROUP_TYPE = 'telRouteDefaultGroupBy';
export const FIELD_INTEGRATION_INVOICE_NUMBER = 'integrationInvoiceNumber';
export const FIELD_REQUESTED_PICKUP_EARLY_DATE = 'requestedPickupEarlyDate';
export const FIELD_TEL_PRIMARY_REFERENCE_VALUE = 'telPrimaryReferenceValue';
export const FIELD_ENABLED_DAILY_HOURS_STORAGE = 'enabledDailyHoursStorage';
export const FIELD_PRIMARY_REFERENCE_TYPE_GUID = 'primaryReferenceTypeGuid';
export const FIELD_ESTIMATED_ARRIVAL_DATE_TIME = 'estimatedArrivalDateTime';
export const FIELD_ESTIMATED_DELIVERY_LATE_DATE = 'estimatedDeliveryLateDate';
export const FIELD_INVOICE_REFERENCE_TYPE_GUIDS = 'invoiceReferenceTypeGuids';
export const FIELD_REMOVE_AFTER_INACTIVITY_DAYS = 'removeAfterInactivityDays';
export const FIELD_FUTURE_AVAILABILITY_LOCATION = 'futureAvailabilityLocation';
export const FIELD_RATE_GUID_TO_COMMITMENT_COUNT = 'rateGuidToCommitmentCount';
export const FIELD_ESTIMATED_DELIVERY_EARLY_DATE = 'estimatedDeliveryEarlyDate';
export const FIELD_TRACKING_FREQUENCY_IN_MINUTES = 'trackingFrequencyInMinutes';
export const FIELD_CHANGE_ASSIGNED_UNIT_DIVISION = 'changeAssignedUnitDivision';
export const FIELD_ENABLED_UPDATE_TRUCK_DAILY_LOG = 'enabledUpdateTruckDailyLog';
export const FIELD_STORED_NON_HAZMAT_TOTAL_WEIGHT = 'storedNonHazmatTotalWeight';
export const FIELD_EXCEL_MASS_RATING_SUMMARY_GUID = 'excelMassRatingSummaryGuid';
export const FIELD_PROOF_OF_DELIVERY_DOCUMENT_TYPE = 'proofOfDeliveryDocumentTypeGuid';
export const FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER = 'addAdditionalChargesFromOrder';
export const FIELD_DISABLE_SSL_CERTIFICATE_VALIDATION = 'disableSslCertificateValidation';
export const FIELD_HOT_LOAD_TRACKING_FREQUENCY_IN_MINUTES = 'hotLoadTrackingFrequencyInMinutes';
// rate shop
export const FIELD_RATE_SHOP_CARRIER_EQUIPMENTS = 'carrierEquipments';
export const FIELD_RATE_SHOP_CARRIER_TRANSIT_DAYS = 'carrierTransitDays';
export const FIELD_RATE_SHOP_CARRIER_SERVICE_TYPES = 'carrierServiceTypes';
export const FIELD_RATE_SHOP_CARRIER_TRANSPORTATION_MODE = 'carrierTransportationMode';
// driver onboarding integration
export const FIELD_DRIVER_ROLES_GUIDS = 'driverRolesGuids';
export const FIELD_DEFAULT_DRIVER_PASSWORD = 'defaultDriverPassword';
export const FIELD_PREFERRED_USERNAME_FORMAT = 'preferredUsernameFormat';
// hours
export const FIELD_CYCLE_TOMORROW = 'cycleTomorrow';
export const FIELD_CYCLE_REMAINING = 'cycleRemaining';
export const FIELD_SHIFT_REMAINING = 'shiftRemaining';
export const FIELD_TIME_UNTIL_BREAK = 'timeUntilBreak';
export const FIELD_DUTY_STATUS_CODE = 'dutyStatusCode';
export const FIELD_OUT_OF_DUTY_REASON = 'outOfDutyReason';
export const FIELD_SHIFT_DRIVER_REMAINING = 'shiftDriveRemaining';
export const FIELD_TIME_IN_CURRENT_STATUS = 'timeInCurrentStatus';
// order quotes
export const FIELD_ACCEPTED_BY = 'acceptedBy';
export const FIELD_ACCEPTED_DATE = 'acceptedDate';
export const FIELD_ACCEPTED_BY_EMAIL = 'acceptedByEmail';
export const FIELD_ORDER_QUOTE_NUMBER = 'orderQuoteNumber';
export const FIELD_CANCEL_REASON_CODE = 'cancelReasonCode';
export const FIELD_DECLINE_REASON_CODE = 'declineReasonCode';
export const ACCEPTED_BY_PHONE_NUMBER = 'acceptedByPhoneNumber';
// audit
export const FIELD_AUDIT_NEW_VALUE = 'newValue';
export const FIELD_AUDIT_OLD_VALUE = 'oldValue';
export const FIELD_AUDIT_FIELD_NAME = 'fieldName';
// business hours
export const FIELD_DAYS = 'days';
export const FIELD_TIME_TO = 'timeTo';
export const FIELD_TIME_FROM = 'timeFrom';
export const FIELD_OPERATION_HOUR = 'operationHour';
// branch
export const FIELD_BRANCH_NOTES = 'notes';
export const FIELD_BRANCH_SCAC = FIELD_SCAC;
export const FIELD_SALE_PERSONS = 'salePersons';
export const FIELD_BRANCH_CURRENCY = 'currency';
export const FIELD_BROKER_AGENTS = 'brokerAgents';
export const FIELD_BRANCH_DBA = 'doingBusinessAs';
export const FIELD_BRANCH_DNS_PREFIX = 'dnsPrefix';
export const FIELD_BRANCH_BRANCH_NAME = BRANCH_NAME;
export const FIELD_BRANCH_BRANCH_TYPE = BRANCH_TYPE;
export const FIELD_BRANCH_PARENT_NAME = 'parentName';
export const FIELD_BRANCH_CREDIT_USED = 'creditUsed';
export const FIELD_BRANCH_MC_NUMBER = FIELD_MC_NUMBER;
export const FIELD_BRANCH_DESCRIPTION = 'description';
export const FIELD_BRANCH_CREDIT_LIMIT = 'creditLimit';
export const FIELD_BRANCH_MAX_SMS_PRICE = 'maxSmsPrice';
export const FIELD_BRANCH_SUBTYPE = 'enterpriseSubtype';
export const FIELD_BRANCH_FEDERAL_EIN = FIELD_FEDERAL_EIN;
export const FIELD_BRANCH_DUNS_NUMBER = FIELD_DUNS_NUMBER;
export const FIELD_BRANCH_ACCOUNT_NAME = FIELD_ACCOUNT_NAME;
export const FIELD_BRANCH_SETTINGS_AI_ENABLED = 'aiEnabled';
export const FIELD_SHARED_TO_DIVISIONS = 'sharedToDivisions';
export const FIELD_BRANCH_US_DOT_NUMBER = FIELD_US_DOT_NUMBER;
export const FIELD_BRANCH_NORMALIZED_CURRENCY = 'normalizedCurrency';
export const FIELD_COMMISSION_ASSIGNEE_TYPE = 'commissionAssigneeType';
export const FIELD_INTEGRATION_ACCOUNT_NUMBER = 'integrationAccountNumber';
export const FIELD_BRANCH_SETTINGS_IFTA_REPORT_ENABLED = 'iftaReportEnabled';
export const FIELD_BRANCH_SMS_NOTIFICATION_ENABLED = 'smsNotificationEnabled';
export const FIELD_BRANCH_DEACTIVATE_ON_CREDIT_LIMIT = 'deactivateOnCreditLimit';
export const FIELD_BRANCH_SETTINGS_CARRIER_MOBILE_ENABLED = 'carrierMobileEnabled';
export const FIELD_BRANCH_SETTINGS_FLEET_DRIVER_SMS_ENABLED = 'fleetDriverSmsEnabled';
export const FIELD_BRANCH_SETTINGS_AVAILABLE_IMPORT_MAPPERS = 'availableImportMappers';
export const FIELD_BRANCH_SETTINGS_CARRIER_DRIVER_SMS_ENABLED = 'carrierDriverSmsEnabled';
export const FIELD_BRANCH_SETTINGS_DISTANCE_TO_NEXT_STOP_ENABLED = 'distanceToNextStopEnabled';
export const FIELD_USE_BRANCH_INTEGRATION_ACCOUNT_NUMBER = 'useBranchIntegrationAccountNumber';
export const FIELD_BRANCH_SETTINGS_IGNORE_LOS_SUBSCRIPTION_LIMITS = 'ignoreLosSubscriptionLimits';
// styling
export const FIELD_STYLING_LOGO_URL = 'logoUrl';
// api system fields
export const FIELD_GUID = 'guid';
export const FIELD_TEXT = 'text';
export const FIELD_VALUE = 'value';
export const FIELD_LABEL = 'label';
export const FIELD_COUNT = 'count';
export const FIELD_ACTIVE = 'active';
export const FIELD_DELETED = 'deleted';
export const FIELD_VERSION = 'version';
export const FIELD_PRIMARY = 'primary';
export const FIELD_EDITABLE = 'editable';
export const FIELD_LOGIN_ID = 'login_id';
export const FIELD_APPROVED = 'approved';
export const FIELD_NOTE_TYPE = 'noteType';
export const FIELD_CREATED_BY = 'createdBy';
export const FIELD_EVENT_GUID = 'eventGuid';
export const FIELD_MASTER_GUID = 'masterGuid';
export const FIELD_BRANCH = BRANCH_LOWERCASE;
export const FIELD_BRANCH_GUID = BRANCH_GUID;
export const FIELD_BRANCH_NAME = BRANCH_NAME;
export const FIELD_OBJECT_GUID = 'objectGuid';
export const FIELD_APPROVED_BY = 'approvedBy';
export const FIELD_MODIFIED_BY = 'modifiedBy';
export const FIELD_CONFIG_GUID = 'configGuid';
export const FIELD_PARENT_GUID = 'parentGuid';
export const FIELD_PARENT_TYPE = 'parentType';
export const FIELD_STATUS_TYPE = 'statusType';
export const FIELD_BRANCH_GUIDS = BRANCH_GUIDS;
export const FIELD_STORED_VALUE = 'storedValue';
export const FIELD_CREATED_DATE = 'createdDate';
export const FIELD_DISPLAY_VALUE = 'displayValue';
export const FIELD_SYSTEM_STATUS = 'systemStatus';
export const FIELD_CURRENT_BRANCH = CURRENT_BRANCH;
export const FIELD_DISPLAYED_NAME = 'displayedName';
export const FIELD_LAST_LOGIN_DATE = 'lastLoginDate';
export const FIELD_BRANCH_DELETE_DATE = 'deleteDate';
export const FIELD_BRANCH_SUSPENDED = FIELD_SUSPENDED;
export const FIELD_DISPLAYED_VALUE = 'displayedValue';
export const FIELD_INHERITED_VALUE = 'inheritedValue';
export const FIELD_LAST_MODIFIED_BY = 'lastModifiedBy';
export const FIELD_OWNER_BRANCH_GUID = OWNER_BRANCH_GUID;
export const FIELD_DRAFT_BRANCH_GUID = DRAFT_BRANCH_GUID;
export const FIELD_PRIMARY_REFERENCE = 'primaryReference';
export const FIELD_OWNING_BRANCH_GUID = OWNING_BRANCH_GUID;
export const FIELD_LAST_MODIFIED_DATE = 'lastModifiedDate';
export const FIELD_INTEGRATION_STATUS = 'integrationStatus';
export const FIELD_CURRENT_BRANCH_GUID = CURRENT_BRANCH_GUID;
export const FIELD_GRANTED_BRANCH_GUID = GRANTED_BRANCH_GUID;
export const FIELD_DROPDOWN_OPTION_GUID = 'dropdownOptionGuid';
export const FIELD_APPROVED_BY_USER_GUID = 'approvedByUserGuid';
export const FIELD_BRANCH_AUTHORITIES = `${BRANCH_LOWERCASE}Authorities`;
export const FIELD_CHILD_BRANCHES_GUIDS = `child${BRANCH_CAPITALIZE}sGuids`;
export const FIELD_BRANCH_UNDERSCORE_GUID = `${BRANCH_LOWERCASE}_${FIELD_GUID}`;
export const FIELD_BRANCH_DOT_BRANCH_NAME = `${BRANCH_LOWERCASE}.${BRANCH_NAME}`;
export const FIELD_BRANCH_TYPE_DOT_DISPLAY_VALUE = `${BRANCH_TYPE}.${FIELD_DISPLAY_VALUE}`;
export const FIELD_BRANCH_DOT_ACCOUNT_NAME = `${BRANCH_LOWERCASE}.${FIELD_BRANCH_ACCOUNT_NAME}`;
// advance payment
export const FIELD_ADVANCE_PAYMENT_TAG = 'tag';
export const FIELD_ADVANCE_PAYMENT_AMOUNT = 'amount';
export const FIELD_ADVANCE_PAYMENT_STATUS = 'status';
export const FIELD_ADVANCE_PAYMENT_ACTION = 'action';
export const FIELD_ADVANCE_PAYMENT_REASON = 'reason';
export const FIELD_ADVANCE_PAYMENT_DRIVER = 'driver';
export const FIELD_ADVANCE_PAYMENT_FLEET_ID = 'fleetId';
export const FIELD_ADVANCE_PAYMENT_CHECK_FEE = 'checkFee';
export const FIELD_ADVANCE_PAYMENT_TRIP_NUMBER = 'tripNumber';
export const FIELD_ADVANCE_PAYMENT_CHECK_NUMBER = 'checkNumber';
export const FIELD_ADVANCE_PAYMENT_CHECK_AMOUNT = 'checkAmount';
export const FIELD_ADVANCE_PAYMENT_LAST_USE_CITY = 'lastUseCity';
export const FIELD_ADVANCE_PAYMENT_LAST_USE_DATE = 'lastUseDate';
export const FIELD_ADVANCE_PAYMENT_CHECK_BALANCE = 'checkBalance';
export const FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER = 'driverNumber';
export const FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME = 'driverLastName';
export const FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME = 'driverFistName';
export const FIELD_ADVANCE_PAYMENT_INTEGRATION_TYPE = 'integrationType';
export const FIELD_ADVANCE_PAYMENT_CHANGE_STATUS_REASON = 'changeStatusReason';
export const FIELD_ADVANCE_PAYMENT_LAST_USE_LOCATION_CODE = 'lastUseLocationCode';
// expense
export const FIELD_EXPENSE_TYPE_GUID = 'typeGuid';
export const FIELD_EXPENSE_REQUIRABLE_TYPE = 'requirableType';
// expense statuses
export const FIELD_EXPENSE_STATUS_PENDING = 'PENDING';
export const FIELD_EXPENSE_STATUS_APPROVED = 'APPROVED';
export const FIELD_EXPENSE_STATUS_DECLINED = 'DECLINED';
// report
export const FIELD_OWNER = 'owner';
export const FIELD_PROMPT = 'prompt';
export const FIELD_GROUP_BY = 'groupBy';
export const FIELD_ROLE_GUIDS = 'roleGuids';
export const FIELD_SUMMARY_GROUP = 'summaryGroup';
export const FIELD_PINNED_REPORT = 'pinnedReport';
export const FIELD_SHOW_LAST_DAYS = 'showLastDays';
export const FIELD_REPORT_DEFAULT = 'defaultReport';
export const FIELD_CUSTOMER_GUIDS = 'customerGuids';
export const FIELD_SUMMARY_SUBGROUP = 'summarySubgroup';
export const FIELD_PINNED_REPORT_INDEX = 'pinnedReportIndex';
export const FIELD_AVOID_RELATED_VALUE_DUPLICATES = 'avoidRelatedValueDuplicates';
// chart
export const FIELD_CHART_TIME_UNIT = 'timeUnit';
export const FIELD_CHART_DATA_TYPE = 'dataType';
export const FIELD_CHART_CHART_TYPE = 'chartType';
export const FIELD_CHART_GROUP_BY_TYPE = 'groupByType';
export const FIELD_CHART_DATE_RELATIVE = 'dateRelative';
// weight
export const FIELD_WEIGHT = 'weight';
export const FIELD_MAX_WEIGHT = 'maxWeight';
export const FIELD_MIN_WEIGHT = 'minWeight';
export const FIELD_WEIGHT_UOM = 'weightUom';
// temperature
export const FIELD_TEMPERATURE = 'temperature';
export const FIELD_MIN_TEMPERATURE = 'minTemperature';
export const FIELD_MAX_TEMPERATURE = 'maxTemperature';
export const FIELD_TEMPERATURE_UOM = 'temperatureUOM';
// quantity
export const FIELD_QUANTITY = 'quantity';
export const FIELD_MIN_QUANTITY = 'minQuantity';
export const FIELD_MAX_QUANTITY = 'maxQuantity';
export const FIELD_QUANTITY_UOM = 'quantityUOM';
// communication
export const FIELD_MESSAGE = 'message';
export const FIELD_EMAIL_TO = 'emailTo';
export const FIELD_EMAIL_FROM = 'emailFrom';
export const FIELD_MESSAGE_SUBJECT = 'subject';
// reference
export const FIELD_REFERENCE = 'reference';
export const FIELD_REFERENCES = 'references';
export const FIELD_REFERENCE_SCOPES = 'scopes';
export const FIELD_REFERENCE_NAME = 'referenceName';
export const FIELD_REFERENCE_TYPE_GUID = 'referenceTypeGuid';
export const FIELD_REFERENCE_ALLOWED_VALUES = 'allowedValues';
export const FIELD_REFERENCE_TYPE_VALUE = 'referenceTypeValue';
export const FIELD_REFERENCE_FIELD_NAME = 'referenceFieldName';
export const FIELD_PARENT_REFERENCE_GUID = 'parentReferenceGuid';
export const FIELD_REFERENCE_INTEGRATION_CODE = 'integrationCode';
// user
export const FIELD_USER_NAL_ID = 'nalId';
export const FIELD_USER_LOCALE = 'locale';
export const FIELD_USER_TYPE = 'userType';
export const FIELD_USER_EMAIL = FIELD_EMAIL;
export const FIELD_USER_LOGIN_ID = 'loginId';
export const FIELD_USER_SIGNATURE = 'signature';
export const FIELD_USER_PASSWORD = FIELD_PASSWORD;
export const FIELD_USER_LAST_NAME = FIELD_LAST_NAME;
export const FIELD_USER_FIRST_NAME = FIELD_FIRST_NAME;
export const FIELD_USER_FAX_NUMBER = FIELD_FAX_NUMBER;
export const FIELD_USER_PHONE_NUMBER = FIELD_PHONE_NUMBER;
export const FIELD_USER_SMS_SENDER_NAME = 'smsSenderName';
// role
export const FIELD_ROLE_NAME = 'name';
export const FIELD_ROLE_ROLES = 'roles';
export const FIELD_ROLE_ROLE_TYPE = 'roleType';
export const FIELD_ROLE_ROLES_GUIDS = 'rolesGuids';
export const FIELD_ROLE_USER_PERMISSION_READ = 'READ';
export const FIELD_ROLE_USER_PERMISSION_WRITE = 'WRITE';
export const FIELD_ROLE_USER_PERMISSIONS = 'permissions';
export const FIELD_ROLE_PERMISSION_NAME = 'permissionName';
export const FIELD_ROLE_USER_PERMISSION_EXECUTE = 'EXECUTE';
export const FIELD_ROLE_ROLE_PERMISSIONS = 'rolePermissions';
export const FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS = 'availableDocumentTypeGuids';
// fleet
export const FIELD_IDLE_TIME = 'idleTime';
export const FIELD_FLEET_YEAR = FIELD_YEAR;
export const FIELD_FLEET_EMAIL = FIELD_EMAIL;
export const FIELD_FLEET_LOGIN_ID = 'loginId';
export const FIELD_FLEET_PASSWORD = 'password';
export const FIELD_FLEET_BIRTH_DATE = 'birthDate';
export const FIELD_FLEET_IN_SERVICE = 'inService';
export const FIELD_FLEET_INTEGRATION_TYPE = 'type';
export const FIELD_FLEET_PAYED_HOURS = 'payedHours';
export const FIELD_FLEET_EXTERNAL_ID = 'externalId';
export const FIELD_FLEET_LOCATION_RADIUS = 'radius';
export const FIELD_FLEET_FUEL_CARD_ID = 'fuelCardId';
export const FIELD_FLEET_LAST_NAME = FIELD_LAST_NAME;
export const FIELD_EMPLOYEE_NUMBER = 'employeeNumber';
export const FIELD_FLEET_MOBILE_PHONE = 'mobilePhone';
export const FIELD_FLEET_PHONE_NUMBER = 'phoneNumber';
export const FIELD_FLEET_TRUCK_UNIT_ID = 'truckUnitId';
export const FIELD_AUTO_ACCEPT_RATE = 'autoAcceptRate';
export const FIELD_FLEET_FIRST_NAME = FIELD_FIRST_NAME;
export const FIELD_TEAM_DRIVER_GUID = 'teamDriverGuid';
export const FIELD_FLEET_DRIVER_NUMBER = 'driverNumber';
export const FIELD_FLEET_TRANSPONDER_ID = 'transponderId';
export const FIELD_DOMICILE_TERMINAL = 'domicileTerminal';
export const FIELD_ASSIGNED_DIVISION = 'assignedDivision';
export const FIELD_FLEET_LOCATION_RADIUS_UOM = 'radiusUom';
export const FIELD_FLEET_ADD_EXTERNAL_ID = 'addExternalId';
export const FIELD_FLEET_IN_SERVICE_DATE = 'inServiceDate';
export const FIELD_EXPEDITED_DIVISION = 'expeditedDivision';
export const FIELD_FLEET_EDIT_EXTERNAL_ID = 'editExternalId';
export const FIELD_FLEET_OUT_SERVICE_DATE = 'outServiceDate';
export const FIELD_FLEET_TERMINATION_DATE = 'terminationDate';
export const FIELD_UNEMPLOYMENT_REASON = 'unemploymentReason';
export const FIELD_FLEET_CURRENT_HIRE_DATE = 'currentHireDate';
export const FIELD_FLEET_ORIGINAL_HIRE_DATE = 'originalHireDate';
export const FIELD_FLEET_PREVIOUS_HIRE_DATE = 'previousHireDate';
export const FIELD_FLEET_OUT_OF_SERVICE_DATE = 'outOfServiceDate';
export const FIELD_SECONDARY_PHONE_NUMBER = 'secondaryPhoneNumber';
export const FIELD_FLEET_PRIMARY_PHONE_NUMBER = 'primaryPhoneNumber';
// fleet equipment service
export const FIELD_INTERVAL = 'interval';
export const FIELD_PO_NUMBER = 'poNumber';
export const FIELD_SERVICE_ID = 'serviceId';
export const FIELD_SERVICE_DATE = 'serviceDate';
export const FIELD_INTERVAL_UOM = 'intervalUom';
export const FIELD_PERFORMED_DATE = 'performedDate';
export const FIELD_PERFORMED_TYPE = 'performedType';
export const FIELD_PERFORMED_VALUE = 'performedValue';
export const FIELD_COMPONENT_GUIDS = 'componentGuids';
// inspection
export const FIELD_VEHICLE_TYPE = 'vehicleType';
export const FIELD_SECTION_GUID = 'sectionGuid';
export const FIELD_INSPECTION_SECTIONS = 'sections';
export const FIELD_ENTERPRISE_NAME = 'enterpriseName';
export const FIELD_INSPECTION_GUID = 'inspectionGuid';
export const FIELD_INSPECTION_COMPONENTS = 'components';
export const FIELD_SIGNATURE_REQUIRED = 'signatureRequired';
export const FIELD_VEHICLE_COMPONENT_TYPE = 'vehicleComponentType';
export const FIELD_ENABLE_LOCATION_TRACKING = 'enableLocationTracking';
export const FIELD_INSPECTION_COMPONENT_GUID = 'inspectionComponentGuid';

// document configuration
export const FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_NAME = 'name';
export const FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FILE = 'file';
export const FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_NOTES = 'notes';
export const FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_FORMAT = 'format';
export const FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE = 'dataSource';
export const FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_PRINTABLE_SECTION = 'printableSection';
export const FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DOCUMENT_TYPE_GUID = FIELD_DOCUMENT_TYPE_GUID;
export const FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_SPECIFIC_DOCUMENT_FEATURES =
  'specificDocumentFeatures';
// notification configuration
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_NAME = 'name';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE = 'value';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER = 'trigger';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL = 'warningLevel';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ROLE = FIELD_ROLE_GUIDS;
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES = 'emailTypes';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_SUBJECT = 'mailSubject';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE = FIELD_OBJECT_TYPE;
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_HOURS_BEFORE = 'hoursBefore';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_EMAIL = 'EMAIL';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES_STATIC = 'STATIC';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ALARM_ENABLED = 'alarmEnabled';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES = 'messageTypes';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_STATIC_EMAILS = 'staticEmails';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE = 'conditionType';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TEL_STATUS = 'TEL_STATUS';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CLO_STATUS = 'CLO_STATUS';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES_CLO_RATE = 'CLO_RATE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES = 'referenceTypes';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_MACRO_POINT = 'MACRO_POINT';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_INTERNAL = 'INTERNAL';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_DOCUMENT_SOURCE = 'DOCUMENT_SOURCE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION = FIELD_OPERATION;
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_GUID = 'mailTemplateGuid';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES_CLO_BILL_TO = 'CLO_BILL_TO';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_LATE_DELIVERY = 'LATE_DELIVERY';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_TYPES = 'mailTemplateTypes';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_STATUS_MESSAGE = 'STATUS_MESSAGE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_DETENTION = 'EVENT_DETENTION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EDI_TRANSACTION = 'EDI_TRANSACTION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_LATE_PREDICTION = 'LATE_PREDICTION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_UNPLANNED_EVENT = 'UNPLANNED_EVENT';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_LATE_DATE = 'EVENT_LATE_DATE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_EARLY_DATE = 'EVENT_EARLY_DATE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_MARGIN_VIOLATION = 'MARGIN_VIOLATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_INTEGRATION_ERROR = 'INTEGRATION_ERROR';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_MACRO_POINT_STATUS = 'MACRO_POINT_STATUS';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_TERMINATION = 'DRIVER_TERMINATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_SERVICE_DATE = 'TRUCK_SERVICE_DATE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_ORDER_QUOTE_ACCEPT = 'ORDER_QUOTE_ACCEPT';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CHAT_MESSAGE = 'DRIVER_CHAT_MESSAGE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_APPOINTMENT_REQUIRED = 'APPOINTMENT_REQUIRED';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_SERVICE_DATE = 'TRAILER_SERVICE_DATE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_TEMPLATE_KEY = 'mailTemplateTemplateKey';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_NO_STATUS_UPDATE = 'EVENT_NO_STATUS_UPDATE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DISPATCHER_CHAT_MESSAGE = 'DISPATCHER_CHAT_MESSAGE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_IN_GEOFENCING_LOCATION =
  'TRAILER_IN_GEOFENCING_LOCATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EDI_TRANSACTION_ORDER_EXPIRATION =
  'EDI_TRANSACTION_ORDER_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_DATES_UPDATE_FROM_EDI =
'EVENT_DATES_UPDATE_FROM_EDI';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CONTAINER_RAIL_BILLING_COMPLETED =
'CONTAINER_RAIL_BILLING_COMPLETED';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_ORDER_QUOTE_RATE_PROVIDED =
'ORDER_QUOTE_RATE_PROVIDED';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_ORDER_QUOTE_RATE_REQUESTED =
  'ORDER_QUOTE_RATE_REQUESTED';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_BRANCH_CREDIT_LIMIT_PERCENT =
  'ENTERPRISE_CREDIT_LIMIT_PERCENT';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_WATCHLIST_DETACH_REMOVED =
  'CARRIER_WATCHLIST_DETACH_REMOVED';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_APPOINTMENT_REQUIRED_AFTER_CREATE =
  'APPOINTMENT_REQUIRED_AFTER_CREATE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCKER_TOOLS_BOOK_IT_NOW =
  'TRUCKER_TOOLS_BOOK_IT_NOW';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_LICENSE_EXPIRATION =
  'DRIVER_LICENSE_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_DOCUMENT_EXPIRATION =
  'TRUCK_DOCUMENT_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_INSURANCE_EXPIRATION =
  'TRUCK_INSURANCE_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_DOCUMENT_EXPIRATION =
  'CARRIER_DOCUMENT_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_DOCUMENTS_EXPIRATION =
  'DRIVER_DOCUMENTS_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_CONTRACT_EXPIRATION =
  'CARRIER_CONTRACT_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_INSURANCE_EXPIRATION =
  'CARRIER_INSURANCE_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_DEACTIVATION =
  'CARRIER_DEACTIVATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_SCANNED_VIN_MISMATCH =
  'SCANNED_VIN_MISMATCH';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_DOCUMENT_EXPIRATION =
  'TRAILER_DOCUMENT_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_INSURANCE_EXPIRATION =
  'TRAILER_INSURANCE_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CERTIFICATE_EXPIRATION =
  'DRIVER_CERTIFICATE_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_REGISTRATION_EXPIRATION =
  'TRUCK_REGISTRATION_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_CERTIFICATE_EXPIRATION =
  'CARRIER_CERTIFICATE_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_REGISTRATION_EXPIRATION =
  'TRAILER_REGISTRATION_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CITIZENSHIP_VISA_EXPIRATION =
  'DRIVER_CITIZENSHIP_VISA_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_COMPONENT_WARRANTY_EXPIRATION =
  'TRUCK_COMPONENT_WARRANTY_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_STATUS_MESSAGE_STATUS_TYPE_COMPLETED =
  'STATUS_MESSAGE_STATUS_TYPE_COMPLETED';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_COMPONENT_WARRANTY_EXPIRATION =
  'TRAILER_COMPONENT_WARRANTY_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_COMPENSATION_DETAILS_EXPIRATION =
  'DRIVER_COMPENSATION_DETAILS_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CITIZENSHIP_PASSPORT_EXPIRATION =
  'DRIVER_CITIZENSHIP_PASSPORT_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CITIZENSHIP_WORK_AUTH_EXPIRATION =
  'DRIVER_CITIZENSHIP_WORK_AUTH_EXPIRATION';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CITIZENSHIP_GREEN_CARD_EXPIRATION =
  'DRIVER_CITIZENSHIP_GREEN_CARD_EXPIRATION';
// notification configuration condition types
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_HOURS = 'HOURS';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_PERCENT = 'PERCENT';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_CLO_STATUS = 'CLO_STATUS';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_TEL_STATUS = 'TEL_STATUS';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_STATUS_CODE = 'STATUS_CODE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_DAYS_BEFORE = 'DAYS_BEFORE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_HOURS_BEFORE = 'HOURS_BEFORE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_WARNING_LEVEL = 'WARNING_LEVEL';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_HOURS_IN_STOP = 'HOURS_IN_STOP';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_MINUTES_AFTER = 'MINUTES_AFTER';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_MINUTES_BEFORE = 'MINUTES_BEFORE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_MACRO_POINT_STATUS = 'MACRO_POINT_STATUS';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_HOURS_AFTER_CREATE =
  'HOURS_AFTER_CREATE';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_CARRIER_RATE_TRAILER_UPDATE_ACTION =
  'CARRIER_RATE_TRAILER_UPDATE_ACTION';
// notification configuration object types
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_TEL = 'TEL';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_CLO = 'CLO';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_EDI = 'EDI';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_IFTA = 'IFTA';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_TRUCK = 'TRUCK';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_BRANCH = 'BRANCH';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_DRIVER = 'DRIVER';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_TRAILER = 'TRAILER';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_CARRIER = 'CARRIER';
export const FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_ORDER_QUOTE = 'ORDER_QUOTE';
// carrier configuration
export const CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DECLINE = 'STATISTIC_DECLINE';
export const CARRIER_DEACTIVATION_RULE_TRIGGER_INSURANCE_EXPIRATION = 'INSURANCE_EXPIRATION';
export const CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_TEL = 'STATISTIC_ON_TIME_TEL';
export const CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_INVOICE_TIME = 'STATISTIC_INVOICE_TIME';
export const CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_DROP = 'STATISTIC_ON_TIME_DROP';
export const CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_DOCUMENT_TIME = 'STATISTIC_DOCUMENT_TIME';
export const CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_ON_TIME_PICKUP = 'STATISTIC_ON_TIME_PICKUP';
export const CARRIER_DEACTIVATION_RULE_TRIGGER_STATISTIC_EVENT_COMPLETE_BY_CARRIER =
  'STATISTIC_EVENT_COMPLETE_BY_CARRIER';
// operations
export const FIELD_OPERATION_IN = 'IN';
export const FIELD_OPERATION_MORE = 'MORE';
export const FIELD_OPERATION_EQUAL = 'EQUAL';
export const FIELD_OPERATION_RANGE = 'RANGE';
export const FIELD_OPERATION_NOT_EQUAL = 'NOT_EQUAL';
// fuel-cards configuration
export const FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_TYPE = 'type';
export const FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_LOGIN = 'login';
export const FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_PASSWORD = 'password';
export const FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_CARRIER_ID = 'carrierId';
export const FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_START_CODE = 'startCode';
export const FIELD_CONFIGURATION_INTEGRATION_FUEL_CARD_DEACTIVATE_CARD_AFTER_MINUTER = 'deactivateCardAfterMinutes';
// EDI configuration
export const FIELD_SCAC_OVERRIDE = 'scacOverride';
export const FIELD_TEL_REFERENCES = 'telReferences';
export const FIELD_DROP_STOP_TYPES = 'dropStopTypes';
export const FIELD_DECLINE_REASONS = 'declineReasons';
export const FIELD_EVENT_REFERENCES = 'eventReferences';
export const FIELD_PICKUP_STOP_TYPES = 'pickupStopTypes';
export const FIELD_AUTO_CREATE_ORDER = 'autoCreateOrder';
export const FIELD_TRANSACTION_TYPES = 'transactionTypes';
export const FIELD_INVOICE_REFERENCES = 'invoiceReferences';
export const FIELD_RECEIVER_QUALIFIER = 'receiverQualifier';
export const FIELD_GENERATE_PRO_NUMBER = 'generateProNumber';
export const FIELD_PRO_NUMBER_SEQUENCE = 'proNumberSequence';
export const FIELD_EXPORTER_CONFIG_GUID = 'exporterConfigGuid';
export const FIELD_PRO_NUMBER_REFERENCE = 'proNumberReference';
export const FIELD_SEND_CARRIER_LOCATION = 'sendCarrierLocation';
export const FIELD_REFERENCES_TO_COMMENT = 'referencesToComment';
export const FIELD_AUTO_UPDATE_EVENT_DATES = 'autoUpdateEventDates';
export const FIELD_EVENT_MATCHING_REFERENCE = 'eventMatchingReference';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID = 'senderId';
export const FIELD_STOP_NUMBER_REFERENCE_CODE = 'stopNumberReferenceCode';
export const FIELD_ORIGIN_LOCATION_IDENTIFIER = 'originLocationIdentifier';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_AUTO_ACCEPT = 'autoaccept';
export const FIELD_BILL_TO_LOCATION_IDENTIFIER = 'billToLocationIdentifier';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_FOLDER_NAME = 'folderName';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_DEFAULT_ITEM_ID = 'defaultItemId';
export const FIELD_STATUS_MESSAGE_ORDER_REFERENCES = 'statusMessageOrderReferences';
export const FIELD_STATUS_MESSAGE_EVENT_REFERENCES = 'statusMessageEventReferences';
export const FIELD_INVOICE_ASSOCIATED_DOCUMENT_TYPES = 'invoiceAssociatedDocumentTypes';
export const FIELD_LOCATION_IDENTIFICATION_QUALIFIER = 'locationIdentificationQualifier';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_USE_ITEM_TEMPLATES = 'useItemTemplates';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_PO_NUMBER_REFERENCE = 'poNumberReference';
export const FIELD_USE_SHIPMENT_NUMBER_AS_PICKUP_NUMBER = 'useShipmentNumberAsPickupNumber';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_NUMBER = FIELD_SHIPMENT_NUMBER_PRIMARY;
export const FIELD_CONFIGURATION_INTEGRATION_EDI_USE_LOCATION_TEMPLATES = 'useLocationTemplates';
export const FIELD_INVOICE_ASSOCIATED_DOCUMENT_FOLDER_NAME = 'invoiceAssociatedDocumentFolderName';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_LOAD_IDENTIFICATION_TYPE = 'loadIdentificationType';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_PRIMARY_REFERENCE = FIELD_SHIPMENT_NUMBER_REFERENCE;
export const FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_TYPE = 'senderIdentificationType';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_OVERRIDE_CONFIGURED_BILL_TO = 'overrideConfiguredBillTo';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_IDENTIFICATION_VALUE = 'senderIdentificationValue';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_ORDER_IDENTIFICATION_REFERENCE = 'orderIdentificationReference';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_LOADER_LAST_TWO_NUMBERS_ARE_PENNIES = 'lastTwoNumbersArePennies';
// EDI extractor config
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PORT = FIELD_PORT;
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL = 'protocol';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD = 'password';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME = 'username';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_FOLDER_NAME = 'folderName';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID = 'receiverId';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST = 'remoteHost';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT = 'remotePort';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_LOCAL_FOLDER = 'localFolder';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_FOLDER = 'remoteFolder';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_EVENT_WITH_STATUS = 'sendEventLocationWithStatus';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_SHIPMENT_WITH_STATUS = 'sendShipperNameWithStatus';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_STATUS_MESSAGE_REFERENCE = 'statusMessageReference';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PRIMARY_REFERENCE = FIELD_SHIPMENT_NUMBER_REFERENCE;
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_TENDER_RESPONSE_REFERENCE = 'tenderResponseReference';
// EDI exporter config
export const FIELD_CONTENT_FORMAT = 'contentFormat';
export const FIELD_INVOICE_RECEIVER_ID = 'invoiceReceiverId';
export const FIELD_SEND_APPOINTMENT_DATES = 'sendAppointmentDates';
export const FIELD_ACCEPT_DECLINE_RECEIVER_ID = 'acceptDeclineReceiverId';
export const FIELD_STATUS_MESSAGE_RECEIVER_ID = 'statusMessageReceiverId';
export const FIELD_INVOICE_RECEIVER_QUALIFIER = 'invoiceReceiverQualifier';
export const FIELD_ACKNOWLEDGEMENT_RECEIVER_ID = 'acknowledgementReceiverId';
export const FIELD_STATUS_MESSAGE_DEFAULT_TIME_ZONE = 'statusMessageDefaultTimeZone';
export const FIELD_ACCEPT_DECLINE_RECEIVER_QUALIFIER = 'acceptDeclineReceiverQualifier';
export const FIELD_STATUS_MESSAGE_RECEIVER_QUALIFIER = 'statusMessageReceiverQualifier';
export const FIELD_STATUS_MESSAGE_STOP_NUMBER_POSITION = 'statusMessageStopNumberPosition';
export const FIELD_ACKNOWLEDGEMENT_RECEIVER_QUALIFIER = 'acknowledgementReceiverQualifier';
export const FIELD_STATUS_MESSAGE_SEND_FIRST_LAST_EVENT = 'statusMessageSendFirstLastEvent';
export const FIELD_STATUS_MESSAGE_SEND_NEXT_EVENT_INDEX = 'statusMessageSendNextEventIndex';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXPORTER_LATITUDE_REFERENCE_NAME = 'latitudeReferenceName';
export const FIELD_CONFIGURATION_INTEGRATION_EDI_EXPORTER_LONGITUDE_REFERENCE_NAME = 'longitudeReferenceName';
// public clo
export const FIELD_PUBLIC_CLO_USER_LOGIN_ID = 'userLoginId';
export const FIELD_PUBLIC_CLO_USER_PASSWORD = 'userPassword';
export const FIELD_PUBLIC_API_AUTO_CREATE_ORDER = 'autoCreateOrder';
export const FIELD_PUBLIC_CLO_SHIPMENT_NUMBER_PRIMARY = FIELD_SHIPMENT_NUMBER_PRIMARY;
export const FIELD_PUBLIC_CLO_SHIPMENT_NUMBER_REFERENCE = FIELD_SHIPMENT_NUMBER_REFERENCE;
// customer integration configs
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_NAME = 'name';
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_ENABLED = 'enabled';
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_SHIPPER = 'shipper';
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_ID = 'clientId';
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_NAME = 'carrierName';
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_CODE = 'carrierCode';
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CLIENT_SECRET = FIELD_CLIENT_SECRET;
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE = 'integrationType';
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_CARRIER_DOT_NUMBER = 'carrierDotNumber';
export const FIELD_CONFIGURATION_INTEGRATION_CUSTOMER_INTEGRATION_CONFIGS_LOAD_NUMBER_REFERENCE_GUID =
  'loadNumberReferenceGuid';
// service-mapping configuration
export const FIELD_SERVICE_MAPPING_SERVICE_CODE = 'serviceCode';
export const FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID = 'serviceConfigGuid';
// quick books distribution line account mapping
export const FIELD_ACCOUNT_NAME_GUID = 'accountNameGuid';
export const FIELD_ASSESSORIAL_CONFIGURATION_GUID = 'assessorialConfigurationGuid';
export const FIELD_SERVICE_CODE_SERVICE_CONFIG_GUIDS = 'serviceCodeServiceConfigGuids';
// advance payment config
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_CUSTOMER_ID = 'customerId';
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_NAME = 'signOnName';
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_ACCOUNT_CODE = 'accountCode';
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SECURITY_INFO = 'securityInfo';
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_PLUS_LESS_FEES = 'plusLessFees';
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_NAME = 'wssSignOnName';
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_SIGN_ON_PASSWORD = 'signOnPassword';
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_INTEGRATION_TYPE = 'integrationType';
export const FIELD_CONFIGURATION_INTEGRATION_ADVANCE_PAYMENT_WSS_SIGN_ON_PASSWORD = 'wssSignOnPassword';
// ui configuration
export const FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_FIRST_ROW = 'firstRow';
export const FIELD_CONFIGURATION_UI_DISPATCH_BOARD_ACTIONS_SECOND_ROW = 'secondRow';
// mail sending
export const FIELD_MAIL_SENDING_TO = FIELD_TO;
export const FIELD_MAIL_SENDING_MESSAGE = 'text';
export const FIELD_MAIL_SENDING_SUBJECT = 'subject';
export const FIELD_MAIL_SENDING_REPLY_TO = 'replyTo';
export const FIELD_MAIL_SENDING_DOCUMENT_TEMPLATE_GUID = 'documentTemplateGuid';
export const FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS = 'attachedDocumentGuids';
// document
export const FIELD_DOCUMENT_URL = 'url';
export const FIELD_DOCUMENT_UPLOAD = 'file';
export const FIELD_DOCUMENT_STATUS = 'status';
export const FIELD_DOCUMENT_SENT_TO = 'sentTo';
export const FIELD_DOCUMENT_COMMENTS = 'comments';
export const FIELD_DOCUMENT_PROOF_TYPE = 'proofType';
export const FIELD_DOCUMENT_SIGNED_DATE = 'signedDate';
export const FIELD_DOCUMENT_DESCRIPTION = 'description';
export const FIELD_DOCUMENT_EVENT_GUID = FIELD_EVENT_GUID;
export const FIELD_DOCUMENT_DOCUMENT_GUID = 'documentGuid';
export const FIELD_DOCUMENT_DOCUMENT_TYPE = 'documentType';
export const FIELD_DOCUMENT_DOCUMENT_NAME = 'documentName';
export const FIELD_DOCUMENT_SHOWN_FOR_DRIVER = 'shownForDriver';
export const FIELD_DOCUMENT_EXPIRATION_DATE = FIELD_EXPIRATION_DATE;
export const FIELD_DOCUMENT_EXTERNAL_DOCUMENT_TYPE = 'externalDocumentType';
export const FIELD_ATTACHABLE_TO_CUSTOMER_INVOICE = 'attachableToCustomerInvoice';
// location
export const FIELD_ZIP = 'zip';
export const FIELD_CITY = 'city';
export const FIELD_STATE = 'state';
export const FIELD_ROUTE = 'route';
export const FIELD_VENDOR = 'vendor';
export const FIELD_STATES = 'states';
export const FIELD_COUNTRY = 'country';
export const FIELD_ADDRESS = 'address';
export const FIELD_PAY_TYPE = 'payType';
export const FIELD_LATITUDE = 'latitude';
export const FIELD_LOCATION = 'location';
export const FIELD_LOCALITY = 'locality';
export const FIELD_TIME_ZONE = 'timeZone';
export const FIELD_ADDRESS_1 = 'address1';
export const FIELD_ADDRESS_2 = 'address2';
export const FIELD_ADDRESS_3 = 'address3';
export const FIELD_ADDRESS_4 = 'address4';
export const FIELD_LONGITUDE = 'longitude';
export const FIELD_CROSSDOCK = 'crossdock';
export const FIELD_VENDOR_CITY = 'vendorCity';
export const FIELD_ORIGIN_ZONE = 'originZone';
export const FIELD_POSTAL_TOWN = 'postal_town';
export const FIELD_POSTAL_CODE = 'postal_code';
export const FIELD_RESIDENTIAL = 'residential';
export const FIELD_VENDOR_STATE = 'vendorState';
export const FIELD_LOCATION_NAME = 'locationName';
export const FIELD_LOCATION_TYPE = 'locationType';
export const FIELD_LICENSE_STATE = 'licenseState';
export const FIELD_LOCATION_ZONE = 'locationZone';
export const FIELD_STREET_NUMBER = 'street_number';
export const FIELD_LICENSE_NUMBER = 'licenseNumber';
export const FIELD_LOCATION_OWNER = 'locationOwner';
export const FIELD_VENDOR_COUNTRY = 'vendorCountry';
export const FIELD_VENDOR_ADDRESS = 'vendorAddress';
export const FILED_LICENSE_COUNTRY = 'licenseCountry';
export const FIELD_DESTINATION_ZONE = 'destinationZone';
export const FIELD_VENDOR_POSTAL_CODE = 'vendorPostalCode';
export const FIELD_ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1';
// contact
export const FIELD_FAX = 'fax';
export const FIELD_PHONE = 'phone';
export const FIELD_COMMENT = 'comment';
export const FIELD_COMMENTS = 'comments';
export const FIELD_CONTACTS = 'contacts';
export const FIELD_HOME_PHONE = 'homePhone';
export const FIELD_CONTACT_TYPE = 'contactType';
export const FIELD_CONTACT_NAME = 'contactName';
export const FIELD_CONTACT_POSTAL_CODE = 'postalCode';
export const FIELD_EMERGENCY_PHONE = 'emergencyPhone';
export const FIELD_EMERGENCY_CONTACT_NAME = 'emergencyContactName';
// container
export const FIELD_CONTAINER = 'container';
export const FIELD_CONTAINERS = 'containers';
export const FIELD_CONTAINER_GUID = 'containerGuid';
export const FIELD_CONTAINER_NUMBER = 'containerNumber';
export const FIELD_CONTAINER_INITIAL = 'containerInitial';
export const FIELD_CONTAINER_TYPE_GUID = 'containerTypeGuid';
export const FIELD_CONTAINER_INTERNAL_ID = 'containerInternalId';
export const FIELD_CONTAINER_NUMBER_WITH_INITIAL = 'containerNumberWithInitial';
// item
export const FIELD_VIN = 'vin';
export const FIELD_BAY = 'bay';
export const FIELD_MAKE = 'make';
export const FIELD_MODEL = 'model';
export const FIELD_COLOR = 'color';
export const FIELD_ITEM_GUID = 'guid';
export const FIELD_ITEM_ID = 'itemId';
export const FIELD_ODOMETER = 'odometer';
export const FIELD_ITEM_N_M_F_C = 'nmfc';
export const FIELD_ITEM_S_T_C_C = 'stcc';
export const FIELD_ITEM_E_C_C_N = 'eccn';
export const FIELD_ITEM_WEIGHT = 'weight';
export const FIELD_SUB_MODEL = 'subModel';
export const FIELD_ITEM_VOLUME = 'volume';
export const FIELD_ITEM_TYPE = 'itemType';
export const FIELD_WHEELBASE = 'wheelbase';
export const FIELD_ITEM_VERSION = 'version';
export const FIELD_ITEM_TEL_GUID = 'telGuid';
export const FIELD_ITEM_CLO_GUID = 'cloGuid';
export const FIELD_INTERNAL_ID = 'internalId';
export const FIELD_ITEM_LOAD_GUID = 'loadGuid';
export const FIELD_OPERATIONAL = 'operational';
export const FIELD_CLOSED_TOTAL = 'closedTotal';
export const FIELD_ODOMETER_UOM = 'odometerUom';
export const FIELD_ITEM_COMMODITY = 'commodity';
export const FIELD_ITEM_HAZARDOUS = 'hazardous';
export const FIELD_ITEM_VOLUME_UOM = 'volumeUOM';
export const FIELD_MANUFACTURER = 'manufacturer';
export const FIELD_WHEELBASE_UOM = 'wheelbaseUom';
export const FIELD_AWARDED_VALUE = 'awardedValue';
export const FIELD_ITEM_QUANTITY = FIELD_QUANTITY;
export const FIELD_DAMAGED_VALUE = 'damagedValue';
export const FIELD_PRODUCT_VALUE = 'productValue';
export const FIELD_ITEM_WEIGHT_TYPE = 'weightType';
export const FIELD_ITEM_PACKAGE_TYPE = 'packageType';
export const FIELD_ITEM_HAZMAT_CLASS = 'hazmatClass';
export const FIELD_ITEM_STACKABILITY = 'stackability';
export const FIELD_ITEM_FREIGHT_CLASS = 'freightClass';
export const FIELD_ITEM_INTERNAL_ID = 'itemInternalId';
export const FIELD_ITEM_DECLARED_VALUE = 'declaredValue';
export const FIELD_ITEM_MONETARY_VALUE = 'monetaryValue';
export const FIELD_ITEM_HAZMAT_COMMENT = 'hazmatComment';
export const FIELD_ITEM_COMMODITY_TYPE = 'commodityType';
export const FIELD_ITEM_DIMENSIONS_UOM = 'dimensionsUOM';
export const FIELD_ITEM_DIMENSIONS_WIDTH = 'dimensionsW';
export const FIELD_ITEM_DIMENSIONS_LENGTH = 'dimensionsL';
export const FIELD_ITEM_DIMENSIONS_HEIGHT = 'dimensionsH';
export const FIELD_OUTSTANDING_TOTAL = 'outstandingTotal';
export const FIELD_ITEM_TEMPERATURE_UOM = 'temperatureUOM';
export const FIELD_ITEM_HAZMAT_PLACARDS = 'hazmatPlacards';
export const FIELD_ITEM_TEMPERATURE_LOW = 'temperatureLow';
export const FIELD_ITEM_PICKED_UP_VOLUME = 'pickedUpVolume';
export const FIELD_ITEM_PICKED_UP_WEIGHT = 'pickedUpWeight';
export const FIELD_ITEM_TEMPERATURE_TYPE = 'temperatureType';
export const FIELD_ITEM_TEMPERATURE_HIGH = 'temperatureHigh';
export const FIELD_ITEM_HAZMAT_EMS_NUMBER = 'hazmatEmsNumber';
export const FIELD_ITEM_HARMONIZED_TARIFF = 'harmonizedTariff';
export const FIELD_ITEM_DECLARED_CURRENCY = 'declaredCurrency';
export const FIELD_ITEM_HAZMAT_FLASHPOINT = 'hazmatFlashpoint';
export const FIELD_ITEM_PICKED_UP_QUANTITY = 'pickedUpQuantity';
export const FIELD_ITEM_TEMPERATURE_SENSOR = 'temperatureSensor';
export const FIELD_ITEM_HAZMAT_CONTACT_NAME = 'hazmatContactName';
export const FIELD_ITEM_HAZMAT_CONTACT_PHONE = 'hazmatContactPhone';
export const FIELD_ITEM_CUSTOMER_PART_NUMBER = 'customerPartNumber';
export const FIELD_ITEM_HAZMAT_SHIPPING_NAME = 'hazmatShippingName';
export const FIELD_ITEM_HAZMAT_PACKAGE_GROUP = 'hazmatPackageGroup';
export const FIELD_ITEM_HAZMAT_FLASHPOINT_UOM = 'hazmatFlashpointUOM';
export const FIELD_ITEM_HAZMAT_UN_OR_NA_NUMBER = 'hazmatUnOrNaNumber';
export const FIELD_ITEM_STACKABILITY_CONDITION = 'stackabilityCondition';
export const FIELD_ITEM_MONETARY_VALUE_CURRENCY = 'monetaryValueCurrency';
export const FIELD_ITEM_HAZMAT_PLACARDS_DETAILS = 'hazmatPlacardsDetails';
// damages
export const FIELD_AREA = 'area';
export const FIELD_SEVERITY = 'severity';
// event
export const FIELD_STOP_DROP = 'drop';
export const FIELD_STOP_ID = 'stopId';
export const FIELD_STOP_ITEMS = 'items';
export const FIELD_STOP_PICKUP = 'pickup';
export const FIELD_STOP_TYPE = 'stopType';
export const FIELD_CLO_EVENT = 'cloEvent';
export const FIELD_TEL_EVENT = 'cloEvent';
export const FIELD_LATE_DATE = 'lateDate';
export const FIELD_STOP_DATE = 'stopDate';
export const FIELD_EARLY_DATE = 'earlyDate';
export const FIELD_EVENT_TYPE = 'eventType';
export const FIELD_STOP_ITEM_IDS = 'itemIds';
export const FIELD_STOP_NUMBER = 'stopNumber';
export const FIELD_EVENT_COUNT = 'eventCount';
export const FIELD_ARRIVE_DATE = 'arriveDate';
export const FIELD_TEL_EVENT_INDEX = 'telEventIndex';
export const FIELD_CLO_EVENT_INDEX = 'cloEventIndex';
export const FIELD_DISTANCE_MANUAL = 'manualDistance';
export const FIELD_DISTANCE_SYSTEM = 'systemDistance';
export const FIELD_DISTANCE_TEL = 'telDistanceToNextStop';
export const FIELD_DISTANCE_CLO = 'cloDistanceToNextStop';
export const FIELD_RELATED_DROP_GUIDS = 'relatedDropGuids';
export const FIELD_EVENT_CHECK_IN_DATE = 'eventCheckinDate';
export const FIELD_STOP_DROPPED_TRAILERS = 'droppedTrailers';
export const FIELD_DISTANCE_MANUAL_UOM = 'manualDistanceUom';
export const FIELD_DISTANCE_SYSTEM_UOM = 'systemDistanceUom';
export const FIELD_EVENT_COMPLETE_DATE = 'eventCompleteDate';
export const FIELD_STOP_PICKED_UP_TRAILERS = 'pickedUpTrailers';
export const FIELD_STOP_DROPPED_CONTAINERS = 'droppedContainers';
export const FIELD_APPOINTMENT_LATE_DATE = 'appointmentLateDate';
export const FIELD_APPOINTMENT_EARLY_DATE = 'appointmentEarlyDate';
export const FIELD_STOP_PICKED_UP_CONTAINERS = 'pickedUpContainers';
export const FIELD_STOP_DROPPED_CONTAINER_IDS = 'droppedContainerIds';
export const FIELD_STOP_DROPPED_TRAILER_GUIDS = 'droppedTrailerGuids';
export const FIELD_STOP_PICKED_UP_TRAILER_GUIDS = 'pickedUpTrailerGuids';
export const FIELD_STOP_PICKED_UP_CONTAINER_IDS = 'pickedUpContainerIds';
// status message
export const FIELD_PROOF_TYPE = 'proofType';
export const FIELD_STATUS_MESSAGE_GUID = 'guid';
export const FIELD_STATUS_MESSAGE_DATE = 'statusDate';
export const FIELD_STATUS_MESSAGE_CODE = 'statusCode';
export const FIELD_STATUS_MESSAGE_TRACKING = 'tracking';
export const FIELD_STATUS_MESSAGE_LOAD_GUID = 'loadGuid';
export const FIELD_STATUS_MESSAGE_LOAD_EVENT = 'loadEvent';
export const FIELD_STATUS_MESSAGE_REASON_CODES = 'reasonCodes';
export const FIELD_STATUS_MESSAGE_ENT_GUID = OWNING_BRANCH_GUID;
export const FIELD_STATUS_MESSAGE_REASON_CODE = 'statusReasonCode';
export const FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID = 'loadEventGuid';
export const FIELD_STATUS_MESSAGE_REASON_REQUIRED = 'reasonRequired';
export const FIELD_STATUS_MESSAGE_WARNING_LEVEL = 'statusWarningLevel';
// rate/invoice/formik
export const FIELD_RATE = 'rate';
export const FIELD_TOTAL = 'total';
export const FIELD_MARGIN = 'margin';
export const FIELD_APPLY_TO = 'applyTo';
export const FIELD_DISTRICT = 'district';
export const FIELD_CURRENCY = 'currency';
export const FIELD_DISCOUNT = 'discount';
export const FIELD_MIN_STOPS = 'minStops';
export const FIELD_MAX_STOPS = 'maxStops';
export const ITEMS_VOLUME = 'itemsVolume';
export const FIELD_RATE_TYPE = 'rateType';
export const FIELD_RATE_UNIT = 'rateUnit';
export const FIELD_DEDUCTION = 'deduction';
export const FIELD_TRUCK_GUID = 'truckGuid';
export const FIELD_BOL_NUMBER = 'bolNumber';
export const FIELD_MIN_MARGIN = 'minMargin';
export const FIELD_RATE_TOTAL = 'rateTotal';
export const FIELD_SEND_TO_EDI = 'sentToEdi';
export const FIELD_NON_TAXABLE = 'nonTaxable';
export const FIELD_GRAND_TOTAL = 'grandTotal';
export const FIELD_PAYED_HOURS = 'payedHours';
export const FIELD_PAY_FOR_UNIT = 'payForUnit';
export const FIELD_MODE = 'transportationMode';
export const FIELD_TRIP_MAX_PAY = 'tripMaxPay';
export const FIELD_CHECK_NUMBER = 'checkNumber';
export const FIELD_DEPOSIT_DATE = 'depositDate';
export const FIELD_UPLIFT_VALUE = 'upliftValue';
export const FIELD_SYNC_CHARGES = 'syncCharges';
export const FIELD_TRANSIT_DAYS = 'transitDays';
export const FIELD_SERVICE_TYPE = 'serviceType';
export const FIELD_PAYMENT_TERM = 'paymentTerm';
export const FIELD_FUEL_RELATED = 'fuelRelated';
export const FIELD_SERVICE_DAYS = 'serviceDays';
export const FIELD_PACKAGE_TYPE = 'packageType';
export const FIELD_TOTAL_VOLUME = 'totalVolume';
export const FIELD_TRUCK_UNIT_ID = FIELD_UNIT_ID;
export const ITEMS_VOLUME_UOM = 'itemsVolumeUom';
export const FIELD_CHARGES_TOTAL = 'chargesTotal';
export const FIELD_TRAILER_GUIDS = 'trailerGuids';
export const FIELD_INVOICE_COUNT = 'invoiceCount';
export const FIELD_FACTORING_FEE = 'factoringFee';
export const FIELD_DISPATCH_NAME = 'dispatchName';
export const FIELD_DISCOUNT_TYPE = 'discountType';
export const FIELD_TRUCK_UNIT_IDS = 'truckUnitIds';
export const FIELD_DISPATCH_PHONE = 'dispatchPhone';
export const FIELD_INVOICES_TOTAL = 'invoicesTotal';
export const FIELD_TOTAL_DISCOUNT = 'totalDiscount';
export const FIELD_CHASSIS_NUMBER = 'chassisNumber';
export const FIELD_INVOICES_COUNT = 'invoicesCount';
export const FIELD_FUEL_INDEX_INFO = 'fuelIndexInfo';
export const FIELD_DISPATCH_EMAILS = 'dispatchEmails';
export const FIELD_CRITICAL_MARGIN = 'criticalMargin';
export const FIELD_BOL_PAYMENT_TERM = 'bolPaymentTerm';
export const FIELD_TOTAL_VOLUME_UOM = 'totalVolumeUom';
export const FIELD_MAX_PAY_VIOLATED = 'maxPayViolated';
export const FIELD_TRAILER_UNIT_IDS = 'trailerUnitIds';
export const FIELD_NORMALIZED_TOTAL = 'normalizedTotal';
export const FIELD_TOTAL_QUANTITIES = 'totalQuantities';
export const FIELD_FLEET_ASSIGNMENT = 'fleetAssignment';
export const FIELD_READY_FOR_BILLING = 'readyForBilling';
export const FIELD_TOTAL_TRIP_WEIGHT = 'totalTripWeight';
export const FIELD_DISTANCE_TO_START = 'distanceToStart';
export const FIELD_TOTAL_TRIP_VOLUME = 'totalTripVolume';
export const FIELD_ROUTE_TOTAL_SPEND = 'routeTotalSpend';
export const FIELD_DEADHEAD_DISTANCE = 'deadHeadDistance';
export const FIELD_ROUTE_TOTAL_INCOME = 'routeTotalIncome';
export const FIELD_SELECTED_RATE_GUID = 'selectedRateGuid';
export const FIELD_TRUCK_TYPE_CONFIG_GUID = 'truckTypeGuid';
export const FIELD_PRIMARY_DRIVER_GUID = 'primaryDriverGuid';
export const FIELD_SERVICE_VENDOR_GUID = 'serviceVendorGuid';
export const FIELD_TOTAL_TRIP_DISTANCE = 'totalTripDistance';
export const FIELD_TOTAL_RATE_DISTANCE = 'totalRateDistance';
export const FIELD_STORED_TOTAL_WEIGHT = 'storedTotalWeight';
export const FIELD_ORIGIN_CARRIER_NAME = 'originCarrierName';
export const FIELD_STORED_TOTAL_VOLUME = 'storedTotalVolume';
export const FIELD_FLEET_VENDOR_CHARGES = 'fleetVendorCharges';
export const FIELD_CLO_RATE_ADJUSTMENTS = 'cloRateAdjustments';
export const FIELD_START_POINT_LOCATION = 'startPointLocation';
export const FIELD_TRANSPORTING_CARRIER = 'transportingCarrier';
export const FIELD_TOTAL_TRIP_VOLUME_UOM = 'totalTripVolumeUom';
export const FIELD_TOTAL_TRIP_WEIGHT_UOM = 'totalTripWeightUom';
export const FIELD_SERVICE_TYPE_CONFIG_GUID = 'serviceTypeGuid';
export const FIELD_TRAILER_TYPE_CONFIG_GUID = 'trailerTypeGuid';
export const FIELD_DEADHEAD_DISTANCE_UOM = 'deadHeadDistanceUom';
export const FIELD_FINISH_POINT_LOCATION = 'finishPointLocation';
export const FIELD_SECONDARY_DRIVER_GUID = 'secondaryDriverGuid';
export const FIELD_STORED_TOTAL_DISTANCE = 'storedTotalDistance';
export const FIELD_TOTAL_PICKUP_QUANTITY = 'totalPickupQuantity';
export const FIELD_BOL_DOCUMENT_TYPE_GUID = 'bolDocumentTypeGuid';
export const FIELD_DISPLAYED_TOTAL_VOLUME = 'displayedTotalVolume';
export const FIELD_PRIMARY_DRIVER_CHARGES = 'primaryDriverCharges';
export const FIELD_DISPATCH_OR_CANCEL_DATE = 'dispatchOrCancelDate';
export const FIELD_TOTAL_TRIP_DISTANCE_UOM = 'totalTripDistanceUom';
export const FIELD_ONLY_INCLUDE_TO_INVOICE = 'onlyIncludeToInvoice';
export const FIELD_NORMALIZED_TRIP_MAX_PAY = 'normalizedTripMaxPay';
export const FIELD_STORED_TOTAL_TRIP_VOLUME = 'storedTotalTripVolume';
export const FIELD_SELECTED_RATE_PRICE_GUID = 'selectedRatePriceGuid';
export const FIELD_DISPATCHED_OR_CANCEL_BY = 'dispatchedOrCanceledBy';
export const FIELD_LAST_EVENT_COMPLETE_DATE = 'lastEventCompleteDate';
export const FIELD_STORED_DEADHEAD_DISTANCE = 'storedDeadHeadDistance';
export const FIELD_DRAY_CONTAINER_DROP_NUMBER = 'drayContainerDropNum';
export const FIELD_SECONDARY_DRIVER_CHARGES = 'secondaryDriverCharges';
export const FIELD_DESTINATION_CARRIER_NAME = 'destinationCarrierName';
export const FIELD_DISPLAY_DISTANCE_TO_START = 'displayDistanceToStart';
export const FIELD_FIRST_EVENT_COMPLETE_DATE = 'firstEventCompleteDate';
export const FIELD_TRANSPORTING_CARRIER_NAME = 'transportingCarrierName';
export const FIELD_TRANSPORTATION_MODE_GUIDS = 'transportationModeGuids';
export const FIELD_PRICE_SHEET_CURRENCY_TOTAL = 'priceSheetCurrencyTotal';
export const FIELD_STORED_TOTAL_TRIP_DISTANCE = 'storedTotalTripDistance';
export const FIELD_DRAY_CONTAINER_PICK_UP_NUMBER = 'drayContainerPickupNum';
export const FIELD_DISPLAY_DISTANCE_TO_START_UOM = 'displayDistanceToStartUOM';
export const FIELD_TOTAL_DISTANCE_WITH_DEAD_HEAD = 'totalDistanceWithDeadHead';
export const FIELD_TRANSPORTATION_MODE_DISPLAYED_VALUE = 'transportationModeDisplayedValue';
// carrier scorecards
export const FIELD_LATE_AFTER_MINUTES = 'lateAfterMinutes';
export const FIELD_UPLOAD_TIME_DOCUMENT_TYPE_GUID = 'uploadTimeDocumentTypeGuid';
export const FIELD_UPLOAD_TIME_INVOICE_STATUS_GUIDS = 'uploadTimeInvoiceStatusGuids';
// accessorial
export const FIELD_ACCESSORIAL_COPY = 'copy';
export const FIELD_ACCESSORIAL_SERVICE = 'service';
export const FIELD_ACCESSORIAL_DISTRICT = 'district';
export const FIELD_ACCESSORIAL_PARENT_GUID = 'parentGuid';
export const FIELD_ACCESSORIAL_FUEL_RELATED = 'fuelRelated';
export const FIELD_ACCESSORIAL_STORED_VALUE = 'storedValue';
export const FIELD_ACCESSORIAL_CLO_INVOICE_GL = 'cloInvoiceGl';
export const FIELD_ACCESSORIAL_TEL_INVOICE_GL = 'telInvoiceGl';
export const FIELD_ACCESSORIAL_USE_FUEL_INDEX = 'useFuelIndex';
export const FIELD_ACCESSORIAL_DISPLAYED_VALUE = 'displayedValue';
export const FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_RATE = 'rate';
export const FIELD_ACCESSORIAL_ACCESSORIAL_GUID = 'assessorialGuid';
export const FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_TO = 'valueTo';
export const FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES = 'fuelIndexVariables';
export const FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID = 'originalConfigGuid';
export const FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_FROM = 'valueFrom';
export const FIELD_ACCESSORIAL_SHARED_ACCESSORIAL_GUIDS = 'sharedAssesorialsGuids';
// charge
export const FIELD_CHARGE_RATE = 'rate';
export const FIELD_CHARGE_TOTAL = 'total';
export const FIELD_CHARGE_TYPE = FIELD_TYPE;
export const FIELD_CHARGE_MAX_RATE = 'maxRate';
export const FIELD_CHARGE_MIN_RATE = 'minRate';
export const FIELD_CHARGE_DISCOUNT = 'discount';
export const FIELD_CHARGE_COMMENTS = 'comments';
export const FIELD_CHARGE_RATE_UNIT = 'rateUnit';
export const FIELD_CHARGE_RATE_TYPE = 'rateType';
export const FIELD_CHARGE_CURRENCY = FIELD_CURRENCY;
export const FIELD_CHARGE_QUANTITY = FIELD_QUANTITY;
export const FIELD_CHARGE_NON_TAXABLE = 'nonTaxable';
export const FIELD_CHARGE_DEDUCTION = FIELD_DEDUCTION;
export const FIELD_ADVANCE_PAYMENT = 'advancePayment';
export const FIELD_CHARGE_SEQUENCE_NUM = 'sequenceNum';
export const FIELD_CHARGE_RATE_NAME = 'displayedValue';
export const FIELD_CHARGE_HOLD_QUANTITY = 'holdQuantity';
export const FIELD_CHARGE_RATE_NAME_STORED = 'storedValue';
export const FIELD_CHARGE_INTERNAL_EXPENSE = 'internalExpense';
export const FIELD_CHARGE_AUTO_ASSESSORIAL = 'autoAssessorial';
export const FIELD_DEDUCTION_FROM_VENDOR = 'deductionFromVendor';
// charges
export const FIELD_MAIN_CHARGES_TOTAL = 'mainChargesTotal';
export const FIELD_FUEL_CHARGES_TOTAL = 'fuelChargesTotal';
export const FIELD_DISCOUNT_CHARGES_TOTAL = 'discountChargesTotal';
export const FIELD_ADDITIONAL_CHARGES_TOTAL = 'additionalChargesTotal';
// invoice
export const FIELD_INVOICE_MODE = FIELD_MODE;
export const FIELD_INVOICE_STATUS = 'status';
export const FIELD_INVOICE_DATE = 'invoiceDate';
export const FIELD_GL_POSTED_DATE = 'glPostedDate';
export const FIELD_INVOICE_CHECK_DATE = 'checkDate';
export const FIELD_INVOICE_NUMBER = 'invoiceNumber';
export const FIELD_INVOICE_NET_DAYS = FIELD_NET_DAYS;
export const FIELD_CREATED_INVOICE = 'createdInvoice';
export const FIELD_INVOICE_DEPOSIT_DATE = 'depositDate';
export const FIELD_INVOICE_CHECK_NUMBER = 'checkNumber';
export const FIELD_INVOICE_APPROVED_TOTAL = 'approvedTotal';
export const FIELD_CERTIFICATION_DATE = 'certificationDate';
export const FIELD_MASTER_INVOICE_GUIDS = 'masterInvoiceGuids';
export const FIELD_INVOICE_PAYMENT_DUE_DATE = 'paymentDueDate';
export const FIELD_INVOICE_INVOICE_TEMPLATES = 'invoiceTemplates';
export const FIELD_FINANCIAL_INTEGRATION = 'financialIntegration';
export const FIELD_ADDITIONAL_REFERENCES = 'additionalReferences';
export const FIELD_INVOICE_MASTER_INVOICE_GUID = 'masterInvoiceGuid';
export const FIELD_INVOICE_DRIVER_PAYROLL_GUID = 'driverPayrollGuid';
export const FIELD_SEPARATE_INVOICE_BILL_TO = 'separateInvoiceBillTo';
export const FIELD_INVOICE_TOTAL_TRIP_WEIGHT = FIELD_TOTAL_TRIP_WEIGHT;
export const FIELD_INVOICE_DRIVER_PAYROLL_NUMBER = 'driverPayrollNumber';
export const FIELD_INVOICE_DOCUMENT_TYPE_GUID = 'invoiceDocumentTypeGuid';
export const FIELD_INVOICE_TOTAL_TRIP_DISTANCE = FIELD_TOTAL_TRIP_DISTANCE;
export const FIELD_INVOICE_TOTAL_TRIP_WEIGHT_UOM = FIELD_TOTAL_TRIP_WEIGHT_UOM;
export const FIELD_INVOICE_TOTAL_TRIP_DISTANCE_UOM = FIELD_TOTAL_TRIP_DISTANCE_UOM;
export const FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER = 'achPaymentConfirmationNumber';
// payroll
export const FIELD_PAYROLL_NAME = FIELD_NAME;
export const FIELD_PAYROLL_GUID = 'payrollGuid';
export const FIELD_PAYROLL_STATUS = FIELD_STATUS;
export const FIELD_PAYROLL_CHARGES = FIELD_CHARGES;
export const FIELD_PAYROLL_DATE_TO = FIELD_DATE_TO;
export const FIELD_PAYROLL_NUMBER = 'payrollNumber';
export const FIELD_PAYROLL_CHECK_DATE = 'checkDate';
export const FIELD_PAYROLL_NET_DAYS = FIELD_NET_DAYS;
export const FIELD_PAYROLL_CURRENCY = FIELD_CURRENCY;
export const FIELD_PAYROLL_COMMENTS = FIELD_COMMENTS;
export const FIELD_PAYROLL_DRIVER_GUID = 'driverGuid';
export const FIELD_PAYROLL_DATE_FROM = FIELD_DATE_FROM;
export const FIELD_PAYROLL_DUE_DATE = 'paymentDueDate';
export const FIELD_PAYROLL_DEPOSIT_DATE = 'depositDate';
export const FIELD_PAYROLL_CHECK_AMOUNT = 'checkAmount';
export const FIELD_PAYROLL_GRAND_TOTAL = FIELD_GRAND_TOTAL;
export const FIELD_VENDOR_PAYROLL_GUID = 'vendorPayrollGuid';
export const FIELD_PAYROLL_CHECK_NUMBER = FIELD_CHECK_NUMBER;
export const FIELD_PAYROLL_CREATED_DATE = FIELD_CREATED_DATE;
export const FIELD_DRIVER_PAYROLLS_TOTAL = 'driverPayrollsTotal';
export const FIELD_PAYROLL_INVOICES_TOTAL = FIELD_INVOICES_TOTAL;
export const FIELD_PAYROLL_INVOICES_COUNT = FIELD_INVOICES_COUNT;
export const FIELD_VENDOR_PAYROLL_NUMBER = 'vendorPayrollNumber';
export const FIELD_PAYROLL_TOLL_CHARGES_TOTAL = 'tollChargesTotal';
export const FIELD_PAYROLL_CHARGES_TOTAL = 'additionalChargesTotal';
export const FIELD_PAYROLL_DEDUCTION_SUBTOTAL = 'deductionSubtotal';
export const FIELD_FLEET_VENDOR_PAYROLL_GUID = 'fleetVendorPayrollGuid';
export const FIELD_PAYROLL_DRIVER_EXPENSES_TOTAL = 'driverExpensesTotal';
export const FIELD_PAYROLL_MAIN_CHARGES_TOTAL = FIELD_MAIN_CHARGES_TOTAL;
export const FIELD_PAYROLL_FUEL_CARD_CHARGES_TOTAL = 'fuelCardChargesTotal';
export const FIELD_PAYROLL_ADVANCED_PAYMENTS_TOTAL = 'advancedPaymentsTotal';
export const FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL = 'deductionChargesTotal';
export const FIELD_DEDUCTION_CHARGES_FROM_VENDOR_TOTAL = 'deductionChargesFromVendorTotal';
// master invoice
export const FIELD_MASTER_INVOICE_STATUS = FIELD_STATUS;
export const FIELD_MASTER_INVOICE_BILL_TO = FIELD_BILL_TO;
export const FIELD_MASTER_INVOICE_CHARGES = FIELD_CHARGES;
export const FIELD_MASTER_INVOICE_DATE_TO = FIELD_DATE_TO;
export const FIELD_MASTER_INVOICE_CHECK_DATE = 'checkDate';
export const FIELD_MASTER_INVOICE_GROUP_BY = FIELD_GROUP_BY;
export const FIELD_MASTER_INVOICE_NET_DAYS = FIELD_NET_DAYS;
export const FIELD_MASTER_INVOICE_CURRENCY = FIELD_CURRENCY;
export const FIELD_MASTER_INVOICE_DUE_DATE = FIELD_DUE_DATE;
export const FIELD_MASTER_INVOICE_DRIVER_GUID = 'driverGuid';
export const FIELD_MASTER_INVOICE_DATE = 'masterInvoiceDate';
export const FIELD_MASTER_INVOICE_DATE_FROM = FIELD_DATE_FROM;
export const FIELD_MASTER_INVOICE_DEPOSIT_DATE = 'depositDate';
export const FIELD_MASTER_INVOICE_CHECK_AMOUNT = 'checkAmount';
export const FIELD_MASTER_INVOICE_NUMBER = 'masterInvoiceNumber';
export const FIELD_MASTER_INVOICE_GRAND_TOTAL = FIELD_GRAND_TOTAL;
export const FIELD_MASTER_INVOICE_CHECK_NUMBER = FIELD_CHECK_NUMBER;
export const FIELD_MASTER_INVOICE_CREATED_DATE = FIELD_CREATED_DATE;
export const FIELD_MASTER_INVOICE_CHARGES_TOTAL = FIELD_CHARGES_TOTAL;
export const FIELD_MASTER_INVOICE_INVOICES_COUNT = FIELD_INVOICES_COUNT;
export const FIELD_MASTER_INVOICE_INVOICES_TOTAL = FIELD_INVOICES_TOTAL;
export const FIELD_MASTER_INVOICE_TOTAL_DISCOUNT = FIELD_TOTAL_DISCOUNT;
export const FIELD_MASTER_INVOICE_INTEGRATED_DATE = FIELD_INTEGRATED_DATE;
// load/tel/clo
export const FIELD_LOAD_FCFS = 'fcfs';
export const FIELD_CLO_COST = 'cloCost';
export const FIELD_TEL_COST = 'telCost';
export const FIELD_LOAD_ITEMS = 'items';
export const FIELD_LOAD_STOPS = 'events';
export const FIELD_LOAD_STATUS = 'status';
export const FIELD_LOAD_TYPE = 'loadType';
export const FIELD_TEL_GUIDS = 'telGuids';
export const FIELD_FAST_LOAD = 'fastLoad';
export const FIELD_LOAD_VERSION = 'version';
export const FIELD_DECLINE_BY = 'declineBy';
export const FIELD_LOAD_TEL_GUID = 'telGuid';
export const FIELD_LOAD_CLO_GUID = 'cloGuid';
export const FIELD_LOAD_SERVICES = 'services';
export const FIELD_LOAD_EARNINGS = 'earnings';
export const FIELD_LOAD_LOAD_INFO = 'loadInfo';
export const FIELD_LOAD_EQUIPMENT = 'equipment';
export const FIELD_QUOTE_NUMBER = 'quoteNumber';
export const FIELD_LOAD_ORDER_TYPE = 'orderType';
export const FIELD_LOAD_REFERENCES = 'references';
export const FIELD_LOAD_BRANCH_GUID = BRANCH_GUID;
export const FIELD_TERMINAL_DROPS = 'terminalDrops';
export const FIELD_LOAD_ROUTE_NUMBER = 'routeNumber';
export const FIELD_ORIGIN_LOCATION = 'originLocation';
export const FIELD_LOAD_DIVISION_GUID = 'divisionGuid';
export const FIELD_LOAD_CARRIER_FLEET = 'carrierFleet';
export const FIELD_CLO_TOTAL_VOLUME = 'cloTotalVolume';
export const FIELD_TERMINAL_PICKUPS = 'terminalPickups';
export const FIELD_LOAD_EVENT_LATE_DATE = 'eventLateDate';
export const FIELD_LOAD_EVENT_LATE_TIME = 'eventLateTime';
export const FIELD_LOAD_CLO_EVENT_INDEX = 'cloEventIndex';
export const FIELD_LOAD_TEL_EVENT_INDEX = 'telEventIndex';
export const FIELD_LOAD_NUMBER_OF_LOADS = 'numberOfLoads';
export const FIELD_LOAD_CANCEL_REASON = 'loadCancelReason';
export const FIELD_TEL_TOTAL_DISTANCE = 'telTotalDistance';
export const FIELD_LOAD_EVENT_EARLY_DATE = 'eventEarlyDate';
export const FIELD_LOAD_EVENT_EARLY_TIME = 'eventEarlyTime';
export const FIELD_LOAD_APPOINTMENT_DATE = 'appointmentDate';
export const FIELD_LOAD_TEL_CREATION_MODE = 'telCreationMode';
export const FIELD_QUOTE_REQUEST_NUMBER = 'quoteRequestNumber';
export const FIELD_DESTINATION_LOCATION = 'destinationLocation';
export const FIELD_LOAD_APPOINTMENT_NUMBER = 'appointmentNumber';
export const FIELD_STATUS_REASON_CODE_GUID = 'statusReasonCodeGuid';
export const FIELD_LOAD_CANCEL_REASON_GUID = 'loadCancelReasonGuid';
export const FIELD_LOAD_APPOINTMENT_REQUIRED = 'appointmentRequired';
export const FIELD_LOAD_SPECIAL_INSTRUCTIONS = 'specialInstructions';
export const FIELD_LOAD_APPOINTMENT_LATE_TIME = 'appointmentLateTime';
export const FIELD_DECLINE_REASON_CODE_GUID = 'declineReasonCodeGuid';
export const FIELD_LOAD_INTERNAL_INSTRUCTIONS = 'internalInstructions';
export const FIELD_LOAD_APPOINTMENT_EARLY_TIME = 'appointmentEarlyTime';
export const FIELD_ESTIMATED_TIME_OF_ARRIVAL = 'estimatedTimeOfArrival';
// assignment
export const FIELD_TRUCK = 'truck';
export const FIELD_TRAILER = 'trailer';
export const FIELD_TRAILERS = 'trailers';
export const FIELD_TEAM_DRIVER = 'teamDriver';
export const FIELD_PRIMARY_DRIVER = 'primaryDriver';
export const FIELD_SECONDARY_DRIVER = 'secondaryDriver';
export const FIELD_CARRIER_TERMINAL_GUID = 'terminalGuid';
export const FIELD_ROUTE_CARRIER_SNAPSHOT = 'carrierSnapshot';
export const FIELD_PRIMARY_DRIVER_PHONE = 'primaryDriverPhone';
export const FIELD_PREFIX_FLEET_ASSIGNMENT = 'fleetAssignment';
export const FIELD_FLEET_TRAILER_UNIT_ID = 'fleetTrailerUnitId';
export const FIELD_SECONDARY_DRIVER_PHONE = 'secondaryDriverPhone';
export const FIELD_PREFIX_CARRIER_ASSIGNMENT = 'carrierAssignment';
// carrier
export const FIELD_CARRIER_URL = 'url';
export const FIELD_CARRIER_DBA = FIELD_DBA;
export const FIELD_CARRIER_NAME = FIELD_NAME;
export const FIELD_CARRIER_SCAC = FIELD_SCAC;
export const FIELD_CARRIER_SYNC = FIELD_SYNC;
export const FIELD_CARRIER_ACTIVE = 'active';
export const FIELD_CARRIER_GUID = 'carrierGuid';
export const FIELD_CARRIER_EMAIL = 'carrierEmail';
export const FIELD_CARRIER_OWN_COMPANY = 'ownCompany';
export const FIELD_CARRIER_INTERNAL_ID = 'internalId';
export const FIELD_CARRIER_MC_NUMBER = FIELD_MC_NUMBER;
export const FIELD_CARRIER_SEND_PACKAGE = 'sendPackage';
export const FIELD_CARRIER_COMPANY_NAME = 'companyName';
export const FIELD_CARRIER_EQUIPMENTS = FIELD_EQUIPMENTS;
export const FIELD_CARRIER_DUNS_NUMBER = FIELD_DUNS_NUMBER;
export const FIELD_CARRIER_FEDERAL_EIN = FIELD_FEDERAL_EIN;
export const FIELD_CARRIER_INTRASTATE_DOT = 'intrastateDOT';
export const FIELD_CARRIER_USDOT_NUMBER = FIELD_US_DOT_NUMBER;
export const FIELD_CARRIER_SERVICE_TYPES = FIELD_SERVICE_TYPES;
export const FIELD_CARRIER_DEACTIVATED_REASON = 'statusReason';
export const FIELD_CARRIER_ADDED_TO_NETWORK = 'addedToNetwork';
export const FIELD_CARRIER_INTRASTATE_STATE = 'intrastateState';
export const FIELD_ACCOUNTING_EXTERNAL_ID = 'accountingExternalId';
export const FIELD_CARRIER_ALLOWED_BOOK_IT_NOW = 'allowedBookItNow';
export const FIELD_CARRIER_ADDED_TO_WATCH_LIST = 'addedToWatchList';
export const FIELD_CARRIER_TRANSPORTATION_MODES = 'transportationModes';
export const FIELD_IGNORE_DEACTIVATION_RULES = 'ignoreDeactivationRules';
// carrier safety saferwatch
export const CARRIER_SAFETY_SAFETY = 'safety';
export const CARRIER_SAFETY_AUTHORITY = 'authority';
export const CARRIER_SAFETY_INSURANCE = 'insurance';
export const CARRIER_SAFETY_OPERATIONS = 'operations';
export const CARRIER_SAFETY_DRUG_ALCOHOL = 'drugAlcohol';
export const CARRIER_SAFETY_SAFETY_RATING = 'safetyRating';
export const CARRIER_SAFETY_VEHICLE_MAINT = 'vehicleMaint';
export const CARRIER_SAFETY_UNSAFE_DRIVING = 'unsafeDriving';
export const CARRIER_SAFETY_DRIVER_FITNESS = 'driverFitness';
export const CARRIER_SAFETY_NUMBER_OF_TRUCKS = 'numberOfTrucks';
export const CARRIER_SAFETY_COMMON_AUTHORITY = 'commonAuthority';
export const CARRIER_SAFETY_BROKER_AUTHORITY = 'brokerAuthority';
export const CARRIER_SAFETY_INTEGRATION_TYPE = 'integrationType';
export const CARRIER_SAFETY_OVERALL_RISK_INDICATOR = 'overallRiskIndicator';
// carrier safety rmis
export const CARRIER_SAFETY_US_IEP_OOS_PERCENTAGE = 'usIepOosPercentage';
export const CARRIER_SAFETY_US_DRIVER_OOS_PERCENTAGE = 'usDriverOosPercentage';
export const CARRIER_SAFETY_US_HAZMAT_OOS_PERCENTAGE = 'usHazmatOosPercentage';
export const CARRIER_SAFETY_US_VEHICLE_OOS_PERCENTAGE = 'usVehicleOosPercentage';
export const CARRIER_SAFETY_CAN_DRIVER_OOS_PERCENTAGE = 'canDriverOosPercentage';
export const CARRIER_SAFETY_US_IEP_NATL_EVG_PERCENTAGE = 'usIepNatlEvgPercentage';
export const CARRIER_SAFETY_CAN_VEHICLE_OOS_PERCENTAGE = 'canVehicleOosPercentage';
export const CARRIER_SAFETY_US_DRIVER_NATL_EVG_PERCENTAGE = 'usDriverNatlEvgPercentage';
export const CARRIER_SAFETY_US_HAZMAT_NATL_EVG_PERCENTAGE = 'usHazmatNatlEvgPercentage';
export const CARRIER_SAFETY_US_VEHICLE_NATL_EVG_PERCENTAGE = 'usVehicleNatlEvgPercentage';
// accept
export const FIELD_CARRIER_ACCEPT_DRIVER_NAME = 'driverName';
export const FIELD_CARRIER_ACCEPT_TRUCK_NUMBER = 'truckNumber';
export const FIELD_CARRIER_ACCEPT_TRAILER_NUMBER = 'trailerNumber';
export const FIELD_CARRIER_ACCEPT_TRACKING_NUMBER = 'trackingNumber';
export const FIELD_CARRIER_ACCEPT_DRIVER_PHONE_NUMBER = 'driverPhoneNumber';
// associated company
export const FIELD_CARRIER_ASSOC_COMPANY_URL = 'url';
export const FIELD_CARRIER_ASSOC_COMPANY_NAME = 'name';
export const FIELD_CARRIER_ASSOC_COMPANY_SCAC = FIELD_SCAC;
export const FIELD_CARRIER_ASSOC_COMPANY_INTERNAL_ID = 'internalId';
export const FIELD_CARRIER_ASSOC_COMPANY_DUNS_NUMBER = FIELD_DUNS_NUMBER;
export const FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_DOT = 'intrastateDOT';
export const FIELD_CARRIER_ASSOC_COMPANY_USDOT_NUMBER = FIELD_US_DOT_NUMBER;
export const FIELD_CARRIER_ASSOC_COMPANY_INTRASTATE_STATE = 'intrastateState';
// insurance
export const FIELD_PAYMENT_AMOUNT = 'paymentAmount';
export const FIELD_INSURANCE_TYPE = 'insuranceType';
export const FIELD_PAYMENT_INTERVAL = 'paymentInterval';
export const FIELD_CARRIER_INSURANCE_TYPE = FIELD_INSURANCE_TYPE;
export const FIELD_CARRIER_INSURANCE_DESCRIPTION = 'description';
export const FIELD_INSURANCE_CLAIM_NUMBER = 'insuranceClaimNumber';
export const FIELD_CARRIER_INSURANCE_POLICY_NUMBER = 'policyNumber';
export const FIELD_CARRIER_INSURANCE_INSURANCE_NAME = 'insurerName';
export const FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE = 'effectiveDate';
export const FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT = 'coverageAmount';
export const FIELD_CARRIER_INSURANCE_EXPIRATION_DATE = FIELD_EXPIRATION_DATE;
// accident
export const FIELD_ACCIDENT_TYPE = 'accidentType';
export const FIELD_ACCIDENT_DATE = 'accidentDate';
// carrier deactivation rule
export const FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER = 'trigger'; // TODO: check and change
export const FIELD_CARRIER_DEACTIVATION_RULE_PERCENT = 'percent';
export const FIELD_CARRIER_DEACTIVATION_RULE_AVERAGE_HOURS = 'averageHours';
export const FIELD_CARRIER_DEACTIVATION_RULE_PERIOD_IN_DAYS = 'periodInDays';
export const FIELD_CARRIER_DEACTIVATION_RULE_DEACTIVATION_DAYS = 'deactivationDays';
export const FIELD_CARRIER_DEACTIVATION_RULE_MINIMUM_LOAD_COUNT = 'minimumLoadCount';
export const FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS = 'insuranceTypeGuids';
// synchronization info
export const FIELD_CARRIER_SYNCHRONIZATION_INFO_STATUS = 'status';
export const FIELD_CARRIER_SYNCHRONIZATION_INFO_USER_LOGIN = 'userLogin';
export const FIELD_CARRIER_SYNCHRONIZATION_INFO_ERROR_MESSAGE = 'errorMessage';
export const FIELD_CARRIER_SYNCHRONIZATION_INFO_INTEGRATION_TYPE = 'integrationType';
export const FIELD_CARRIER_SYNCHRONIZATION_INFO_SYNCHRONIZATION_DATE = 'synchronizationDate';
// onboarding package
export const FIELD_CARRIER_ONBOARDING_PACKAGE_NAME = FIELD_NAME;
export const FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO = 'copyTo';
export const FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT = 'subject';
export const FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO = 'replyTo';
export const FIELD_CARRIER_ONBOARDING_PACKAGE_MESSAGE = FIELD_MESSAGE;
// onboarding package document
export const FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL = 'url';
export const FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_FILE = 'file';
export const FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DESCRIPTION = 'description';
export const FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_NAME = 'documentName';
export const FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_FILE_NAME = 'documentFilename';
// document
export const FIELD_CARRIER_DOCUMENT_URL = 'url';
export const FIELD_CARRIER_DOCUMENT_TYPE = 'documentType';
export const FIELD_CARRIER_DOCUMENT_FILE = 'documentFilename';
export const FIELD_CARRIER_DOCUMENT_SIGNED_DATE = 'signedDate';
export const FIELD_CARRIER_DOCUMENT_DESCRIPTION = 'description';
export const FIELD_CARRIER_DOCUMENT_EXPIRATION_DATE = FIELD_EXPIRATION_DATE;
export const FIELD_AVAILABLE_CARRIER_DOCUMENT_TYPE = 'availableDocumentTypes';
// certificate
export const FIELD_CARRIER_CERTIFICATE_PERMIT = 'hm232Permit';
export const FIELD_CARRIER_CERTIFICATE_CERTIFIED = 'certified';
export const FIELD_CARRIER_CERTIFICATE_CLASS = 'hazMatClasses';
export const FIELD_CARRIER_CERTIFICATE_SAFETY_PLAN = 'safetyPlan';
export const FIELD_CARRIER_CERTIFICATE_DRIVER_COUNT = 'driverCount';
export const FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE = 'effectiveDate';
export const FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE = FIELD_EXPIRATION_DATE;
// terminal
export const FIELD_TERMINAL_NAME = 'terminalName';
export const FIELD_CARRIER_TERMINAL_NAME = 'name';
export const FIELD_CARRIER_TERMINAL_ACTIVE = 'active';
export const FIELD_CARRIER_TERMINAL_PRIMARY = 'primary';
export const FIELD_CARRIER_TERMINAL_QUOTE_PROCESS = 'quoteProcess';
export const FIELD_CARRIER_TERMINAL_DISPATCH_PROCESS = 'dispatchProcess';
export const FIELD_CARRIER_TERMINAL_STATUS_CHECK_PROCESS = 'statusCheckProcess';
// terminal contact
export const FIELD_CARRIER_TERMINAL_CONTACT_PRIMARY = 'primary';
export const FIELD_CARRIER_TERMINAL_CONTACT_SECONDARY_PHONE = 'secondaryPhone';
// finance
export const FIELD_FINANCE_RELATION = 'relation';
export const FIELD_FINANCE_SELECTED_RATE = 'selectedRate';
export const FIELD_FINANCE_TOTAL_CURRENCY = 'totalCurrency';
export const FIELD_FINANCE_COST_ALLOCATIONS = 'costAllocations';
export const FIELD_CARRIER_FINANCE_QUICK_PAY_VALUE = 'quickPayValue';
export const FIELD_CARRIER_FINANCE_LOCATION_PAY_TYPE = FIELD_PAY_TYPE;
export const FIELD_CARRIER_FINANCE_LOCATION_SELECTED = FIELD_SELECTED;
export const FIELD_CARRIER_FINANCE_LOCATION_START_DATE = FIELD_START_DATE;
// terminal process
export const FIELD_TERMINAL_PROCESS_TYPE = FIELD_TYPE;
export const FIELD_TERMINAL_PROCESS_ENABLED = 'enabled';
export const FIELD_TERMINAL_PROCESS_AUTO_ACCEPT = 'autoAccept';
export const FIELD_DISPATCH_PROCESS_TYPE = 'dispatchProcessType';
export const FIELD_TERMINAL_PROCESS_PERMIT_REVOKE = 'permitRevoke';
export const FIELD_CARRIER_EMAIL_GROUP_GUIDS = 'carrierEmailGroupGuids';
export const FIELD_TERMINAL_STATUS_CHECK_FREQUENCY = 'statusCheckFrequency';
export const FIELD_TERMINAL_SMS_NOTIFICATION_ENABLED = 'smsNotificationEnabled';
export const FIELD_TERMINAL_INTEGRATION_CONFIG_GUID = FIELD_INTEGRATION_CONFIG_GUID;
export const FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_SEQUENCE = 'trackingNumberSequence';
export const FIELD_TERMINAL_PROCESS_TRACKING_NUMBER_REF_TYPE_GUID = 'trackingNumberReferenceTypeGuid';
// fleet driver
export const FIELD_DRIVER = 'driver';
export const FIELD_DRIVER_GUID = 'driverGuid';
export const FIELD_CHARGE_NAME = 'chargeName';
export const FIELD_DRIVER_GUIDS = 'driverGuids';
export const FIELD_DRIVER_EMPLOYED = 'employed';
export const FIELD_WORKING_DAYS = 'workingDays';
export const FIELD_DRIVER_PAID_BY_TRIP = 'paidByTrip';
export const FIELD_INCLUDE_FUEL_CHARGE = 'includeFuelCharge';
export const FIELD_INCLUDE_TOLL_CHARGE = 'includeTollCharge';
export const FIELD_SHOW_VENDOR_PAYROLL = 'showVendorPayroll';
export const FIELD_PAYROLL_VARIABLE_VALUES = 'variableValues';
export const FIELD_PREFER_DISTANCE_RATE = 'preferDistanceRate';
export const FIELD_INCLUDE_ADVANCE_PAYMENT = 'includeAdvancePayment';
export const FIELD_PREFER_DISTANCE_RATE_UOM = 'preferDistanceRateUom';
// fleet truck
export const FIELD_SERIES = 'series';
export const FIELD_IFTA_DATE = 'iftaDate';
export const FIELD_INSTALLED = 'installed';
export const FIELD_TRUCK_TYPE = 'truckType';
export const FIELD_TRUCK_GUIDS = 'truckGuids';
export const FIELD_TITLE_STATE = 'titleState';
export const FIELD_TRUCK_TYPES = 'truckTypes';
export const FIELD_TITLE_NUMBER = 'titleNumber';
export const FIELD_COMPONENT_ID = 'componentId';
export const FIELD_INSTALL_DATE = 'installDate';
export const FIELD_IFTA_REQUIRED = 'iftaRequired';
export const FIELD_SERIAL_NUMBER = 'serialNumber';
export const FIELD_LEGACY_UNIT_ID = 'legacyUnitId';
export const FIELD_SPECIFICATION = 'specification';
export const FIELD_COMPONENT_TYPE = 'componentType';
export const FIELD_INSTALLED_DATE = 'installedDate';
export const FIELD_REGISTRATION_STATE = 'registrationState';
export const FIELD_WARRANTY_EXPIRATION = 'warrantyExpiration';
export const FIELD_OUT_OF_SERVICE_REASON = 'outOfServiceReason';
export const FIELD_SHARED_COMPONENT_GUID = 'sharedComponentGuid';
export const FIELD_UNAVAILABILITY_REASON = 'unavailabilityReason';
export const FIELD_REPLACE_OR_REMOVE_DATE = 'replaceOrRemoveDate';
export const FIELD_COMPONENT_MANUFACTURER = 'componentManufacturer';
export const FIELD_TRUCK_ADDITIONAL_EQUIPMENT = 'truckAdditionalEquipment';
export const FIELD_REGISTRATION_EXPIRATION_DATE = 'registrationExpirationDate';
// specification
export const FIELD_CUBE = 'cube';
export const FIELD_MPG_LOW = 'mpgLow';
export const FIELD_SLEEPER = 'sleeper';
export const FIELD_MPG_HIGH = 'mpgHigh';
export const FIELD_CUBE_TYPE = 'cubeType';
export const FIELD_DOOR_TYPE = 'doorType';
export const FIELD_FUEL_TYPE = 'fuelType';
export const FIELD_AXLE_COUNT = 'axleCount';
export const FIELD_DOOR_WIDTH = 'doorWidth';
export const FIELD_DECK_HEIGHT = 'deckHeight';
export const FIELD_DOOR_HEIGHT = 'doorHeight';
export const FIELD_AXLE_CONFIG = 'axleConfig';
export const FIELD_TARE_WEIGHT = 'tareWeight';
export const FIELD_BODY_MATERIAL = 'bodyMaterial';
export const FIELD_DOOR_WIDTH_UOM = 'doorWidthUom';
export const FIELD_DOOR_DIMENSION = 'doorDimension';
export const FIELD_WELL_DIMENSION = 'wellDimension';
export const FIELD_BULK_COMP_COUNT = 'bulkCompCount';
export const FIELD_DOOR_HEIGHT_UOM = 'doorHeightUom';
export const FIELD_DOOR_DIMENSIONS = 'doorDimensions';
export const FIELD_DECK_HEIGHT_TYPE = 'deckHeightType';
export const FIELD_BULK_COMP_VOLUME = 'bulkCompVolume';
export const FIELD_WEIGHT_TYPE_GVWT = 'weightTypeGVWT';
export const FIELD_TARE_WEIGHT_TYPE = 'tareWeightType';
export const FIELD_WEIGHT_TARE_TYPE = 'weightTareType';
export const FIELD_FIFTH_WHEEL_WIDTH = 'fifthWheelWidth';
export const FIELD_FIFTH_WHEEL_HEIGHT = 'fifthWheelHeight';
export const FIELD_INTERNAL_DIMENSION = 'internalDimension';
export const FIELD_EXTERNAL_DIMENSION = 'externalDimension';
export const FIELD_TEMPERATURE_CONTROL = 'temperatureControl';
export const FIELD_SPECIFICATION_CLASS = 'specificationClass';
export const FIELD_BULK_COMP_VOLUME_TYPE = 'bulkCompVolumeType';
export const FIELD_FIFTH_WHEEL_WIDTH_TYPE = 'fifthWheelWidthType';
export const FIELD_FIFTH_WHEEL_HEIGHT_TYPE = 'fifthWheelHeightType';
// refrigeration
export const FIELD_HOURS = 'hours';
export const FIELD_CARB_ID = 'carbId';
export const FIELD_HOURS_DATE = 'hoursDate';
export const FIELD_TRUATCM_ID = 'truatcmId';
export const FIELD_OUTPUT_YEAR = 'outputYear';
export const FIELD_COOLANT_TYPE = 'coolantType';
export const FIELD_TEMP_RANGE_TO = 'tempRangeTo';
export const FIELD_REFRIGERATION = 'refrigeration';
export const FIELD_TEMP_RANGE_TYPE = 'tempRangeType';
export const FIELD_TEMP_RANGE_FROM = 'tempRangeFrom';
export const FIELD_REFRIGERATION_MANUFACTURER = 'refrigerationManufacturer';
// ownership
export const FIELD_RETURNED_DATE = 'returnedDate';
export const FIELD_OWNERSHIP_TYPE = 'ownershipType';
export const FIELD_PAYMENT_METHOD = 'paymentMethod';
export const FIELD_CONTRACT_NUMBER = 'contractNumber';
export const FIELD_REGISTRATION_COST = 'registrationCost';
export const FIELD_EQUIPMENT_RETURN_COST = 'equipmentReturnCost';
export const FIELD_MAINTENANCE_COST_METHOD = 'maintenanceCostMethod';
export const FIELD_MAINTENANCE_COST_INTERVAL = 'maintenanceCostInterval';
export const FIELD_EQUIPMENT_RETURN_COST_PAID = 'equipmentReturnCostPaid';
export const FIELD_EQUIPMENT_RETURN_COST_RATIO = 'equipmentReturnCostRatio';
export const FIELD_EQUIPMENT_RETURN_COST_ACCRUED = 'equipmentReturnCostAccrued';
export const FIELD_REGISTRATION_PAYMENT_INTERVAL = 'registrationPaymentInterval';
export const FIELD_EQUIPMENT_RETURN_BALANCE_REQUIRED = 'equipmentReturnBalanceRequired';
export const FIELD_EQUIPMENT_RETURN_COST_NOT_PROVISIONED = 'equipmentReturnCostNotProvisioned';
// fleet trailer
export const FIELD_TRAILER_GUID = 'trailerGuid';
export const FIELD_TRAILER_TYPE = 'trailerType';
export const FIELD_MODEL_SERIES = 'modelSeries';
export const FIELD_TRAILER_MODEL = 'trailerModel';
export const FIELD_REGISTRATION_EXPIRED_DATE = 'registrationExpiredDate';
// fleet vendor
export const FIELD_VENDOR_GUID = 'vendorGuid';
export const FIELD_VENDOR_GST_NUMBER = 'gstNumber';
export const FIELD_FLEET_VENDOR_GUID = 'fleetVendorGuid';
// driver compensation
export const FIELD_DRIVER_COMPENSATION_ZIP = 'zip';
export const FIELD_DRIVER_COMPENSATION_CITY = 'city';
export const FIELD_DRIVER_COMPENSATION_NAME = 'name';
export const FIELD_DRIVER_COMPENSATION_GUID = 'guid';
export const FIELD_DRIVER_COMPENSATION_STATES = 'states';
export const FIELD_DRIVER_COMPENSATION_CITIES = 'cities';
export const FIELD_DRIVER_COMPENSATION_VERSION = 'version';
export const FIELD_DRIVER_COMPENSATION_ZIP_CODES = 'zipCodes';
export const FIELD_DRIVER_COMPENSATION_COUNTRIES = 'countries';
export const FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO = 'zipRangeTo';
export const FIELD_DRIVER_COMPENSATION_DISTANCE_OUM = 'distanceUom';
export const FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM = 'zipRangeFrom';
export const FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE = 'effectiveDate';
export const FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO = 'distanceRangeTo';
export const FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE = FIELD_EXPIRATION_DATE;
export const FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM = 'distanceRangeFrom';
// driver accessorial
export const FIELD_DRIVER_ACCESSORIAL_RATE_TYPE = 'rateType';
export const FIELD_DRIVER_ACCESSORIAL_RATE_TYPE_FLAT = 'FLAT';
export const FIELD_DRIVER_ASSESSORIAL_GUID = 'assessorialGuid';
// driver daily hours
export const FIELD_LOG_DATE = 'logDate';
export const FIELD_LOG_DATE_TIME = 'logDateTime';
export const FIELD_ON_DUTY_HOURS = 'onDutyHours';
export const FIELD_DRIVING_HOURS = 'drivingHours';
export const FIELD_ON_DUTY_MINUTES = 'onDutyMinutes';
export const FIELD_DRIVING_MINUTES = 'drivingMinutes';
export const FIELD_DURATION_MINUTES = 'durationMinutes';
export const FIELD_END_LOG_DATE_TIME = 'endLogDateTime';
// driver certifications
export const FIELD_CERTIFICATION_NOTES = 'notes';
export const FIELD_CERTIFICATION_OOS_ITEM = 'oosItem';
export const FIELD_CERTIFICATION_TYPE = 'certificationType';
export const FIELD_CERTIFICATION_NAME = 'certificationName';
// carrier rate
export const FIELD_CARRIER_RATE_ZIP = 'zip';
export const FIELD_CARRIER_RATE_CITY = 'city';
export const FIELD_CARRIER_RATE_GUID = 'guid';
export const FIELD_CARRIER_RATE_NAME = 'name';
export const FIELD_CARRIER_RATE_STATES = 'states';
export const FIELD_CARRIER_RATE_CITIES = 'cities';
export const FIELD_CARRIER_RATE_VERSION = 'version';
export const FIELD_CARRIER_RATE_ZIP_CODES = 'zipCodes';
export const FIELD_CARRIER_RATE_COUNTRIES = 'countries';
export const FIELD_CARRIER_RATE_ZIP_RANGE_TO = 'zipRangeTo';
export const FIELD_CARRIER_RATE_DISTANCE_OUM = 'distanceUom';
export const FIELD_MIN_MARGIN_VIOLATED = 'minMarginViolated';
export const FIELD_CARRIER_RATE_CHARGES = 'carrierRateCharges';
export const FIELD_CARRIER_RATE_ZIP_RANGE_FROM = 'zipRangeFrom';
export const FIELD_CARRIER_RATE_EFFECTIVE_DATE = 'effectiveDate';
export const FIELD_MARGIN_VIOLATION_NOTE = 'marginViolationNote';
export const FIELD_CARRIER_RATE_ORIGIN_ZONE_NAME = 'originZoneName';
export const FIELD_CARRIER_RATE_ORIGIN_ZONE_GUID = 'originZoneGuid';
export const FIELD_MARGIN_VIOLATION_REASON = 'marginViolationReason';
export const FIELD_CARRIER_RATE_DISTANCE_RANGE_TO = 'distanceRangeTo';
export const FIELD_CRITICAL_MARGIN_VIOLATED = 'criticalMarginViolated';
export const FIELD_CARRIER_RATE_EXPIRATION_DATE = FIELD_EXPIRATION_DATE;
export const FIELD_MARGIN_VIOLATION_APPROVED = 'marginViolationApproved';
export const FIELD_CARRIER_RATE_DISTANCE_RANGE_FROM = 'distanceRangeFrom';
export const FIELD_CARRIER_RATE_GEO_FENCING_ZONE_GUID = 'geofencingZoneGuid';
export const FIELD_CARRIER_RATE_DESTINATION_ZONE_GUID = 'destinationZoneGuid';
export const FIELD_CARRIER_RATE_DESTINATION_ZONE_NAME = 'destinationZoneName';
// carrier contract
export const FIELD_CONTRACT_GUID = 'contractGuid';
export const FIELD_VARIABLE_TYPE = 'variableType';
export const FIELD_VARIABLE_UNIT = 'variableUnit';
export const FIELD_CARRIER_CONTRACT_VENDOR = 'vendor';
export const FIELD_CARRIER_CONTRACT_MAX_VOLUME = 'maxVolume';
export const FIELD_CARRIER_CONTRACT_VOLUME_UOM = 'volumeUom';
export const FIELD_CARRIER_CONTRACT_NAME = FIELD_CONTRACT_NAME;
export const FIELD_CARRIER_CONTRACT_MIN_DISTANCE = 'minDistance';
export const FIELD_CARRIER_CONTRACT_MAX_DISTANCE = 'maxDistance';
export const FIELD_CARRIER_CONTRACT_GUID = 'carrierContractGuid';
export const FIELD_CARRIER_CONTRACT_ROLE_GUIDS = FIELD_ROLE_GUIDS;
export const FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE = 'effectiveDate';
export const FIELD_CARRIER_CONTRACT_EXPIRATION_DATE = FIELD_EXPIRATION_DATE;
// customer contract
export const FIELD_UPLIFT = 'uplift';
export const FIELD_MIN_UPLIFT = 'minUplift';
export const FIELD_MAX_UPLIFT = 'maxUplift';
export const FIELD_CUSTOMER_CONTRACT_SCOPE = 'scope';
export const FIELD_CUSTOMER_CONTRACT_NAME = 'customerContractName';
export const FIELD_CUSTOMER_CONTRACT_GUID = 'customerContractGuid';
export const FIELD_CUSTOMER_CONTRACT_CARRIER_GUIDS = FIELD_CARRIER_GUIDS;
export const FIELD_CUSTOMER_CONTRACT_CARRIER_CONTRACT_GUIDS = 'carrierContractGuids';
export const FIELD_CUSTOMER_CONTRACT_ALLOW_INTERLINE_CARRIERS = 'allowInterlineCarriers';
export const FIELD_CUSTOMER_USE_CARRIER_PRICE_FOR_TRIP_RATE = 'useCarrierPriceForTripRate';
export const FIELD_CUSTOMER_CONTRACT_CARRIER_ASSESSORIAL_EXCLUSION = 'assessorialExclusion';
export const FIELD_CUSTOMER_CONTRACT_CARRIER_EXCLUDED_ASSESSORIALS = 'excludedAssessorials';
export const FIELD_CUSTOMER_CONTRACT_CARRIER_GRANTED_ENTERPRISE_GUIDS = 'grantedEnterpriseGuids';
export const FIELD_CUSTOMER_CONTRACT_CARRIER_USE_AWARDED_LINES = 'useCarrierContractAwardedLines';
// sequence
export const FIELD_SEQUENCE_TYPE = FIELD_TYPE;
export const FIELD_SEQUENCE_STEP = 'step';
export const FIELD_SEQUENCE_USED = 'used';
export const FIELD_SEQUENCE_PREFIX = 'prefix';
export const FIELD_SEQUENCE_SUFFIX = 'suffix';
export const FIELD_SEQUENCE_END_NUMBER = 'endNum';
export const FIELD_SEQUENCE_START_NUMBER = 'startNum';
export const FIELD_SEQUENCE_SYSTEM_NAME = 'systemName';
export const FIELD_SEQUENCE_TRANSFORMER = 'transformer';
export const FIELD_SEQUENCE_APPROX_VALUE = 'approxValue';
export const FIELD_SEQUENCE_DISPLAY_NAME = 'displayName';
// load board
export const FIELD_LOAD_BOARD_TRUCKS = 'trucks';
export const FIELD_LOAD_BOARD_WEIGHT = 'weight';
export const FIELD_LOAD_BOARD_LENGTH = 'length';
export const FIELD_LOAD_BOARD_TRUCK = 'truckType';
export const FIELD_LOAD_BOARD_COMMENT = 'comment';
export const FIELD_LOAD_BOARD_CAPACITY = 'capacity';
export const FIELD_LOAD_BOARD_ORIGIN = FIELD_ORIGIN;
export const FIELD_LOAD_BOARD_LENGTH_UOM = 'lengthUom';
export const FIELD_LOAD_BOARD_WEIGHT_UOM = 'weightUom';
export const FIELD_LOAD_BOARD_DIMENSIONS = 'dimensions';
export const FIELD_LOAD_BOARD_DATE_LATEST = 'latestDate';
export const FIELD_LOAD_BOARD_ORIGIN_CITY = 'originCity';
export const FIELD_LOAD_BOARD_REFERENCE = FIELD_REFERENCE;
export const FIELD_LOAD_BOARD_ORIGIN_STATE = 'originState';
export const FIELD_LOAD_BOARD_ORIGIN_RADIUS = 'originRadius';
export const FIELD_LOAD_BOARD_DATE_EARLIEST = 'earliestDate';
export const FIELD_LOAD_BOARD_DESTINATION = FIELD_DESTINATION;
export const FIELD_LOAD_BOARD_DESTINATION_CITY = 'destinationCity';
export const FIELD_LOAD_BOARD_ORIGIN_RADIUS_UOM = 'originRadiusUom';
export const FIELD_LOAD_BOARD_DESTINATION_STATE = 'destinationState';
export const FIELD_LOAD_BOARD_DESTINATION_RADIUS = 'destinationRadius';
export const FIELD_LOAD_BOARD_SHIPMENT_SPECIFICS = 'shipmentSpecifics';
export const FIELD_LOAD_BOARD_DESTINATION_COUNTRY = 'destinationCountry';
export const FIELD_LOAD_BOARD_DESTINATION_RADIUS_UOM = 'destinationRadiusUom';
// new load board fields
export const FIELD_LOAD_BOARD_TYPES = 'types';
export const FIELD_LOAD_BOARD_INTEGRATION_TYPE = 'type';
export const FIELD_LOAD_BOARD_DH_ORIGIN = 'deadHeadOrigin';
export const FIELD_LOAD_BOARD_DH_DESTINATION = 'deadHeadDestination';
// load board shipment
export const FIELD_LOAD_BOARD_RATE = 'rate';
export const FIELD_LOAD_BOARD_EMAIL = 'email';
export const FIELD_LOAD_BOARD_PHONE = 'phone';
export const FIELD_LOAD_BOARD_TYPE = FIELD_TYPE;
export const FIELD_LOAD_BOARD_NOTE = FIELD_NOTES;
export const FIELD_LOAD_BOARD_CONTACT = 'contact';
export const FIELD_LOAD_BOARD_COMPANY = 'company';
export const FIELD_LOAD_BOARD_BOOKING = 'booking';
export const FIELD_LOAD_BOARD_SHIPMENT = 'shipment';
export const FIELD_LOAD_BOARD_SOURCE = FIELD_SOURCE;
export const FIELD_LOAD_BOARD_COMMENTS = 'comments';
export const FIELD_LOAD_BOARD_RATE_UNIT = 'rateUnit';
export const FIELD_LOAD_BOARD_RATE_TYPE = 'rateType';
export const FIELD_LOAD_BOARD_DISTANCE = FIELD_DISTANCE;
export const FIELD_LOAD_BOARD_PICKUP_DATE = 'pickupDate';
export const FIELD_LOAD_BOARD_PHONE_EXT = 'phoneExtension';
export const FIELD_LOAD_BOARD_REFERENCE_ID = 'referenceId';
export const FIELD_LOAD_BOARD_DISTANCE_UOM = 'distanceUom';
export const FIELD_LOAD_BOARD_CREDIT_SCORE_SCORE = 'score';
export const FIELD_LOAD_BOARD_CREDIT_SCORE = 'creditScore';
export const FIELD_LOAD_BOARD_START_DATE = FIELD_START_DATE;
export const FIELD_LOAD_BOARD_COMPANY_ID = FIELD_COMPANY_ID;
export const FIELD_LOAD_BOARD_DELIVERY_DATE = 'deliveryDate';
export const FIELD_LOAD_BOARD_DISTANCE_INFO = 'distanceInfo';
export const FIELD_LOAD_BOARD_RATE_CURRENCY = 'rateCurrency';
export const FIELD_LOAD_BOARD_NEGOTIATED_RATE = 'negotiatedRate';
export const FIELD_LOAD_BOARD_ORIGIN_DEADHEAD = 'originDeadhead';
export const FIELD_LOAD_BOARD_PERSON_TALKED_TO = 'personTalkedTo';
export const FIELD_LOAD_BOARD_PICKUP_FREQUENCY = 'pickupFrequency';
export const FIELD_LOAD_BOARD_NEGOTIATED_PRICE = 'negotiatedPrice';
export const FIELD_LOAD_BOARD_BROKER_MC_NUMBERS = 'brokerMcNumbers';
export const FIELD_LOAD_BOARD_CREDIT_SCORE_DAYS_TO_PAY = 'daysToPay';
export const FIELD_LOAD_BOARD_PUBLISH_TO_CARRIER = 'publishToCarrier';
export const FIELD_LOAD_BOARD_LAST_MODIFIED_DATE = 'lastModifiedDate';
export const FIELD_LOAD_BOARD_CREDIT_SCORE_DATE_TIME = FIELD_DATE_TIME;
export const FIELD_LOAD_BOARD_ORIGIN_DEADHEAD_UOM = 'originDeadheadUom';
export const FIELD_LOAD_BOARD_DESTINATION_DEADHEAD = 'destinationDeadhead';
export const FIELD_LOAD_BOARD_DESTINATION_DEADHEAD_UOM = 'destinationDeadheadUom';
export const FIELD_LOAD_BOARD_NEGOTIATED_RATE_CURRENCY = 'negotiatedRateCurrency';
export const FIELD_LOAD_BOARD_NEGOTIATED_PRICE_CURRENCY = 'negotiatedPriceCurrency';
// EDI
export const FIELD_EDI_SENDER = 'sender';
export const FIELD_EDI_ACCEPT = 'accept';
export const FIELD_EDI_STATUS = 'status';
export const FIELD_EDI_DECLINE = 'decline';
export const FIELD_EDI_SENDER_ID = 'senderId';
export const FIELD_EDI_FILE_NAME = 'fileName';
export const FIELD_EDI_PROCESSED = 'processed';
export const FIELD_EDI_FILE_URI = FIELD_FILE_URI;
export const FIELD_EDI_EXTERNAL_ID = 'externalId';
export const FIELD_EDI_RECEIVED_DATE = 'receiveDate';
export const FIELD_EDI_ACCEPT_DECLINE = 'acceptDecline';
export const FIELD_EDI_TRANSACTION_DATE = 'transactionDate';
export const FIELD_EDI_TRANSACTION_GUID = 'transactionGuid';
export const FIELD_EDI_SENDER_QUALIFIER = 'senderQualifier';
export const FIELD_EDI_TRANSACTION_TYPE = 'transactionType';
export const FIELD_EDI_ORIGINAL_FILE_NAME = 'originalFileName';
export const FIELD_EDI_TRANSACTION_ACTION = 'transactionAction';
export const FIELD_EDI_SHIPMENT_EXTERNAL_ID = 'shipmentExternalId';
// QUOTES
export const FIELD_QUOTES_SENDER = 'sender';
export const FIELD_QUOTES_ACCEPT = 'accept';
export const FIELD_QUOTES_STATUS = 'status';
export const FIELD_QUOTES_DECLINE = 'decline';
export const FIELD_QUOTES_FILE_NAME = 'fileName';
export const FIELD_QUOTES_EXTERNAL_ID = 'externalId';
export const FIELD_QUOTES_REQUEST_DATE = 'requestDate';
export const FIELD_QUOTES_REQUEST_NUMBER = 'requestNumber';
export const FIELD_QUOTES_ACCEPT_DECLINE = 'acceptDecline';
export const FIELD_QUOTES_TRANSACTION_DATE = 'transactionDate';
export const FIELD_QUOTES_TRANSACTION_GUID = 'transactionGuid';
export const FIELD_QUOTES_ORIGINAL_FILE_NAME = 'originalFileName';
export const FIELD_QUOTES_TRANSACTION_ACTION = 'transactionAction';
// average-fuel-price
export const FIELD_AVERAGE_FUEL_PRICE_PRICE = FIELD_PRICE;
export const FIELD_AVERAGE_FUEL_PRICE_DISTRICT = 'district';
export const FIELD_AVERAGE_FUEL_PRICE_PRICE_DATE = 'priceDate';
// fuel-cards
export const FIELD_FUEL_CARDS_UOM = FIELD_UOM;
export const FIELD_FUEL_CARDS_LINES = 'lines';
export const FIELD_FUEL_CARDS_CARD_ID = 'cardId';
export const FIELD_FUEL_CARDS_TOTAL = FIELD_TOTAL;
export const FIELD_FUEL_CARDS_PRICE = FIELD_PRICE;
export const FIELD_FUEL_CARDS_SOURCE = FIELD_SOURCE;
export const FIELD_FUEL_CARDS_DISCOUNT = 'discount';
export const FIELD_FUEL_CARDS_GROUP_NAME = 'groupName';
export const FIELD_FUEL_CARDS_QUANTITY = FIELD_QUANTITY;
export const FIELD_FUEL_CARDS_PRODUCT_NAME = 'productName';
export const FIELD_FUEL_CARDS_PRODUCT_TYPE = 'productType';
export const FIELD_FUEL_CARDS_PRODUCT_CODE = 'productCode';
export const FIELD_FUEL_CARDS_PAYABLE_TOTAL = 'payableTotal';
export const FIELD_FUEL_CARDS_FUEL_CARD_GUID = 'fuelCardGuid';
export const FIELD_FUEL_CARDS_EXCLUDED_TOTAL = 'excludedTotal';
export const FIELD_FUEL_CARDS_PAYROLL_GUID = FIELD_PAYROLL_GUID;
export const FIELD_FUEL_CARDS_TRANSACTION_STOP = 'transactionStop';
export const FIELD_FUEL_CARDS_TRANSACTION_DATE = 'transactionDate';
export const FIELD_FUEL_CARDS_TRANSACTION_NUMBER = 'transactionNumber';
export const FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID = FIELD_VENDOR_PAYROLL_GUID;
// toll charges
export const FIELD_TOLL_CHARGES_SOURCE = 'source';
export const FIELD_TOLL_CHARGES_AMOUNT = 'amount';
export const FIELD_TOLL_CHARGES_AGENCY = 'agency';
export const FIELD_TOLL_CHARGES_POSITING_DATE = 'postingDate';
export const FIELD_TOLL_CHARGES_LOCATION_NAME = 'locationName';
export const FIELD_TOLL_CHARGES_TRANSPONDER_ID = 'transponderId';
export const FIELD_TOLL_CHARGES_PAYROLL_GUID = FIELD_PAYROLL_GUID;
export const FIELD_TOLL_CHARGES_LICENCE_PLATE = FIELD_LICENSE_PLATE;
export const FIELD_TOLL_CHARGES_TRANSACTION_DATE = 'transactionDate';
export const FIELD_TOLL_CHARGES_TRANSACTION_TYPE = 'transactionType';
// IFTA
export const FIELD_IFTA_TRUCKS = 'trucks';
export const FIELD_IFTA_DATE_TO = 'dateTo';
export const FIELD_IFTA_STATE = FIELD_STATE;
export const FIELD_IFTA_DATE_FROM = 'dateFrom';
export const FIELD_IFTA_TEL_GUIDS = FIELD_TEL_GUIDS;
export const FIELD_IFTA_STATE_DETAILS = 'stateDetails';
export const FIELD_IFTA_TRUCK_GUIDS = FIELD_TRUCK_GUIDS;
export const FIELD_IFTA_TOTAL_DISTANCE = FIELD_TOTAL_DISTANCE;
export const FIELD_IFTA_FUEL_QUANTITY_UOM = 'fuelQuantityUom';
export const FIELD_IFTA_TOTAL_FUEL_QUANTITY = 'totalFuelQuantity';
export const FIELD_IFTA_TOTAL_DISTANCE_UOM = FIELD_TOTAL_DISTANCE_UOM;
export const FIELD_IFTA_FUEL_TRANSACTION_GUIDS = 'fuelTransactionGuids';
// contact-book
export const FIELD_CONTACT_BOOK_COMPANY = 'company';
export const FIELD_CONTACT_BOOK_EMAIL = FIELD_EMAIL;
export const FIELD_CONTACT_BOOK_PHONE = FIELD_PHONE;
export const FIELD_CONTACT_BOOK_POSITION = 'position';
export const FIELD_CONTACT_BOOK_COMMENTS = FIELD_COMMENTS;
export const FIELD_CONTACT_BOOK_LAST_NAME = FIELD_LAST_NAME;
export const FIELD_CONTACT_BOOK_FIRST_NAME = FIELD_FIRST_NAME;
// dispatch board
export const FIELD_ITEMS_INFO = 'itemsInfo';
export const FIELD_ROUTE_GUID = 'routeGuid';
export const FIELD_DROP_COUNT = 'dropCount';
export const FIELD_EVENTS_INFO = 'eventsInfo';
export const FIELD_PICKUP_COUNT = 'pickupCount';
export const FIELD_ANY_HAZARDOUS = 'anyHazardous';
export const FIELD_LAST_LOCATION = 'lastLocation';
export const FIELD_CONTAINERS_INFO = 'containersInfo';
export const FIELD_PINNED_NOTE_TEXT = 'pinnedNoteText';
export const FIELD_TIME_TO_NEXT_STOP = 'timeToNextStop';
export const FIELD_LOAD_BOARD_SHIPMENTS = 'loadBoardShipments';
export const FIELD_DISTANCE_TO_NEXT_STOP = 'distanceToNextStop';
export const FIELD_LAST_CHAT_AUTHOR_TYPE = 'lastChatAuthorType';
export const FIELD_DISPATCH_BOARD_TRUCK_UNIT_ID = 'truckUnitId';
export const FIELD_SECONDARY_REF_NAME = 'secondaryReferenceName';
export const FIELD_SHOWN_ASSIGNMENT_CARRIER_NAME = 'carrierName';
export const FIELD_FLEET_ASSIGNMENT_GUID = 'fleetAssignmentGuid';
export const FIELD_SECONDARY_REF_VALUE = 'secondaryReferenceValue';
export const FIELD_SHOWN_FLEET_ASSIGNMENT = 'shownFleetAssignment';
export const FIELD_CARRIER_ASSIGNMENT_GUID = 'carrierAssignmentGuid';
export const FIELD_SHOWN_CARRIER_ASSIGNMENT = 'shownCarrierAssignment';
export const FIELD_ANY_APPOINTMENT_REQUIRED = 'anyAppointmentRequired';
export const FIELD_DRIVER_TRACKING_APP_INSTALLED = 'driverTrackingAppInstalled';
// geo fencing zone
export const FIELD_GEO_FENCING_ZONE_NAME = FIELD_NAME;
export const FIELD_GEO_FENCING_ZONE_CITIES = 'cities';
export const FIELD_GEO_FENCING_ZONE_STATES = 'states';
export const FIELD_GEO_FENCING_ZONE_ZIP_CODES = 'zipCodes';
export const FIELD_GEO_FENCING_ZONE_COUNTRIES = 'countries';
export const FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO = 'zipRangeTo';
export const FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM = 'zipRangeFrom';
export const FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS = 'zoneAddressPoints';
// accounting
export const FIELD_ACCOUNTING_USER_ID = 'userId';
export const FIELD_ACCOUNTING_SENDER_ID = 'senderId';
export const FIELD_ACCOUNTING_USER_PASSWORD = 'userPassword';
export const FIELD_ACCOUNTING_SENDER_PASSWORD = 'senderPassword';
// los subscription
export const FIELD_LINKED = 'linked';
export const FIELD_EMAIL_ID = 'emailId';
export const FIELD_LINKED_BY = 'linkedBy';
export const FIELD_PLAN_NAME = 'planName';
export const FIELD_RECEIVED_DATE = 'receiveDate';
export const FIELD_REJECT_REASON = 'rejectReason';
export const FIELD_ACCEPT_REJECT = 'acceptReject';
export const FIELD_CONTACT_NUMBER = 'contactNumber';
export const FIELD_BRANCH_DELETED = 'branchDeleted';
export const FIELD_SUBSCRIPTION_ID = 'subscriptionId';
export const FIELD_CONFIRMATION_DATE = 'confirmationDate';
export const FIELD_UNSUBSCRIPTION_DATE = 'unsubscriptionDate';
export const FIELD_ACCEPT_OR_REJECT_DATE = 'acceptOrRejectDate';
export const FIELD_MASTER_ENTERPRISE_NAME = 'masterEnterpriseName';
export const FIELD_MASTER_ENTERPRISE_GUID = 'masterEnterpriseGuid';
export const FIELD_ACCEPTED_OR_REJECTED_BY = 'acceptedOrRejectedBy';
export const FIELD_USE_EXISTENT_ENTERPRISE = 'useExistentEnterprise';
export const FIELD_LOS_PRICING_CONFIG_NAME = 'losPricingPlanConfigName';
// task management
export const FIELD_TASK_TITLE = 'taskTitle';
export const FIELD_RELATED_TASKS = 'relatedTasks';
export const FIELD_TASK_TYPE_GUIDS = 'taskTypeGuids';
export const FIELD_SUB_OBJECT_TYPE = 'subObjectType';
export const FIELD_SUB_OBJECT_GUID = 'subObjectGuid';
export const FIELD_TASK_DESCRIPTION = 'taskDescription';
// claim management
export const FIELD_CLAIM_DATE = 'claimDate';
export const FIELD_CLAIM_NUMBER = 'claimNumber';
// expense type config
export const FIELD_CONFIGURATION_MAINTENANCE_TYPE = 'maintenanceType';

export const CHASSIS_GROUPED_FIELDS = [
  FIELD_CHASSIS_NUMBER,
  FIELD_DRAY_CONTAINER_DROP_NUMBER,
  FIELD_DRAY_CONTAINER_PICK_UP_NUMBER,
];

export const GROUPED_FIELDS = {
  API_DEFAULT_FIELDS: [
    FIELD_GUID,
    FIELD_VERSION,
    FIELD_CREATED_BY,
    FIELD_CREATED_DATE,
    FIELD_LAST_MODIFIED_BY,
    FIELD_LAST_MODIFIED_DATE,
  ],
  BRANCH_SETTINGS_OBJ: [
    FIELD_GUID,
    FIELD_CREATED_BY,
    FIELD_CREATED_DATE,
    FIELD_ITEM_VERSION,
    FIELD_ITEM_VERSION,
    FIELD_LOAD_BRANCH_GUID,
    FIELD_LAST_MODIFIED_BY,
    FIELD_LAST_MODIFIED_DATE,
    FIELD_BRANCH_MAX_SMS_PRICE,
    FIELD_BRANCH_SMS_NOTIFICATION_ENABLED,
    FIELD_BRANCH_SETTINGS_FLEET_DRIVER_SMS_ENABLED,
    FIELD_BRANCH_SETTINGS_CARRIER_DRIVER_SMS_ENABLED,
  ],
  DRIVER_COMPENSATION_MAIN_FIELDS: [
    FIELD_MODE,
    FIELD_CURRENCY,
    FIELD_MIN_WEIGHT,
    FIELD_WEIGHT_UOM,
    FIELD_MAX_WEIGHT,
    FIELD_CHARGE_RATE,
    FIELD_TRANSIT_DAYS,
    FIELD_SERVICE_TYPE,
    FIELD_MIN_QUANTITY,
    FIELD_MAX_QUANTITY,
    FIELD_PACKAGE_TYPE,
    FIELD_MIN_TEMPERATURE,
    FIELD_MAX_TEMPERATURE,
    FIELD_TEMPERATURE_UOM,
    FIELD_CHARGE_MAX_RATE,
    FIELD_CHARGE_MIN_RATE,
    FIELD_CHARGE_RATE_UNIT,
    FIELD_CHARGE_RATE_TYPE,
    FIELD_GET_FUEL_FROM_ORDER,
    FIELD_DRIVER_COMPENSATION_GUID,
    FIELD_DRIVER_COMPENSATION_NAME,
    FIELD_DRIVER_COMPENSATION_VERSION,
    FIELD_DRIVER_COMPENSATION_DISTANCE_OUM,
    FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER,
    FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE,
    FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE,
    FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO,
    FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM,
  ],
  DRIVER_COMPENSATION_LOCATIONS_FIELDS: [
    FIELD_DRIVER_COMPENSATION_CITIES,
    FIELD_DRIVER_COMPENSATION_STATES,
    FIELD_DRIVER_COMPENSATION_COUNTRIES,
    FIELD_DRIVER_COMPENSATION_ZIP_CODES,
    FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO,
    FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM,
  ],
  APPOINTMENTS_ARR: [
    FIELD_LOAD_APPOINTMENT_DATE,
    FIELD_LOAD_APPOINTMENT_NUMBER,
    FIELD_LOAD_APPOINTMENT_LATE_TIME,
    FIELD_LOAD_APPOINTMENT_EARLY_TIME,
  ],
  APPOINTMENTS_OBJ: {
    FIELD_LOAD_APPOINTMENT_DATE,
    FIELD_LOAD_APPOINTMENT_NUMBER,
    FIELD_LOAD_APPOINTMENT_LATE_TIME,
    FIELD_LOAD_APPOINTMENT_EARLY_TIME,
  },
  APPOINTMENTS_PICK_ARR: [
    FIELD_GUID,
    FIELD_LOAD_TYPE,
    FIELD_LOAD_GUID,
    FIELD_LOAD_APPOINTMENT_DATE,
    FIELD_LOAD_APPOINTMENT_NUMBER,
    FIELD_LOAD_APPOINTMENT_LATE_TIME,
    FIELD_LOAD_APPOINTMENT_EARLY_TIME,
  ],
  LOAD_PICK_ARR: [
    FIELD_GUID,
    FIELD_LOAD_SERVICES,
    FIELD_LOAD_EQUIPMENT,
    FIELD_LOAD_ORDER_TYPE,
    FIELD_CUSTOM_STATUS_GUID,
    FIELD_LOAD_SPECIAL_INSTRUCTIONS,
    FIELD_LOAD_INTERNAL_INSTRUCTIONS,
  ],
  EVENT_DATES_ARR: [
    FIELD_LOAD_EVENT_LATE_DATE,
    FIELD_LOAD_EVENT_EARLY_DATE,
  ],
  EVENT_DATES_TIME_ARR: [
    FIELD_LOAD_EVENT_LATE_DATE,
    FIELD_LOAD_EVENT_LATE_TIME,
    FIELD_LOAD_EVENT_EARLY_DATE,
    FIELD_LOAD_EVENT_EARLY_TIME,
  ],
  EVENT_DATES_OBJ: {
    FIELD_LOAD_EVENT_LATE_DATE,
    FIELD_LOAD_EVENT_EARLY_DATE,
  },
  EVENT_LOCATION_ARR: [
    FIELD_ZIP,
    FIELD_CITY,
    FIELD_STATE,
    FIELD_COUNTRY,
    FIELD_COMMENTS,
    FIELD_LATITUDE,
    FIELD_ADDRESS_1,
    FIELD_ADDRESS_2,
    FIELD_LONGITUDE,
    FIELD_TEMPLATE_ID,
    FIELD_LOCATION_NAME,
    FIELD_OPERATION_HOUR,
  ],
  EVENT_LOCATION_ARR_2: [
    FIELD_ZIP,
    FIELD_CITY,
    FIELD_STATE,
    FIELD_COUNTRY,
    FIELD_COMMENTS,
    FIELD_ADDRESS_1,
    FIELD_ADDRESS_2,
    FIELD_TEMPLATE_ID,
    FIELD_LOCATION_NAME,
  ],
  EVENT_FULL_LOCATION_ARR: [
    FIELD_ZIP,
    FIELD_CITY,
    FIELD_STATE,
    FIELD_ACTIVE,
    FIELD_COUNTRY,
    FIELD_VERSION,
    FIELD_COMMENTS,
    FIELD_LATITUDE,
    FIELD_CONTACTS,
    FIELD_ADDRESS_1,
    FIELD_ADDRESS_2,
    FIELD_LONGITUDE,
    FIELD_CROSSDOCK,
    FIELD_TEMPLATE_ID,
    FIELD_RESIDENTIAL,
    FIELD_BRANCH_GUID,
    FIELD_LOCATION_TYPE,
    FIELD_LOCATION_NAME,
    FIELD_OPERATION_HOUR,
    FIELD_INTEGRATION_ID,
    FIELD_INTEGRATION_TYPE,
  ],
  CONTACT_ARR: [
    FIELD_FAX,
    FIELD_EMAIL,
    FIELD_PHONE,
    FIELD_CONTACT_NAME,
  ],
  LOCATION_TEMPLATE_ARR: [
    FIELD_ZIP,
    FIELD_CITY,
    FIELD_STATE,
    FIELD_COUNTRY,
    FIELD_ADDRESS_1,
    FIELD_ADDRESS_2,
    FIELD_LOCATION_NAME,
  ],
  LATEST_KNOWN_LOCATION_ARR: [
    FIELD_ZIP,
    FIELD_CITY,
    FIELD_STATE,
    FIELD_COUNTRY,
    FIELD_LATITUDE,
    FIELD_LONGITUDE,
  ],
  EVENT_CONTACT_ARR: [
    FIELD_FAX,
    FIELD_EMAIL,
    FIELD_PHONE,
    FIELD_CONTACT_NAME,
    FIELD_PHONE_EXTENSION,
  ],
  SYSTEM_OMIT_ARR: [
    FIELD_GUID,
    FIELD_VERSION,
    FIELD_CREATED_BY,
    FIELD_CREATED_DATE,
    FIELD_LAST_MODIFIED_BY,
    FIELD_LAST_MODIFIED_DATE,
  ],
  SYSTEM_OMIT_ARR_2: [
    FIELD_VERSION,
    FIELD_CREATED_BY,
    FIELD_CREATED_DATE,
    FIELD_LAST_MODIFIED_BY,
    FIELD_LAST_MODIFIED_DATE,
  ],
  SYSTEM_OMIT_FULL_ARR: [
    FIELD_GUID,
    FIELD_ACTIVE,
    FIELD_VERSION,
    FIELD_CREATED_BY,
    FIELD_BRANCH_GUID,
    FIELD_CREATED_DATE,
    FIELD_LAST_MODIFIED_BY,
    FIELD_LAST_MODIFIED_DATE,
  ],
  EVENT_ITEM_PICK_ARR: [
    FIELD_GUID,
    FIELD_VERSION,
    FIELD_CREATED_BY,
    FIELD_CREATED_DATE,
    FIELD_ITEM_TEL_GUID,
    FIELD_ITEM_CLO_GUID,
    FIELD_ITEM_INTERNAL_ID,
    FIELD_LAST_MODIFIED_BY,
    FIELD_LAST_MODIFIED_DATE,
  ],
  FLEET_ASSIGNMENT_PICK_ARR: [
    FIELD_TRUCK_GUID,
    FIELD_TRAILER_GUIDS,
    ...CHASSIS_GROUPED_FIELDS,
    FIELD_PRIMARY_DRIVER_GUID,
    FIELD_SECONDARY_DRIVER_GUID,
  ],
  FLEET_ASSIGNMENT_PICK_ARR_2: [
    FIELD_TRUCK_GUID,
    FIELD_TRAILER_GUIDS,
    FIELD_PRIMARY_DRIVER_GUID,
    FIELD_SECONDARY_DRIVER_GUID,
  ],
  STATUS_MESSAGE_PICK_ARR: [
    FIELD_STATUS_MESSAGE_DATE,
    FIELD_STATUS_MESSAGE_CODE,
    FIELD_STATUS_MESSAGE_ENT_GUID,
    FIELD_STATUS_MESSAGE_LOAD_GUID,
    FIELD_STATUS_MESSAGE_REASON_CODE,
    FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID,
  ],
  STATUS_MESSAGE_OMIT_ARR: [
    FIELD_STATUS_MESSAGE_DATE,
    FIELD_STATUS_MESSAGE_GUID,
    FIELD_STATUS_MESSAGE_CODE,
    FIELD_STATUS_MESSAGE_ENT_GUID,
    FIELD_STATUS_MESSAGE_LOAD_GUID,
    FIELD_STATUS_MESSAGE_REASON_CODE,
    FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID,
  ],
  ADD_DOCUMENT_PICK_VALUE: [
    FIELD_PROOF_TYPE,
    FIELD_EVENT_GUID,
  ],
  STATUS_MESSAGE_LOCATION: [
    FIELD_ZIP,
    FIELD_CITY,
    FIELD_STATE,
    FIELD_COUNTRY,
    FIELD_LATITUDE,
    FIELD_LONGITUDE,
  ],
  CHARGE_FIELDS_ARR: [
    FIELD_CHARGE_RATE,
    FIELD_CHARGE_TYPE,
    FIELD_CHARGE_TOTAL,
    FIELD_CHARGE_QUANTITY,
    FIELD_CHARGE_RATE_TYPE,
    FIELD_CHARGE_RATE_NAME,
    FIELD_CHARGE_RATE_UNIT,
    FIELD_CHARGE_NON_TAXABLE,
    FIELD_CHARGE_SEQUENCE_NUM,
    FIELD_CHARGE_RATE_NAME_STORED,
  ],
  TRIP_TOTALS_ARR: [
    FIELD_CUSTOMER_TOTAL,
    FIELD_DEADHEAD_DISTANCE,
    FIELD_TOTAL_TRIP_VOLUME,
    FIELD_TOTAL_TRIP_WEIGHT,
    FIELD_TOTAL_TRIP_DISTANCE,
    FIELD_DEADHEAD_DISTANCE_UOM,
    FIELD_TOTAL_TRIP_VOLUME_UOM,
    FIELD_TOTAL_TRIP_WEIGHT_UOM,
    FIELD_TOTAL_TRIP_DISTANCE_UOM,
  ],
  FREIGHT_CLASS_CALCULATION_FIELDS: [
    FIELD_ITEM_WEIGHT,
    FIELD_ITEM_WEIGHT_TYPE,
    FIELD_ITEM_DIMENSIONS_UOM,
    FIELD_ITEM_DIMENSIONS_WIDTH,
    FIELD_ITEM_DIMENSIONS_LENGTH,
    FIELD_ITEM_DIMENSIONS_HEIGHT,
  ],
  RATE_MARGIN_VIOLATION_INFO_FIELDS: [
    FIELD_MAX_PAY_VIOLATED,
    FIELD_MIN_MARGIN_VIOLATED,
    FIELD_MARGIN_VIOLATION_NOTE,
    FIELD_MARGIN_VIOLATION_REASON,
    FIELD_CRITICAL_MARGIN_VIOLATED,
    FIELD_MARGIN_VIOLATION_APPROVED,
  ],
};

export const systemFieldsLocaleMap = {
  // hours
  [FIELD_CYCLE_TOMORROW]: 'titles:cycle-tomorrow',
  [FIELD_CYCLE_REMAINING]: 'titles:cycle-remaining',
  [FIELD_SHIFT_REMAINING]: 'titles:shift-remaining',
  [FIELD_TIME_UNTIL_BREAK]: 'titles:time-until-break',
  [FIELD_DUTY_STATUS_CODE]: 'titles:duty-status-code',
  [FIELD_OUT_OF_DUTY_REASON]: 'titles:out-of-duty-reason',
  [FIELD_SHIFT_DRIVER_REMAINING]: 'titles:shift-drive-remaining',
  [FIELD_TIME_IN_CURRENT_STATUS]: 'titles:time-in-current-status',
};

// SYSTEM OBJECT
export const SYSTEM_OBJECT_RATE = 'rate';
export const SYSTEM_OBJECT_CLAIM = 'claim';
export const SYSTEM_OBJECT_ROLES = 'roles';
export const SYSTEM_OBJECT_ROUTE = 'route';
export const SYSTEM_OBJECT_TRUCK = 'truck';
export const SYSTEM_OBJECT_PARENT = 'parent';
export const SYSTEM_OBJECT_DRIVER = 'driver';
export const SYSTEM_OBJECT_VENDOR = 'vendor';
export const SYSTEM_OBJECT_BILL_TO = 'billTo';
export const SYSTEM_OBJECT_STYLING = 'styling';
export const SYSTEM_OBJECT_CARRIER = 'carrier';
export const SYSTEM_OBJECT_INVOICE = 'invoice';
export const SYSTEM_OBJECT_PAYROLL = 'payroll';
export const SYSTEM_OBJECT_TEL_INFO = 'telInfo';
export const SYSTEM_OBJECT_REMIT_TO = 'remitTo';
export const SYSTEM_OBJECT_TERMINAL = 'terminal';
export const SYSTEM_OBJECT_LOCATION = 'location';
export const SYSTEM_OBJECT_WORK_ORDER = 'workOrder';
export const SYSTEM_OBJECT_LAST_EVENT = 'lastEvent';
export const SYSTEM_OBJECT_NEXT_EVENT = 'nextEvent';
export const SYSTEM_OBJECT_ITEMS_INFO = 'itemsInfo';
export const SYSTEM_OBJECT_INSURANCES = 'insurances';
export const SYSTEM_OBJECT_INSPECTION = 'inspection';
export const SYSTEM_OBJECT_FIRST_EVENT = 'firstEvent';
export const SYSTEM_OBJECT_PINNED_NOTE = 'pinnedNote';
export const SYSTEM_OBJECT_FLEET_VENDOR = 'fleetVendor';
export const SYSTEM_OBJECT_NOTIFICATION = 'notification';
export const SYSTEM_OBJECT_CARRIER_DRIVER = 'carrierDriver';
export const SYSTEM_OBJECT_LOS_SUBSCRIPTION_ORDER = 'order';
export const SYSTEM_OBJECT_MASTER_INVOICE = 'masterInvoice';
export const SYSTEM_OBJECT_PRIMARY_DRIVER = 'primaryDriver';
export const SYSTEM_OBJECT_DRIVER_PAYROLL = 'driverPayroll';
export const SYSTEM_OBJECT_SERVICE_VENDOR = 'serviceVendor';
export const SYSTEM_OBJECT_FUEL_INDEX_INFO = 'fuelIndexInfo';
export const SYSTEM_OBJECT_PAY_TO_LOCATION = 'payToLocation';
export const SYSTEM_OBJECT_CREATED_BY_USER = 'createdByUser';
export const SYSTEM_OBJECT_COST_ALLOCATION = 'costAllocation';
export const SYSTEM_OBJECT_STATUS_MESSAGES = 'statusMessages';
export const SYSTEM_OBJECT_TEL_CARRIER_RATE = 'telCarrierRate';
export const SYSTEM_OBJECT_CUSTOMER_DETAILS = 'customerDetails';
export const SYSTEM_OBJECT_PRIMARY_LOCATION = 'primaryLocation';
export const SYSTEM_OBJECT_SECONDARY_DRIVER = 'secondaryDriver';
export const SYSTEM_OBJECT_FLEET_ASSIGNMENT = 'fleetAssignment';
export const SYSTEM_OBJECT_CARRIER_SNAPSHOT = 'carrierSnapshot';
export const SYSTEM_OBJECT_NORMALIZED_TOTAL = 'normalizedTotal';
export const SYSTEM_OBJECT_CARRIER_INVOICES = 'carrierInvoices';
export const SYSTEM_OBJECT_CONFIRMED_BY_USER = 'confirmedByUser';
export const SYSTEM_OBJECT_TRAILER_OWNERSHIP = 'trailerOwnership';
export const SYSTEM_OBJECT_DISPATCHING_GROUP = 'dispatchingGroup';
export const SYSTEM_OBJECT_PRIMARY_REFERENCE = 'primaryReference';
export const SYSTEM_OBJECT_TERMINAL_SNAPSHOT = 'terminalSnapshot';
export const SYSTEM_OBJECT_LAST_TERMINAL_DROP = 'lastTerminalDrop';
export const SYSTEM_OBJECT_INTEGRATION_CONFIG = 'integrationConfig';
export const SYSTEM_OBJECT_CARRIER_ASSIGNMENT = 'carrierAssignment';
export const SYSTEM_OBJECT_GEOFENCING_LOCATION = 'geofencingLocation';
export const SYSTEM_OBJECT_LAST_STATUS_MESSAGE = 'lastStatusMessage';
export const SYSTEM_OBJECT_ASSIGNED_DISPATCHER = 'assignedDispatcher';
export const SYSTEM_OBJECT_TRUCK_SPECIFICATION = 'truckSpecification';
export const SYSTEM_OBJECT_LOAD_BOARD_SHIPMENTS = 'loadBoardShipments';
export const SYSTEM_OBJECT_FLEET_VENDOR_PAYROLL = 'fleetVendorPayroll';
export const SYSTEM_OBJECT_ASSIGNED_TO_DIVISION = 'assignedToDivision';
export const SYSTEM_OBJECT_ORIGINATING_DIVISION = 'originatingDivision';
export const SYSTEM_OBJECT_TRAILER_SPECIFICATION = 'trailerSpecification';
export const SYSTEM_OBJECT_ACCOUNTING_INTEGRATION = 'accountingIntegration';
export const SYSTEM_OBJECT_SHOWN_CARRIER_ASSIGNMENT = 'shownCarrierAssignment';
export const SYSTEM_OBJECT_RATE_MARGIN_VIOLATION_INFO = 'rateMarginViolationInfo';
// SYSTEM DROPDOWNS
export const SYSTEM_DROPDOWN_STATUS = 'status';
export const SYSTEM_DROPDOWN_EQUIPMENT = 'equipment';
export const SYSTEM_DROPDOWN_MODE = 'transportationMode';
export const SYSTEM_DROPDOWN_SERVICE_TYPE = 'serviceType';
export const SYSTEM_DROPDOWN_LOCATION_TYPE = 'locationType';
export const SYSTEM_DROPDOWN_ORDER_TYPE = FIELD_LOAD_ORDER_TYPE;
export const SYSTEM_DROPDOWN_STATUS_REASON_CODE = 'statusReasonCode';
// SYSTEM LISTS
export const SYSTEM_LIST_TELS = 'tels';
export const SYSTEM_LIST_CLOS = 'clos';
export const SYSTEM_LIST_RATES = 'rates';
export const SYSTEM_LIST_ITEMS = 'items';
export const SYSTEM_LIST_TRAILERS = 'trailers';
export const SYSTEM_LIST_INVOICES = 'invoices';
export const SYSTEM_LIST_COMPONENTS = 'components';
export const SYSTEM_LIST_FUEL_CHARGES = 'fuelCharges';
export const SYSTEM_LIST_HAZMAT_ITEMS = 'hazmatItems';
export const SYSTEM_LIST_FLEET_INVOICES = 'fleetInvoices';
export const SYSTEM_LIST_NON_HAZMAT_ITEMS = 'nonHazmatItems';
export const SYSTEM_LIST_CARRIER_INVOICES = 'carrierInvoices';
export const SYSTEM_LIST_COST_ALLOCATIONS = 'costAllocations';
export const SYSTEM_LIST_FUEL_TRANSACTIONS = 'fuelTransactions';
export const SYSTEM_LIST_DISPATCHING_GROUP = 'dispatchingGroups';
export const SYSTEM_LIST_GRANTED_ENTERPRISES = 'grantedEnterprises';
export const SYSTEM_LIST_FLEET_VENDOR_INVOICES = 'fleetVendorInvoices';
export const SYSTEM_LIST_COMMISSION_ASSIGNMENTS = 'commissionAssignments';

export const GRC = {
  SHARED_TO_BRANCHES_GUID: `${SHARED_TO_BRANCHES}.${FIELD_GUID}`,
  MACRO_POINT_INFO_STATUS: `${FIELD_MACRO_POINT_INFO}.${FIELD_STATUS}`,
  SHARED_TO_BRANCHES_BRANCH_NAME: `${SHARED_TO_BRANCHES}.${BRANCH_NAME}`,
  ORIGINATING_DIVISION_BRANCH_NAME: `${SYSTEM_OBJECT_ORIGINATING_DIVISION}.${FIELD_BRANCH_NAME}`,
  // gl code
  GL_CODE_DISPLAYED_VALUE: `${FIELD_GL_CODE}.${FIELD_DISPLAYED_VALUE}`,
  // order type
  ORDER_TYPE_DISPLAYED_VALUE: `${SYSTEM_DROPDOWN_ORDER_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // REFERENCE
  REFERENCES_NAME: `${FIELD_REFERENCES}.${FIELD_NAME}`,
  REFERENCES_VALUE: `${FIELD_REFERENCES}.${FIELD_VALUE}`,
  TEL_PRIMARY_REF_VALUE: `${FIELD_TEL}.${FIELD_PRIMARY_REFERENCE_VALUE}`, // TODO: check if it works everywhere
  PRIMARY_REFERENCE_NAME: `${SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_NAME}`,
  PRIMARY_REFERENCE_VALUE: `${SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_VALUE}`,
  TEL_PRIMARY_REFERENCE_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_NAME}`,
  TEL_PRIMARY_REFERENCE_VALUE: `${FIELD_TEL}.${SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_VALUE}`,
  CLO_PRIMARY_REFERENCE_VALUE: `${FIELD_CLO}.${SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_VALUE}`,
  CLOS_PRIMARY_REFERENCE_VALUE: `${SYSTEM_LIST_CLOS}.${SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_VALUE}`,
  TELS_PRIMARY_REFERENCE_VALUE: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_VALUE}`,
  // RATE
  RATE_TOTAL: `${SYSTEM_OBJECT_RATE}.${FIELD_TOTAL}`,
  RATE_SOURCE: `${SYSTEM_OBJECT_RATE}.${FIELD_SOURCE}`,
  RATE_CURRENCY: `${SYSTEM_OBJECT_RATE}.${FIELD_CURRENCY}`,
  RATE_TRANSPORTATION_MODE: `${SYSTEM_OBJECT_RATE}.${FIELD_MODE}`,
  RATE_SERVICE_DAYS: `${SYSTEM_OBJECT_RATE}.${FIELD_SERVICE_DAYS}`,
  RATE_TRIP_MAX_PAY: `${SYSTEM_OBJECT_RATE}.${FIELD_TRIP_MAX_PAY}`,
  RATE_CUSTOMER_TOTAL: `${SYSTEM_OBJECT_RATE}.${FIELD_CUSTOMER_TOTAL}`,
  RATE_MAX_PAY_VIOLATED: `${SYSTEM_OBJECT_RATE}.${FIELD_MAX_PAY_VIOLATED}`,
  RATE_DEADHEAD_DISTANCE: `${SYSTEM_OBJECT_RATE}.${FIELD_DEADHEAD_DISTANCE}`,
  RATE_MAIN_CHARGES_TOTAL: `${SYSTEM_OBJECT_RATE}.${FIELD_MAIN_CHARGES_TOTAL}`,
  RATE_MIN_MARGIN_VIOLATED: `${SYSTEM_OBJECT_RATE}.${FIELD_MIN_MARGIN_VIOLATED}`,
  RATE_MARGIN_VIOLATION_NOTE: `${SYSTEM_OBJECT_RATE}.${FIELD_MARGIN_VIOLATION_NOTE}`,
  RATE_CHARGE_NAME: `${SYSTEM_OBJECT_RATE}.${FIELD_CHARGES}.${FIELD_DISPLAYED_NAME}`,
  RATE_MODE: `${SYSTEM_OBJECT_RATE}.${SYSTEM_DROPDOWN_MODE}.${FIELD_DISPLAYED_VALUE}`,
  RATE_DISCOUNT_CHARGES_TOTAL: `${SYSTEM_OBJECT_RATE}.${FIELD_DISCOUNT_CHARGES_TOTAL}`,
  RATE_DISPATCH_OR_CANCEL_DATE: `${SYSTEM_OBJECT_RATE}.${FIELD_DISPATCH_OR_CANCEL_DATE}`,
  RATE_NORMALIZED_TRIP_MAX_PAY: `${SYSTEM_OBJECT_RATE}.${FIELD_NORMALIZED_TRIP_MAX_PAY}`,
  RATE_DISPATCHED_OR_CANCELED_BY: `${SYSTEM_OBJECT_RATE}.${FIELD_DISPATCHED_OR_CANCEL_BY}`,
  RATE_STORED_DEADHEAD_DISTANCE: `${SYSTEM_OBJECT_RATE}.${FIELD_STORED_DEADHEAD_DISTANCE}`,
  RATE_ADDITIONAL_CHARGES_TOTAL: `${SYSTEM_OBJECT_RATE}.${FIELD_ADDITIONAL_CHARGES_TOTAL}`,
  RATE_CRITICAL_MARGIN_VIOLATED: `${SYSTEM_OBJECT_RATE}.${FIELD_CRITICAL_MARGIN_VIOLATED}`,
  RATE_MODE_DISPLAYED_VALUE: `${SYSTEM_OBJECT_RATE}.${FIELD_MODE}.${FIELD_DISPLAYED_VALUE}`,
  RATE_MARGIN_VIOLATION_APPROVED: `${SYSTEM_OBJECT_RATE}.${FIELD_MARGIN_VIOLATION_APPROVED}`,
  RATE_STORED_TOTAL_TRIP_DISTANCE: `${SYSTEM_OBJECT_RATE}.${FIELD_STORED_TOTAL_TRIP_DISTANCE}`,
  RATE_NORMALIZED_TOTAL: `${SYSTEM_OBJECT_RATE}.${SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_TOTAL}`,
  RATE_MODE_DROPDOWN_OPTION_GUID: `${SYSTEM_OBJECT_RATE}.${FIELD_MODE}.${FIELD_DROPDOWN_OPTION_GUID}`,
  RATE_NORMALIZED_TOTAL_CURRENCY: `${SYSTEM_OBJECT_RATE}.${SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_CURRENCY}`,
  RATE_MARGIN_VIOLATION_REASON: `${SYSTEM_OBJECT_RATE}.${FIELD_MARGIN_VIOLATION_REASON}.${FIELD_DISPLAYED_VALUE}`,
  // rate fuel charges
  RATE_FUEL_CHARGES_TOTAL: `${SYSTEM_OBJECT_RATE}.${FIELD_FUEL_CHARGES_TOTAL}`,
  RATE_FUEL_CHARGES_RATE: `${SYSTEM_OBJECT_RATE}.${SYSTEM_LIST_FUEL_CHARGES}.${FIELD_RATE}`,
  RATE_FUEL_CHARGES_QUANTITY: `${SYSTEM_OBJECT_RATE}.${SYSTEM_LIST_FUEL_CHARGES}.${FIELD_QUANTITY}`,
  // rate fuel index info
  RATE_FUEL_CHARGES_FUEL_INDEX_INFO_PRICE: `${SYSTEM_OBJECT_RATE}.${SYSTEM_LIST_FUEL_CHARGES}.${
    SYSTEM_OBJECT_FUEL_INDEX_INFO}.${FIELD_PRICE}`,
  RATE_FUEL_CHARGES_FUEL_INDEX_INFO_RATE: `${SYSTEM_OBJECT_RATE}.${SYSTEM_LIST_FUEL_CHARGES}.${
    SYSTEM_OBJECT_FUEL_INDEX_INFO}.${FIELD_RATE}`,
  RATE_FUEL_CHARGES_FUEL_INDEX_INFO_DISTRICT: `${SYSTEM_OBJECT_RATE}.${SYSTEM_LIST_FUEL_CHARGES}.${
    SYSTEM_OBJECT_FUEL_INDEX_INFO}.${FIELD_DISTRICT}`,
  RATE_FUEL_CHARGES_FUEL_INDEX_INFO_PRICE_DATE: `${SYSTEM_OBJECT_RATE}.${SYSTEM_LIST_FUEL_CHARGES}.${
    SYSTEM_OBJECT_FUEL_INDEX_INFO}.${FIELD_PRICE_DATE}`,
  // normalized total
  NORMALIZED_TOTAL_TOTAL: `${SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_TOTAL}`,
  NORMALIZED_TOTAL_CURRENCY: `${SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_CURRENCY}`,
  // LOCATION
  LOCATION_ZIP: `${SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  LOCATION_CITY: `${SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  LOCATION_EMAIL: `${SYSTEM_OBJECT_LOCATION}.${FIELD_EMAIL}`,
  LOCATION_PHONE: `${SYSTEM_OBJECT_LOCATION}.${FIELD_PHONE}`,
  LOCATION_STATE: `${SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  LOCATION_COUNTRY: `${SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  LOCATION_ADDRESS: `${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS}`,
  LOCATION_COMMENTS: `${SYSTEM_OBJECT_LOCATION}.${FIELD_COMMENTS}`,
  LOCATION_LATITUDE: `${SYSTEM_OBJECT_LOCATION}.${FIELD_LATITUDE}`,
  LOCATION_NAME: `${SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  LOCATION_ADDRESS1: `${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  LOCATION_ADDRESS2: `${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  LOCATION_LONGITUDE: `${SYSTEM_OBJECT_LOCATION}.${FIELD_LONGITUDE}`,
  LOCATION_TEMPLATE_ID: `${SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  LOCATION_PAYMENT_TERM: `${SYSTEM_OBJECT_LOCATION}.${FIELD_PAYMENT_TERM}`,
  LOCATION_TYPE: `${SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // LAST TERMINAL DROP
  LAST_TERMINAL_DROP_LATE_DATE: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${FIELD_LATE_DATE}`,
  LAST_TERMINAL_DROP_EARLY_DATE: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${FIELD_EARLY_DATE}`,
  LAST_TERMINAL_DROP_ZIP: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  LAST_TERMINAL_DROP_CITY: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  LAST_TERMINAL_DROP_EMAIL: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_EMAIL}`,
  LAST_TERMINAL_DROP_PHONE: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_PHONE}`,
  LAST_TERMINAL_DROP_STATE: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  LAST_TERMINAL_DROP_COUNTRY: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  LAST_TERMINAL_DROP_COMMENTS: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_COMMENTS}`,
  LAST_TERMINAL_DROP_NAME: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  LAST_TERMINAL_DROP_ADDRESS1: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  LAST_TERMINAL_DROP_ADDRESS2: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  LAST_TERMINAL_DROP_TEMPLATE_ID: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_TEMPLATE_ID}`,
  LAST_TERMINAL_DROP_PAYMENT_TERM: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_PAYMENT_TERM}`,
  LAST_TERMINAL_DROP_TYPE: `${SYSTEM_OBJECT_LAST_TERMINAL_DROP}.${SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_TYPE}.${
    FIELD_DISPLAYED_VALUE}`,
  // BILL TO
  BILL_TO_ZIP: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_ZIP}`,
  BILL_TO_CITY: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_CITY}`,
  BILL_TO_EMAIL: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_EMAIL}`,
  BILL_TO_PHONE: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_PHONE}`,
  BILL_TO_STATE: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_STATE}`,
  BILL_TO_EMAILS: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_EMAILS}`,
  BILL_TO_COUNTRY: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_COUNTRY}`,
  BILL_TO_COMMENTS: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_COMMENTS}`,
  BILL_TO_ADDRESS1: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_ADDRESS_1}`,
  BILL_TO_TEMPLATE_ID: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_TEMPLATE_ID}`,
  BILL_TO_PAYMENT_TERM: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_PAYMENT_TERM}`,
  BILL_TO_EMAILS_FIELD: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_EMAILS_FIELD}`,
  BILL_TO_CONTACT_NAME: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_CONTACT_NAME}`,
  BILL_TO_LOCATION_NAME: `${SYSTEM_OBJECT_BILL_TO}.${FIELD_LOCATION_NAME}`,
  BILL_TO_LOCATION_TYPE_DISPLAYED: `${SYSTEM_OBJECT_BILL_TO}.${
    SYSTEM_DROPDOWN_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // REMIT TO
  REMIT_TO_ZIP: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_ZIP}`,
  REMIT_TO_CITY: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_CITY}`,
  REMIT_TO_STATE: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_STATE}`,
  REMIT_TO_COUNTRY: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_COUNTRY}`,
  REMIT_TO_COMMENTS: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_COMMENTS}`,
  REMIT_TO_ADDRESS1: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_ADDRESS_1}`,
  REMIT_TO_PAYMENT_TERM: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_PAYMENT_TERM}`,
  REMIT_TO_LOCATION_NAME: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_LOCATION_NAME}`,
  REMIT_TO_LOCATION_TYPE_DISPLAYED: `${SYSTEM_OBJECT_REMIT_TO}.${
    SYSTEM_DROPDOWN_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // PRIMARY LOCATION
  PRIMARY_LOCATION_ZIP: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_ZIP}`,
  PRIMARY_LOCATION_CITY: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_CITY}`,
  PRIMARY_LOCATION_PHONE: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_PHONE}`,
  PRIMARY_LOCATION_EMAIL: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_EMAIL}`,
  PRIMARY_LOCATION_STATE: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_STATE}`,
  PRIMARY_LOCATION_EMAILS: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_EMAILS}`,
  PRIMARY_LOCATION_COUNTRY: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_COUNTRY}`,
  PRIMARY_LOCATION_PAYMENT_TERM: `${SYSTEM_OBJECT_REMIT_TO}.${FIELD_PAYMENT_TERM}`,
  PRIMARY_LOCATION_COMMENTS: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_COMMENTS}`,
  PRIMARY_LOCATION_ADDRESS1: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_ADDRESS_1}`,
  PRIMARY_LOCATION_ADDRESS2: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_ADDRESS_2}`,
  PRIMARY_LOCATION_EMAILS_FIELD: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_EMAILS_FIELD}`,
  PRIMARY_LOCATION_CONTACT_NAME: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_CONTACT_NAME}`,
  PRIMARY_LOCATION_LOCATION_NAME: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_LOCATION_NAME}`,
  PRIMARY_LOCATION_PHONE_EXTENSION: `${SYSTEM_OBJECT_PRIMARY_LOCATION}.${FIELD_PHONE_EXTENSION}`,
  // ROUTE
  ROUTE_NAME: `${SYSTEM_OBJECT_ROUTE}.${FIELD_NAME}`,
  CLO_ROUTE_NAME: `${FIELD_CLO}.${SYSTEM_OBJECT_ROUTE}.${FIELD_NAME}`,
  TEL_ROUTE_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_ROUTE}.${FIELD_NAME}`,
  // CUSTOM STATUS
  CUSTOM_STATUS_NAME: `${FIELD_CUSTOM_STATUS}.${FIELD_NAME}`,
  // DRIVER
  DRIVER_GUID: `${SYSTEM_OBJECT_DRIVER}.${FIELD_GUID}`,
  DRIVER_FULL_NAME: `${SYSTEM_OBJECT_DRIVER}.${FIELD_FULL_NAME}`,
  DRIVER_LAST_NAME: `${SYSTEM_OBJECT_DRIVER}.${FIELD_LAST_NAME}`,
  DRIVER_FIRST_NAME: `${SYSTEM_OBJECT_DRIVER}.${FIELD_FIRST_NAME}`,
  DRIVER_LOGIN_ID: `${SYSTEM_OBJECT_DRIVER}.${FIELD_USER_LOGIN_ID}`,
  DRIVER_TRUCK_UNIT_ID: `${SYSTEM_OBJECT_DRIVER}.${FIELD_TRUCK}.${FIELD_UNIT_ID}`,
  DRIVER_ASSIGNED_DIVISION_BRANCH_NAME: `${SYSTEM_OBJECT_DRIVER}.assignedToDivision.${BRANCH_NAME}`,
  // PAYROLL DRIVER
  PAYROLL_DRIVER_LAST_NAME: `${SYSTEM_OBJECT_PAYROLL}.${SYSTEM_OBJECT_DRIVER}.${FIELD_LAST_NAME}`,
  PAYROLL_DRIVER_FIRST_NAME: `${SYSTEM_OBJECT_PAYROLL}.${SYSTEM_OBJECT_DRIVER}.${FIELD_FIRST_NAME}`,
  PAYROLL_DRIVER_LOGIN_ID: `${SYSTEM_OBJECT_PAYROLL}.${SYSTEM_OBJECT_DRIVER}.${FIELD_USER_LOGIN_ID}`,
  // FLEET
  FLEET_TRAILER_GUID: `${FIELD_TRAILER}.${FIELD_GUID}`,
  FLEET_TRUCK_GUID: `${SYSTEM_OBJECT_TRUCK}.${FIELD_GUID}`,
  FLEET_TRAILER_UNIT_ID: `${FIELD_TRAILER}.${FIELD_UNIT_ID}`,
  FLEET_TRUCK_UNIT_ID: `${SYSTEM_OBJECT_TRUCK}.${FIELD_UNIT_ID}`,
  FLEET_ASSIGNMENT_CHASSIS_NUMBER: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${FIELD_CHASSIS_NUMBER}`,
  FLEET_ASSIGNMENT_TRUCK_UNIT_ID: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${FIELD_TRUCK}.${FIELD_UNIT_ID}`,
  FLEET_ASSIGNMENT_TRAILERS_UNIT_ID: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${FIELD_TRAILERS}.${FIELD_UNIT_ID}`,
  FLEET_ASSIGNMENT_PRIMARY_DRIVER_EMAIL: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_EMAIL}`,
  FLEET_ASSIGNMENT_PRIMARY_DRIVER_LOGIN_ID: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_USER_LOGIN_ID}`,
  FLEET_ASSIGNMENT_PRIMARY_DRIVER_DRIVER_TYPE: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_DRIVER_TYPE}`,
  FLEET_ASSIGNMENT_PRIMARY_DRIVER_LAST_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_LAST_NAME}`,
  FLEET_ASSIGNMENT_PRIMARY_DRIVER_FIRST_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_FIRST_NAME}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_EMAIL}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_USER_LOGIN_ID}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_DRIVER_TYPE: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_DRIVER_TYPE}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_FULL_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_FULL_NAME}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_LAST_NAME}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_FIRST_NAME}`,
  FLEET_ASSIGNMENT_DRAY_CONTAINER_DROP_NUMBER: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_DRAY_CONTAINER_DROP_NUMBER}`,
  FLEET_ASSIGNMENT_DRAY_CONTAINER_PICK_UP_NUMBER: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_DRAY_CONTAINER_PICK_UP_NUMBER}`,
  FLEET_ASSIGNMENT_CURRENT_TRAILERS_UNIT_ID: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_CURRENT_TRAILERS}.${FIELD_UNIT_ID}`,
  FLEET_ASSIGNMENT_PRIMARY_DRIVER_DISPATCHING_GROUP_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${SYSTEM_OBJECT_DISPATCHING_GROUP}.${FIELD_NAME}`,
  FLEET_ASSIGNMENT_SEC_DRIVER_DISPATCHING_GROUP_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${SYSTEM_OBJECT_DISPATCHING_GROUP}.${FIELD_NAME}`,
  // TELS FLEET ASSIGNMENT
  TELS_FLEET_ASSIGNMENT_CHASSIS_NUMBER: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_CHASSIS_NUMBER}`,
  TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_TRUCK}.${FIELD_UNIT_ID}`,
  TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_TRAILERS}.${FIELD_UNIT_ID}`,
  TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_EMAIL: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_EMAIL}`,
  TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_LOGIN_ID: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_USER_LOGIN_ID}`,
  TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_LAST_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_LAST_NAME}`,
  TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FIRST_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${FIELD_FIRST_NAME}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_EMAIL}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_USER_LOGIN_ID}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_LAST_NAME}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${FIELD_FIRST_NAME}`,
  TELS_FLEET_ASSIGNMENT_DRAY_CONTAINER_DROP_NUMBER: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_DRAY_CONTAINER_DROP_NUMBER}`,
  TELS_FLEET_ASSIGNMENT_DRAY_CONTAINER_PICK_UP_NUMBER: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_DRAY_CONTAINER_PICK_UP_NUMBER}`,
  TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_FULL_NAME}`,
  TELS_FLEET_ASSIGNMENT_SECONDARY_DRIVER_FULL_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_FULL_NAME}`,
  TELS_FLEET_ASSIGNMENT_PR_DRIVER_FLEET_VENDOR_MC_NUMBER: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_MC_NUMBER}`,
  TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_DISPATCHING_GROUP_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}.${SYSTEM_OBJECT_DISPATCHING_GROUP}.${FIELD_NAME}`,
  TELS_FLEET_ASSIGNMENT_SEC_DRIVER_DISPATCHING_GROUP_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}.${SYSTEM_OBJECT_DISPATCHING_GROUP}.${FIELD_NAME}`,
  // CARRIER
  CARRIER_GUID: `${SYSTEM_OBJECT_CARRIER}.${FIELD_GUID}`,
  CARRIER_NAME: `${SYSTEM_OBJECT_CARRIER}.${FIELD_CARRIER_NAME}`,
  CARRIER_ACTIVE: `${SYSTEM_OBJECT_CARRIER}.${FIELD_CARRIER_ACTIVE}`,
  CARRIER_MC_NUMBER: `${SYSTEM_OBJECT_CARRIER}.${FIELD_CARRIER_MC_NUMBER}`,
  CARRIER_USDOT_NUMBER: `${SYSTEM_OBJECT_CARRIER}.${FIELD_CARRIER_USDOT_NUMBER}`,
  CARRIER_BRANCH_NAME: `${SYSTEM_OBJECT_CARRIER}.${BRANCH_LOWERCASE}.${FIELD_BRANCH_NAME}`,
  // TEL COMMISSION ASSIGNEE
  TEL_BROKER_AGENTS_ID: `${FIELD_TEL}.${FIELD_BROKER_AGENTS}.${FIELD_ID}`,
  TEL_BROKER_AGENTS_NAME: `${FIELD_TEL}.${FIELD_BROKER_AGENTS}.${FIELD_NAME}`,
  TEL_CLOS_SALE_PERSONS_ID: `${FIELD_TEL}.${SYSTEM_LIST_CLOS}.${FIELD_SALE_PERSONS}.${FIELD_ID}`,
  TEL_CLOS_SALE_PERSONS_NAME: `${FIELD_TEL}.${SYSTEM_LIST_CLOS}.${
    FIELD_SALE_PERSONS}.${FIELD_NAME}`,
  // CARRIER ASSIGNMENT
  CARRIER_ASSIGNMENT_SCAC: `${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_CARRIER_SCAC}`,
  CARRIER_ASSIGNMENT_NAME: `${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_CARRIER_NAME}`,
  // TELS CARRIER ASSIGNMENT
  TELS_CARRIER_BOL_NUMBER: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${FIELD_BOL_NUMBER}`,
  TELS_CARRIER_PICKUP_NUMBER: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${FIELD_PICKUP_NUMBER}`,
  TELS_CARRIER_TRACKING_NUMBER: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${FIELD_TRACKING_NUMBER}`,
  TELS_CARRIER_SCAC: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_CARRIER_SCAC}`,
  TELS_CARRIER_NAME: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_CARRIER_NAME}`,
  // CARRIER_SNAPSHOT
  CARRIER_SNAPSHOT_SCAC: `${SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_CARRIER_SCAC}`,
  CARRIER_SNAPSHOT_NAME: `${SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_CARRIER_NAME}`,
  CARRIER_SNAPSHOT_MC_NUMBER: `${SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_CARRIER_MC_NUMBER}`,
  // SERVICE VENDOR
  SERVICE_VENDOR_NAME: `${SYSTEM_OBJECT_SERVICE_VENDOR}.${FIELD_NAME}`,
  // DROPDOWNS
  MODE_DISPLAYED_VALUE: `${SYSTEM_DROPDOWN_MODE}.${FIELD_DISPLAYED_VALUE}`,
  STATUS_DISPLAYED_VALUE: `${SYSTEM_DROPDOWN_STATUS}.${FIELD_DISPLAYED_VALUE}`,
  EQUIPMENT_DISPLAYED_VALUE: `${SYSTEM_DROPDOWN_EQUIPMENT}.${FIELD_DISPLAYED_VALUE}`,
  MODE_DROPDOWN_OPTION_GUID: `${SYSTEM_DROPDOWN_MODE}.${FIELD_DROPDOWN_OPTION_GUID}`,
  SERVICES_DROPDOWN_OPTION_GUID: `${FIELD_LOAD_SERVICES}.${FIELD_DROPDOWN_OPTION_GUID}`,
  SERVICE_TYPE_DISPLAYED_VALUE: `${SYSTEM_DROPDOWN_SERVICE_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  SERVICE_TYPE_DROPDOWN_OPTION_GUID: `${SYSTEM_DROPDOWN_SERVICE_TYPE}.${FIELD_DROPDOWN_OPTION_GUID}`,
  // INVOICE INTEGRATION
  ACCOUNTING_INTEGRATION_STATUS: `${SYSTEM_OBJECT_ACCOUNTING_INTEGRATION}.${FIELD_STATUS}`,
  ACCOUNTING_INTEGRATION_TYPE: `${SYSTEM_OBJECT_ACCOUNTING_INTEGRATION}.${FIELD_INTEGRATION_TYPE}`,
  ACCOUNTING_INTEGRATION_GL_POSTED_DATE: `${SYSTEM_OBJECT_ACCOUNTING_INTEGRATION}.${FIELD_GL_POSTED_DATE}`,
  ACCOUNTING_INTEGRATION_LAST_MODIFIED_BY: `${SYSTEM_OBJECT_ACCOUNTING_INTEGRATION}.${FIELD_LAST_MODIFIED_BY}`,
  //
  // INVOICE ASSIGMENT
  INVOICES_TEL_PRIMARY_REFERENCE_VALUE: `${SYSTEM_LIST_INVOICES}.${FIELD_TEL}.${
    SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_VALUE}`,
  INVOICES_CLO_PRIMARY_REFERENCE_VALUE: `${SYSTEM_LIST_INVOICES}.${FIELD_CLO}.${
    SYSTEM_OBJECT_PRIMARY_REFERENCE}.${FIELD_VALUE}`,
  // CLO
  CLO_GUID: `${FIELD_CLO}.${FIELD_GUID}`,
  CLO_PACKAGE_TYPE: `${FIELD_CLO}.${FIELD_PACKAGE_TYPE}`,
  CLO_QUANTITY: `${FIELD_CLO}.${FIELD_TOTAL_PICKUP_QUANTITY}`,
  CLO_EXPIRATION_DATE: `${FIELD_CLO}.${FIELD_EXPIRATION_DATE}`,
  CLO_BRANCH_GUID: `${FIELD_CLO}.${FIELD_BRANCH}.${FIELD_GUID}`,
  CLO_SALE_PERSONS_ID: `${FIELD_CLO}.${FIELD_SALE_PERSONS}.${FIELD_ID}`,
  CLO_SALE_PERSONS_NAME: `${FIELD_CLO}.${FIELD_SALE_PERSONS}.${FIELD_NAME}`,
  CLO_CONTAINERS_CONTAINER_NUMBER: `${FIELD_CLO}.${FIELD_CONTAINERS}.${FIELD_CONTAINER_NUMBER}`,
  CLO_CONTAINERS_CONTAINER_INITIAL: `${FIELD_CLO}.${FIELD_CONTAINERS}.${FIELD_CONTAINER_INITIAL}`,
  CLO_ORDER_TYPE_DISPLAYED_VALUE: `${FIELD_CLO}.${FIELD_LOAD_ORDER_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  CLO_CONTAINERS_CONTAINER_NUMBER_WITH_INITIAL: `${FIELD_CLO}.${FIELD_CONTAINERS}.${
    FIELD_CONTAINER_NUMBER_WITH_INITIAL}`,
  CLO_CARRIER_NAME: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_NAME}`,
  CLO_CARRIER_US_DOT_NUMBER: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_BRANCH_US_DOT_NUMBER}`,
  CLO_CARRIER_MC_NUMBER: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_BRANCH_MC_NUMBER}`,
  CLO_CARRIER_TRUCK: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${FIELD_TRUCK}`,
  CLO_CARRIER_TRAILER: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${FIELD_TRAILER}`,
  CLO_PRIMARY_DRIVER_FIRST_NAME: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_FIRST_NAME}`,
  CLO_PRIMARY_DRIVER_LAST_NAME: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_LAST_NAME}`,
  CLO_PRIMARY_DRIVER_LOGIN_ID: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_USER_LOGIN_ID}`,
  CLO_SECONDARY_DRIVER_FIRST_NAME: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_FIRST_NAME}`,
  CLO_SECONDARY_DRIVER_LAST_NAME: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_LAST_NAME}`,
  CLO_SECONDARY_DRIVER_LOGIN_ID: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_USER_LOGIN_ID}`,
  CLO_DRIVER_TRUCK: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${FIELD_TRUCK}.${
    FIELD_UNIT_ID}`,
  CLO_DRIVER_TRAILER: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${SYSTEM_LIST_TRAILERS}.${
    FIELD_UNIT_ID}`,
  CLO_STATUS: `${FIELD_CLO}.${FIELD_STATUS}`,
  CLO_CREATED_BY: `${FIELD_CLO}.${FIELD_CREATED_BY}`,
  CLO_CREATED_DATE: `${FIELD_CLO}.${FIELD_CREATED_DATE}`,
  CLO_TOTAL_SPENDING: `${FIELD_CLO}.${FIELD_TOTAL_SPENDING}`,
  CLO_BRANCH_NAME: `${FIELD_CLO}.${FIELD_BRANCH_DOT_BRANCH_NAME}`,
  CLO_STORED_TOTAL_WEIGHT: `${FIELD_CLO}.${FIELD_STORED_TOTAL_WEIGHT}`,
  CLO_DOCUMENT_TYPE: `${FIELD_CLO}.${FIELD_DOCUMENTS}.${FIELD_DOCUMENT_DOCUMENT_TYPE}.${
    FIELD_DISPLAYED_VALUE}`,
  CLO_PINNED_NOTE_TEXT: `${FIELD_CLO}.${FIELD_PINNED_NOTE}.${FIELD_TEXT}`,
  CLO_TELS_FLEET_VENDOR_INVOICES_PAY_TYPE: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${SYSTEM_LIST_FLEET_VENDOR_INVOICES}.${
    FIELD_PAY_TYPE}`,
  CLO_TELS_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME: `${FIELD_CLO}.${SYSTEM_LIST_TELS}.${FIELD_FLEET_ASSIGNMENT}.${
    FIELD_TRUCK}.${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_NAME}`,
  // CLO BILL TO
  CLO_BILL_TO_ZIP: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_ZIP}`,
  CLO_BILL_TO_CITY: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_CITY}`,
  CLO_BILL_TO_PHONE: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_PHONE}`,
  CLO_BILL_TO_STATE: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_STATE}`,
  CLO_BILL_TO_EMAILS: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_EMAILS}`,
  CLO_BILL_TO_COUNTRY: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_COUNTRY}`,
  CLO_BILL_TO_COMMENTS: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_COMMENTS}`,
  CLO_BILL_TO_ADDRESS1: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_ADDRESS_1}`,
  CLO_BILL_TO_PAYMENT_TERM: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_PAYMENT_TERM}`,
  CLO_BILL_TO_CONTACT_NAME: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_CONTACT_NAME}`,
  CLO_BILL_TO_EMAILS_FIELD: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_EMAILS_FIELD}`,
  CLO_BILL_TO_LOCATION_NAME: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_LOCATION_NAME}`,
  CLO_BILL_TO_LOCATION_TYPE_DISPLAYED: `${FIELD_CLO}.${SYSTEM_OBJECT_BILL_TO}.${
    SYSTEM_DROPDOWN_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // DRIVER PAYROLL
  DRIVER_PAYROLL_NUMBER: `${SYSTEM_OBJECT_DRIVER_PAYROLL}.${FIELD_PAYROLL_NUMBER}`,
  // CLO LOCATION EVENTS
  CLO_FIRST_EVENT_LATE_DATE: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_LATE_DATE}`,
  CLO_FIRST_EVENT_EARLY_DATE: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EARLY_DATE}`,
  CLO_EVENTS_LOCATION_STATE: `${FIELD_CLO}.${FIELD_LOAD_STOPS}.${SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  CLO_FIRST_EVENT_COMPLETE_DATE: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  CLO_FIRST_EVENT_EVENT_EARLY_DATE: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_LOAD_EVENT_EARLY_DATE}`,
  CLO_EVENTS_LOCATION_NAME: `${FIELD_CLO}.${FIELD_LOAD_STOPS}.${SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  CLO_FIRST_EVENT_LOCATION_ZIP: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  CLO_FIRST_EVENT_LOCATION_CITY: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  CLO_FIRST_EVENT_LOCATION_STATE: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  CLO_FIRST_EVENT_LOCATION_COUNTRY: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  CLO_FIRST_EVENT_LOCATION_COMMENTS: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_COMMENTS}`,
  CLO_FIRST_EVENT_LOCATION_NAME: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  CLO_FIRST_EVENT_LOCATION_ADDRESS1: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  CLO_FIRST_EVENT_LOCATION_ADDRESS2: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  CLO_FIRST_EVENT_LOCATION_TEMPLATE_ID: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  CLO_FIRST_EVENT_LOCATION_TYPE: `${FIELD_CLO}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  CLO_LAST_EVENT_LATE_DATE: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_LATE_DATE}`,
  CLO_LAST_EVENT_EARLY_DATE: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EARLY_DATE}`,
  CLO_LAST_EVENT_COMPLETE_DATE: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  CLO_LAST_EVENT_EVENT_EARLY_DATE: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_LOAD_EVENT_EARLY_DATE}`,
  CLO_LAST_EVENT_LOCATION_ZIP: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  CLO_LAST_EVENT_LOCATION_CITY: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  CLO_LAST_EVENT_LOCATION_STATE: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  CLO_LAST_EVENT_LOCATION_COUNTRY: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  CLO_LAST_EVENT_LOCATION_COMMENTS: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_COMMENTS}`,
  CLO_LAST_EVENT_LOCATION_NAME: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  CLO_LAST_EVENT_LOCATION_ADDRESS1: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  CLO_LAST_EVENT_LOCATION_ADDRESS2: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  CLO_LAST_EVENT_LOCATION_TEMPLATE_ID: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  CLO_LAST_EVENT_LOCATION_TYPE: `${FIELD_CLO}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // VENDOR PAYROLL
  VENDOR_PAYROLL_NUMBER: `${SYSTEM_OBJECT_FLEET_VENDOR_PAYROLL}.${FIELD_PAYROLL_NUMBER}`,
  // TEL
  TEL_GUID: `${FIELD_TEL}.${FIELD_GUID}`,
  TEL_MARGIN: `${FIELD_TEL}.${FIELD_MARGIN}`,
  TEL_TOTAL_INCOME: `${FIELD_TEL}.${TOTAL_INCOME}`,
  TEL_STORED_TOTAL_DISTANCE: `${FIELD_TEL}.${FIELD_STORED_TOTAL_DISTANCE}`,
  TEL_CLOS_BRANCH_NAME: `${FIELD_TEL}.${SYSTEM_LIST_CLOS}.${FIELD_BRANCH_DOT_BRANCH_NAME}`,
  TEL_CLOS_INVOICE_STATUS: `${FIELD_TEL}.${SYSTEM_LIST_CLOS}.${SYSTEM_LIST_INVOICES
    }.${FIELD_STATUS}.${FIELD_DISPLAYED_VALUE}`,
  TEL_RATE_STORED_DEADHEAD_DISTANCE: `${FIELD_TEL}.${SYSTEM_OBJECT_RATE}.${FIELD_STORED_DEADHEAD_DISTANCE}`,
  // TELS
  TELS_GUID: `${SYSTEM_LIST_TELS}.${FIELD_GUID}`,
  TELS_STATUS: `${SYSTEM_LIST_TELS}.${FIELD_STATUS}`,
  // TEL DOCUMENTS
  TEL_DOCUMENTS_DOCUMENT_TYPE_DISPLAYED_VALUE: `${FIELD_TEL}.${FIELD_DOCUMENTS}.${
    FIELD_DOCUMENT_DOCUMENT_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // TEL LOCATION EVENTS
  TEL_FIRST_EVENT_LATE_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_LATE_DATE}`,
  TEL_FIRST_EVENT_EARLY_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EARLY_DATE}`,
  TEL_FIRST_EVENT_APPOINTMENT_EARLY_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    FIELD_APPOINTMENT_EARLY_DATE}`,
  TEL_FIRST_EVENT_APPOINTMENT_LATE_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    FIELD_APPOINTMENT_LATE_DATE}`,
  TEL_FIRST_EVENT_COMPLETE_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  TEL_FIRST_EVENT_LOCATION_ZIP: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  TEL_FIRST_EVENT_LOCATION_CITY: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  TEL_FIRST_EVENT_LOCATION_STATE: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  TEL_FIRST_EVENT_LOCATION_COUNTRY: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  TEL_FIRST_EVENT_LOCATION_COMMENTS: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_COMMENTS}`,
  TEL_FIRST_EVENT_LOCATION_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  TEL_FIRST_EVENT_LOCATION_ADDRESS1: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  TEL_FIRST_EVENT_LOCATION_ADDRESS2: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  TEL_FIRST_EVENT_LOCATION_TEMPLATE_ID: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  TEL_FIRST_EVENT_LOCATION_TYPE: `${FIELD_TEL}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  TEL_LAST_EVENT_LATE_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_LATE_DATE}`,
  TEL_LAST_EVENT_EARLY_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EARLY_DATE}`,
  TEL_LAST_EVENT_COMPLETE_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  TEL_LAST_EVENT_APPOINTMENT_EARLY_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    FIELD_APPOINTMENT_EARLY_DATE}`,
  TEL_LAST_EVENT_APPOINTMENT_LATE_DATE: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    FIELD_APPOINTMENT_LATE_DATE}`,
  TEL_LAST_EVENT_LOCATION_ZIP: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  TEL_LAST_EVENT_LOCATION_CITY: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  TEL_LAST_EVENT_LOCATION_STATE: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  TEL_LAST_EVENT_LOCATION_COUNTRY: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  TEL_LAST_EVENT_LOCATION_COMMENTS: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_COMMENTS}`,
  TEL_LAST_EVENT_LOCATION_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  TEL_LAST_EVENT_LOCATION_ADDRESS1: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  TEL_LAST_EVENT_LOCATION_ADDRESS2: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  TEL_LAST_EVENT_LOCATION_TEMPLATE_ID: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  TEL_LAST_EVENT_LOCATION_TYPE: `${FIELD_TEL}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // LOAD LOCATION EVENTS
  FIRST_EVENT_LATE_DATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_LATE_DATE}`,
  FIRST_EVENT_EARLY_DATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EARLY_DATE}`,
  FIRST_EVENT_PICKUP_NUMBER: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_STOP_NUMBER}`,
  FIRST_EVENT_COMPLETE_DATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  FIRST_EVENT_CHECK_IN_DATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EVENT_CHECK_IN_DATE}`,
  FIRST_EVENT_EVENT_LATE_DATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_LOAD_EVENT_LATE_DATE}`,
  FIRST_EVENT_EVENT_EARLY_DATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_LOAD_EVENT_EARLY_DATE}`,
  FIRST_EVENT_LOCATION_ZIP: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  FIRST_EVENT_LOCATION_CITY: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  FIRST_EVENT_APPOINTMENT_EARLY_DATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_APPOINTMENT_EARLY_DATE}`,
  FIRST_EVENT_LOCATION_STATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  FIRST_EVENT_LOCATION_COUNTRY: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  FIRST_EVENT_LOCATION_COMMENTS: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_COMMENTS}`,
  FIRST_EVENT_LOCATION_NAME: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  FIRST_EVENT_LOCATION_ADDRESS1: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  FIRST_EVENT_LOCATION_ADDRESS2: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  FIRST_EVENT_LOCATION_TEMPLATE_ID: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  FIRST_EVENT_LOCATION_TYPE: `${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  NEXT_EVENT_LATE_DATE: `${SYSTEM_OBJECT_NEXT_EVENT}.${FIELD_LATE_DATE}`,
  NEXT_EVENT_EARLY_DATE: `${SYSTEM_OBJECT_NEXT_EVENT}.${FIELD_EARLY_DATE}`,
  NEXT_EVENT_COMPLETE_DATE: `${SYSTEM_OBJECT_NEXT_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  NEXT_EVENT_CHECK_IN_DATE: `${SYSTEM_OBJECT_NEXT_EVENT}.${FIELD_EVENT_CHECK_IN_DATE}`,
  NEXT_EVENT_LOCATION_ZIP: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  NEXT_EVENT_LOCATION_CITY: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  NEXT_EVENT_APPOINTMENT_EARLY_DATE: `${SYSTEM_OBJECT_NEXT_EVENT}.${FIELD_APPOINTMENT_EARLY_DATE}`,
  NEXT_EVENT_LOCATION_STATE: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  NEXT_EVENT_LOCATION_COUNTRY: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  NEXT_EVENT_LOCATION_NAME: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  NEXT_EVENT_LOCATION_ADDRESS1: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  NEXT_EVENT_LOCATION_ADDRESS2: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  NEXT_EVENT_LOCATION_TEMPLATE_ID: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_TEMPLATE_ID}`,
  NEXT_EVENT_LOCATION_LOCATION_TYPE: `${SYSTEM_OBJECT_NEXT_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  LAST_EVENT_LATE_DATE: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_LATE_DATE}`,
  LAST_EVENT_EARLY_DATE: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EARLY_DATE}`,
  LAST_EVENT_CHECK_IN_DATE: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EVENT_CHECK_IN_DATE}`,
  LAST_EVENT_COMPLETE_DATE: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  LAST_EVENT_EVENT_LATE_DATE: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_LOAD_EVENT_LATE_DATE}`,
  LAST_EVENT_EVENT_EARLY_DATE: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_LOAD_EVENT_EARLY_DATE}`,
  LAST_EVENT_LOCATION_ZIP: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  LAST_EVENT_LOCATION_CITY: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  LAST_EVENT_APPOINTMENT_EARLY_DATE: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_APPOINTMENT_EARLY_DATE}`,
  LAST_EVENT_LOCATION_STATE: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  LAST_EVENT_LOCATION_COUNTRY: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  LAST_EVENT_LOCATION_COMMENTS: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_COMMENTS}`,
  LAST_EVENT_LOCATION_NAME: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_NAME}`,
  LAST_EVENT_LOCATION_ADDRESS1: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_1}`,
  LAST_EVENT_LOCATION_ADDRESS2: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS_2}`,
  LAST_EVENT_LOCATION_TEMPLATE_ID: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  LAST_EVENT_LOCATION_TYPE: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  FIRST_EVENT_LOCATION_LOCATION_TYPE: `${SYSTEM_OBJECT_FIRST_EVENT}.${
    SYSTEM_OBJECT_LOCATION}.${FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  LAST_EVENT_LOCATION_LOCATION_TYPE: `${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // arrived date
  FIRST_EVENT_ARRIVE_DATE: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_ARRIVE_DATE}`,
  LAST_EVENT_ARRIVE_DATE: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_ARRIVE_DATE}`,
  NEXT_EVENT_ARRIVE_DATE: `${SYSTEM_OBJECT_NEXT_EVENT}.${FIELD_ARRIVE_DATE}`,
  // check in date
  FIRST_EVENT_CHECK_IN: `${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EVENT_CHECK_IN_DATE}`,
  LAST_EVENT_CHECK_IN: `${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EVENT_CHECK_IN_DATE}`,
  NEXT_EVENT_CHECK_IN: `${SYSTEM_OBJECT_NEXT_EVENT}.${FIELD_EVENT_CHECK_IN_DATE}`,
  // OTHER
  TEL_STATUS: `${FIELD_TEL}.${FIELD_STATUS}`,
  SERVICES: `${FIELD_LOAD_SERVICES}.${FIELD_DISPLAYED_VALUE}`,
  PINNED_NOTE_TEXT: `${SYSTEM_OBJECT_PINNED_NOTE}.${FIELD_TEXT}`,
  EQUIPMENTS: `${FIELD_LOAD_EQUIPMENT}.${FIELD_DISPLAYED_VALUE}`,
  GRANTED_ENTERPRISES_NAME: `${SYSTEM_LIST_GRANTED_ENTERPRISES}.${BRANCH_NAME}`,
  INBOUND_TERMINALS: `${FIELD_TERMINAL_DROPS}.${SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  PINNED_NOTE_TYPE: `${SYSTEM_OBJECT_PINNED_NOTE}.${FIELD_NOTE_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  OUTBOUND_TERMINALS: `${FIELD_TERMINAL_PICKUPS}.${SYSTEM_OBJECT_LOCATION}.${FIELD_TEMPLATE_ID}`,
  // ITEMS
  ITEMS_STORED_WEIGHT: `${SYSTEM_LIST_ITEMS}.${FIELD_STORED_WEIGHT}`,
  ITEMS_TEMPERATURE_TYPE: `${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_TEMPERATURE_TYPE}`,
  ITEMS_TEMPERATURE_SENSOR: `${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_TEMPERATURE_SENSOR}`,
  ITEMS_ITEM_HAZMAT_FLASHPOINT_UOM: `${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_HAZMAT_FLASHPOINT_UOM}`,
  EVENTS_ITEMS_TEMPERATURE_SENSOR: `${FIELD_LOAD_STOPS}.${FIELD_LOAD_ITEMS}.${FIELD_ITEM_TEMPERATURE_SENSOR}`,
  // hazmat
  HAZMAT_ITEMS_ITEM_ID: `${SYSTEM_LIST_HAZMAT_ITEMS}.${FIELD_ITEM_ID}`,
  HAZMAT_ITEMS_STORED_WEIGHT: `${SYSTEM_LIST_HAZMAT_ITEMS}.${FIELD_STORED_WEIGHT}`,
  // non hazmat
  NON_HAZMAT_ITEMS_ITEM_ID: `${SYSTEM_LIST_NON_HAZMAT_ITEMS}.${FIELD_ITEM_ID}`,
  NON_HAZMAT_ITEMS_STORED_WEIGHT: `${SYSTEM_LIST_NON_HAZMAT_ITEMS}.${FIELD_STORED_WEIGHT}`,
  // ITEMS INFO
  ITEMS_INFO_COUNT: `${SYSTEM_OBJECT_ITEMS_INFO}.${FIELD_COUNT}`,
  ITEMS_INFO_WEIGHT: `${SYSTEM_OBJECT_ITEMS_INFO}.${FIELD_WEIGHT}`,
  ITEMS_INFO_QUANTITY: `${SYSTEM_OBJECT_ITEMS_INFO}.${FIELD_QUANTITY}`,
  ITEMS_INFO_ANY_HAZARDOUS: `${SYSTEM_OBJECT_ITEMS_INFO}.${FIELD_ANY_HAZARDOUS}`,
  ITEMS_INFO_TEMPERATURE_LOW: `${SYSTEM_OBJECT_ITEMS_INFO}.${FIELD_ITEM_TEMPERATURE_LOW}`,
  ITEMS_INFO_TEMPERATURE_HIGH: `${SYSTEM_OBJECT_ITEMS_INFO}.${FIELD_ITEM_TEMPERATURE_HIGH}`,
  // ITEMS VEHICLE
  ITEMS_VIN: `${FIELD_LOAD_ITEMS}.${FIELD_VIN}`,
  ITEMS_BAY: `${FIELD_LOAD_ITEMS}.${FIELD_BAY}`,
  ITEMS_YEAR: `${FIELD_LOAD_ITEMS}.${FIELD_YEAR}`,
  ITEMS_MAKE: `${FIELD_LOAD_ITEMS}.${FIELD_MAKE}`,
  ITEMS_MODEL: `${FIELD_LOAD_ITEMS}.${FIELD_MODEL}`,
  ITEMS_COLOR: `${FIELD_LOAD_ITEMS}.${FIELD_COLOR}`,
  ITEMS_ITEM_ID: `${FIELD_LOAD_ITEMS}.${FIELD_ITEM_ID}`,
  ITEMS_ODOMETER: `${FIELD_LOAD_ITEMS}.${FIELD_ODOMETER}`,
  ITEMS_WHEELBASE: `${FIELD_LOAD_ITEMS}.${FIELD_WHEELBASE}`,
  ITEMS_ITEM_TYPE: `${FIELD_LOAD_ITEMS}.${FIELD_ITEM_TYPE}`,
  ITEMS_SUB_MODEL: `${FIELD_LOAD_ITEMS}.${FIELD_SUB_MODEL}`,
  ITEMS_ORIGIN_STATE: `${FIELD_LOAD_ITEMS}.${FIELD_ORIGIN_STATE}`,
  ITEMS_ODOMETER_UOM: `${FIELD_LOAD_ITEMS}.${FIELD_ODOMETER_UOM}`,
  ITEMS_MANUFACTURER: `${FIELD_LOAD_ITEMS}.${FIELD_MANUFACTURER}`,
  ITEMS_WHEELBASE_UOM: `${FIELD_LOAD_ITEMS}.${FIELD_WHEELBASE_UOM}`,
  ITEMS_LICENSE_PLATE: `${FIELD_LOAD_ITEMS}.${FIELD_LICENSE_PLATE}`,
  ITEMS_ORIGIN_COUNTRY: `${FIELD_LOAD_ITEMS}.${FIELD_ORIGIN_COUNTRY}`,
  // DISPATCHING GROUP
  DISPATCHING_GROUPS_NAME: `${SYSTEM_LIST_DISPATCHING_GROUP}.${FIELD_NAME}`,
  DISPATCHING_GROUP_NAME: `${SYSTEM_OBJECT_DISPATCHING_GROUP}.${FIELD_NAME}`,
  // ASSIGNED DISPATCHER
  ASSIGNED_DISPATCHER_LAST_NAME: `${SYSTEM_OBJECT_ASSIGNED_DISPATCHER}.${FIELD_LAST_NAME}`,
  ASSIGNED_DISPATCHER_FIRST_NAME: `${SYSTEM_OBJECT_ASSIGNED_DISPATCHER}.${FIELD_FIRST_NAME}`,
  ASSIGNED_DISPATCHER_LOGIN_ID: `${SYSTEM_OBJECT_ASSIGNED_DISPATCHER}.${FIELD_USER_LOGIN_ID}`,
  // FLEET VENDOR
  FLEET_VENDOR_GUID: `${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_GUID}`,
  FLEET_VENDOR_NAME: `${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_NAME}`,
  FLEET_VENDOR_OWNER_NAME: `${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_OWNER_NAME}`,
  FLEET_VENDOR_TARGET_RPM: `${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_TARGET_RPM}`,
  FLEET_VENDOR_BRANCH_NAME: `${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_BRANCH}.${FIELD_BRANCH_NAME}`,
  // VENDOR
  VENDOR_GUID: `${SYSTEM_OBJECT_VENDOR}.${FIELD_GUID}`,
  VENDOR_OWNER_NAME: `${SYSTEM_OBJECT_VENDOR}.${FIELD_OWNER_NAME}`,
  FLEET_VENDOR_DBA: `${SYSTEM_OBJECT_VENDOR}.${FIELD_CARRIER_DBA}`,
  FLEET_VENDOR_COMPANY_NAME: `${SYSTEM_OBJECT_VENDOR}.${FIELD_NAME}`,
  FLEET_VENDOR_MC_NUMBER: `${SYSTEM_OBJECT_VENDOR}.${FIELD_MC_NUMBER}`,
  FLEET_VENDOR_DUNS_NUMBER: `${SYSTEM_OBJECT_VENDOR}.${FIELD_DUNS_NUMBER}`,
  FLEET_VENDOR_US_DOT_NUMBER: `${SYSTEM_OBJECT_VENDOR}.${FIELD_US_DOT_NUMBER}`,
  FLEET_VENDOR_ACCOUNT_NUMBER: `${SYSTEM_OBJECT_VENDOR}.${FIELD_ACCOUNT_NUMBER}`,
  // DRIVER FLEET VENDOR
  DRIVER_FLEET_VENDOR_NAME: `${SYSTEM_OBJECT_DRIVER}.${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_NAME}`,
  // PAYROLL FLEET VENDOR
  PAYROLL_FLEET_VENDOR_COMPANY_NAME: `${SYSTEM_OBJECT_PAYROLL}.${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_NAME}`,
  PAYROLL_FLEET_VENDOR_MC_NUMBER: `${SYSTEM_OBJECT_PAYROLL}.${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_MC_NUMBER}`,
  PAYROLL_FLEET_VENDOR_OWNER_NAME: `${SYSTEM_OBJECT_PAYROLL}.${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_OWNER_NAME}`,
  PAYROLL_FLEET_VENDOR_ACCOUNT_NUMBER: `${SYSTEM_OBJECT_PAYROLL}.${SYSTEM_OBJECT_FLEET_VENDOR}.${
    FIELD_ACCOUNT_NUMBER}`,
  // TRAILER OWNERSHIP
  // TODO: check is it correct field name
  TRAILER_OWNERSHIP_CONTRACT_NAME: `${SYSTEM_OBJECT_TRAILER_OWNERSHIP}.contractName`,
  // PARENT
  PARENT_BRANCH_NAME: `${SYSTEM_OBJECT_PARENT}.${BRANCH_NAME}`,
  // EDI
  EDI_CLO_STATUS: `${FIELD_CLO}.${FIELD_STATUS}`,
  EDI_CREATED_CLO_GUID: `${FIELD_CREATED_CLO}.${FIELD_GUID}`,
  EDI_CREATED_CLO_STATUS: `${FIELD_CREATED_CLO}.${FIELD_STATUS}`,
  EDI_CLO_TRANSACTION_ACTION: `${FIELD_CLO}.${FIELD_EDI_TRANSACTION_ACTION}`,
  EDI_CLO_PRIMARY_REFERENCE_VALUE: `${FIELD_CLO}.${FIELD_PRIMARY_REFERENCE_VALUE}`,
  EDI_CREATED_CLO_PRIMARY_REFERENCE_VALUE: `${FIELD_CREATED_CLO}.${FIELD_PRIMARY_REFERENCE_VALUE}`,
  // CARRIER EDI
  TEL_INFO_TEL_GUID: `${SYSTEM_OBJECT_TEL_INFO}.${FIELD_TEL_GUID}`,
  CREATED_INVOICE_TEL_GUID: `${FIELD_CREATED_INVOICE}.${FIELD_TEL_GUID}`,
  CREATED_INVOICE_INVOICE_GUID: `${FIELD_CREATED_INVOICE}.${FIELD_GUID}`,
  TEL_INFO_TEL_PRIMARY_REFERENCE_VALUE: `${SYSTEM_OBJECT_TEL_INFO}.${FIELD_TEL_PRIMARY_REFERENCE_VALUE}`,
  CREATED_INVOICE_TEL_PRIMARY_REFERENCE_VALUE: `${FIELD_CREATED_INVOICE}.${FIELD_TEL_PRIMARY_REFERENCE_VALUE}`,
  // invoice
  INVOICE_INVOICE_TOTAL: `${SYSTEM_OBJECT_INVOICE}.${FIELD_TOTAL}`,
  INVOICE_INVOICE_DATE: `${SYSTEM_OBJECT_INVOICE}.${FIELD_INVOICE_DATE}`,
  INVOICE_INVOICE_NET_DAYS: `${SYSTEM_OBJECT_INVOICE}.${FIELD_NET_DAYS}`,
  INVOICE_INVOICE_COMMENTS: `${SYSTEM_OBJECT_INVOICE}.${FIELD_COMMENTS}`,
  INVOICE_INVOICE_CURRENCY: `${SYSTEM_OBJECT_INVOICE}.${FIELD_CURRENCY}`,
  INVOICE_INVOICE_PO_NUMBER: `${SYSTEM_OBJECT_INVOICE}.${FIELD_PO_NUMBER}`,
  INVOICE_INVOICE_NUMBER: `${SYSTEM_OBJECT_INVOICE}.${FIELD_INVOICE_NUMBER}`,
  INVOICE_INVOICE_CHECK_NUMBER: `${SYSTEM_OBJECT_INVOICE}.${FIELD_CHECK_NUMBER}`,
  INVOICE_INVOICE_CHECK_AMOUNT: `${SYSTEM_OBJECT_INVOICE}.${FIELD_CHECK_AMOUNT}`,
  INVOICE_INVOICE_CHECK_DATE: `${SYSTEM_OBJECT_INVOICE}.${FIELD_INVOICE_CHECK_DATE}`,
  INVOICE_INVOICE_DEPOSIT_DATE: `${SYSTEM_OBJECT_INVOICE}.${FIELD_INVOICE_DEPOSIT_DATE}`,
  INVOICE_INVOICE_PAYMENT_DUE_DATE: `${SYSTEM_OBJECT_INVOICE}.${FIELD_INVOICE_PAYMENT_DUE_DATE}`,
  INVOICE_INVOICE_STATUS: `${SYSTEM_OBJECT_INVOICE}.${FIELD_STATUS}.${FIELD_DISPLAYED_VALUE}`,
  INVOICE_INVOICE_GL_CODE: `${SYSTEM_OBJECT_INVOICE}.${FIELD_GL_CODE}.${FIELD_DISPLAYED_VALUE}`,
  INVOICE_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER:
    `${SYSTEM_OBJECT_INVOICE}.${FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER}`,
  INVOICE_NORMALIZED_TOTAL: `${SYSTEM_OBJECT_INVOICE}.${SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_TOTAL}`,
  INVOICE_NORMALIZED_TOTAL_CURRENCY: `${SYSTEM_OBJECT_INVOICE}.${SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_CURRENCY}`,
  // carrier edi status messages
  STATUS_MESSAGES_DATE_TIME: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${FIELD_DATE_TIME}`,
  STATUS_MESSAGES_STATUS_CODE: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${FIELD_STATUS_CODE}`,
  STATUS_MESSAGES_STOP_SEQUENCE_NUMBER: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${FIELD_STOP_SEQUENCE_NUMBER}`,
  STATUS_MESSAGES_LOCATION_ZIP: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ZIP}`,
  STATUS_MESSAGES_LOCATION_CITY: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${SYSTEM_OBJECT_LOCATION}.${FIELD_CITY}`,
  STATUS_MESSAGES_STATUS_REASON_CODE: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${FIELD_STATUS_MESSAGE_REASON_CODE}`,
  STATUS_MESSAGES_LOCATION_STATE: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${SYSTEM_OBJECT_LOCATION}.${FIELD_STATE}`,
  STATUS_MESSAGES_LOCATION_COUNTRY: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${SYSTEM_OBJECT_LOCATION}.${FIELD_COUNTRY}`,
  STATUS_MESSAGES_LOCATION_ADDRESS: `${SYSTEM_OBJECT_STATUS_MESSAGES}.${SYSTEM_OBJECT_LOCATION}.${FIELD_ADDRESS}`,
  // broker agents
  BROKER_AGENTS_ID: `${FIELD_BROKER_AGENTS}.${FIELD_ID}`,
  BROKER_AGENTS_NAME: `${FIELD_BROKER_AGENTS}.${FIELD_NAME}`,
  TELS_BROKER_AGENTS_ID: `${SYSTEM_LIST_TELS}.${FIELD_BROKER_AGENTS}.${FIELD_ID}`,
  TELS_BROKER_AGENTS_NAME: `${SYSTEM_LIST_TELS}.${FIELD_BROKER_AGENTS}.${FIELD_NAME}`,
  // tel sale representatives
  TEL_SALE_REPRESENTATIVES_ID: `${FIELD_TEL_SALE_REPRESENTATIVES}.${FIELD_ID}`,
  TEL_SALE_REPRESENTATIVES_NAME: `${FIELD_TEL_SALE_REPRESENTATIVES}.${FIELD_NAME}`,
  TELS_SALE_REPRESENTATIVES_ID: `${SYSTEM_LIST_TELS}.${FIELD_SALE_REPRESENTATIVES}.${FIELD_ID}`,
  TELS_SALE_REPRESENTATIVES_NAME: `${SYSTEM_LIST_TELS}.${FIELD_SALE_REPRESENTATIVES}.${FIELD_NAME}`,
  // carrier representatives
  CARRIER_REPRESENTATIVES_ID: `${FIELD_CARRIER_REPRESENTATIVES}.${FIELD_ID}`,
  CARRIER_REPRESENTATIVES_NAME: `${FIELD_CARRIER_REPRESENTATIVES}.${FIELD_NAME}`,
  TELS_CARRIER_REPRESENTATIVES_ID: `${SYSTEM_LIST_TELS}.${FIELD_CARRIER_REPRESENTATIVES}.${FIELD_ID}`,
  TELS_CARRIER_REPRESENTATIVES_NAME: `${SYSTEM_LIST_TELS}.${FIELD_CARRIER_REPRESENTATIVES}.${FIELD_NAME}`,
  // sale persons
  SALE_PERSONS_ID: `${FIELD_SALE_PERSONS}.${FIELD_ID}`,
  SALE_PERSONS_NAME: `${FIELD_SALE_PERSONS}.${FIELD_NAME}`,
  CLOS_SALE_PERSONS_ID: `${SYSTEM_LIST_CLOS}.${FIELD_SALE_PERSONS}.${FIELD_ID}`,
  CLOS_SALE_PERSONS_NAME: `${SYSTEM_LIST_CLOS}.${FIELD_SALE_PERSONS}.${FIELD_NAME}`,
  // sale representatives
  SALE_REPRESENTATIVES_ID: `${FIELD_SALE_REPRESENTATIVES}.${FIELD_ID}`,
  SALE_REPRESENTATIVES_NAME: `${FIELD_SALE_REPRESENTATIVES}.${FIELD_NAME}`,
  // clo sale representatives
  CLO_SALE_REPRESENTATIVES_ID: `${FIELD_CLO_SALE_REPRESENTATIVES}.${FIELD_ID}`,
  CLO_SALE_REPRESENTATIVES_NAME: `${FIELD_CLO_SALE_REPRESENTATIVES}.${FIELD_NAME}`,
  CLOS_SALE_REPRESENTATIVES_ID: `${SYSTEM_LIST_CLOS}.${FIELD_SALE_REPRESENTATIVES}.${FIELD_ID}`,
  CLOS_SALE_REPRESENTATIVES_NAME: `${SYSTEM_LIST_CLOS}.${FIELD_SALE_REPRESENTATIVES}.${FIELD_NAME}`,
  // account managers
  ACCOUNT_MANAGERS_ID: `${FIELD_ACCOUNT_MANAGERS}.${FIELD_ID}`,
  ACCOUNT_MANAGERS_NAME: `${FIELD_ACCOUNT_MANAGERS}.${FIELD_NAME}`,
  CLOS_ACCOUNT_MANAGERS_ID: `${SYSTEM_LIST_CLOS}.${FIELD_ACCOUNT_MANAGERS}.${FIELD_ID}`,
  CLOS_ACCOUNT_MANAGERS_NAME: `${SYSTEM_LIST_CLOS}.${FIELD_ACCOUNT_MANAGERS}.${FIELD_NAME}`,
  // clo rate
  CLOS_RATE_MODE: `${SYSTEM_LIST_CLOS}.${SYSTEM_OBJECT_RATE}.${FIELD_MODE}.${FIELD_DISPLAYED_VALUE}`,
  // clos
  CLOS_STATUS: `${SYSTEM_LIST_CLOS}.${FIELD_STATUS}`,
  CLOS_ITEMS_FREIGHT_CLASS: `${SYSTEM_LIST_CLOS}.${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_FREIGHT_CLASS}`,
  CLOS_INVOICES_STATUS: `${SYSTEM_LIST_CLOS}.${SYSTEM_LIST_INVOICES}.${FIELD_STATUS}.${FIELD_DISPLAYED_VALUE}`,
  CLOS_INVOICES_DEPOSIT_DATE: `${SYSTEM_LIST_CLOS}.${SYSTEM_LIST_INVOICES}.${FIELD_PAYMENTS}.${FIELD_DEPOSIT_DATE}`,
  CLOS_INVOICES_CHECK_DATE: `${SYSTEM_LIST_CLOS}.${SYSTEM_LIST_INVOICES}.${FIELD_PAYMENTS}.${FIELD_INVOICE_CHECK_DATE}`,
  // tel rate
  TEL_RATE_GUID: `${FIELD_TEL}.${SYSTEM_OBJECT_RATE}.${FIELD_GUID}`,
  TEL_RATE_TOTAL: `${FIELD_TEL}.${SYSTEM_OBJECT_RATE}.${FIELD_TOTAL}`,
  TEL_RATE_MAIN_CHARGES_TOTAL: `${FIELD_TEL}.${SYSTEM_OBJECT_RATE}.${FIELD_MAIN_CHARGES_TOTAL}`,
  TEL_RATE_FUEL_CHARGES_TOTAL: `${FIELD_TEL}.${SYSTEM_OBJECT_RATE}.${FIELD_FUEL_CHARGES_TOTAL}`,
  TELS_RATE_MODE: `${SYSTEM_LIST_TELS}.${SYSTEM_OBJECT_RATE}.${FIELD_MODE}.${FIELD_DISPLAYED_VALUE}`,
  TEL_RATE_ADDITIONAL_CHARGES_TOTAL: `${FIELD_TEL}.${SYSTEM_OBJECT_RATE}.${FIELD_ADDITIONAL_CHARGES_TOTAL}`,
  // tel carrier rate
  TEL_CARRIER_RATE_CARRIER_NAME: `${SYSTEM_OBJECT_TEL_CARRIER_RATE}.${
    SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${SYSTEM_OBJECT_CARRIER_SNAPSHOT}.${FIELD_NAME}`,
  // certification
  CERTIFICATION_DATE: `${FIELD_CERTIFICATIONS}.${FIELD_CERTIFICATION_DATE}`,
  CERTIFICATION_NOTES: `${FIELD_CERTIFICATIONS}.${FIELD_CERTIFICATION_NOTES}`,
  CERTIFICATION_OOS_ITEM: `${FIELD_CERTIFICATIONS}.${FIELD_CERTIFICATION_OOS_ITEM}`,
  CERTIFICATION_EXPIRATION_DATE: `${FIELD_CERTIFICATIONS}.${FIELD_EXPIRATION_DATE}`,
  CERTIFICATION_TYPE: `${FIELD_CERTIFICATIONS}.${FIELD_CERTIFICATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  CERTIFICATION_NAME: `${FIELD_CERTIFICATIONS}.${FIELD_CERTIFICATION_NAME}.${FIELD_DISPLAYED_VALUE}`,
  // documents
  DOCUMENTS_STATUS: `${FIELD_DOCUMENTS}.${FIELD_DOCUMENT_STATUS}`,
  DOCUMENTS_EXPIRATION_DATE: `${FIELD_DOCUMENTS}.${FIELD_EXPIRATION_DATE}`,
  DOCUMENTS_DESCRIPTION: `${FIELD_DOCUMENTS}.${FIELD_DOCUMENT_DESCRIPTION}`,
  DOCUMENTS_SIGNET_DATE: `${FIELD_DOCUMENTS}.${FIELD_DOCUMENT_SIGNED_DATE}`,
  DOCUMENTS_TYPE: `${FIELD_DOCUMENTS}.${FIELD_DOCUMENT_DOCUMENT_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  // pay to location
  PAY_TO_TYPE: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_PAY_TYPE}`,
  PAY_TO_LOCATION_ZIP: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_ZIP}`,
  PAY_TO_LOCATION_CITY: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_CITY}`,
  PAY_TO_LOCATION_STATE: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_STATE}`,
  PAY_TO_LOCATION_ADDRESS: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_ADDRESS}`,
  PAY_TO_LOCATION_COUNTRY: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_COUNTRY}`,
  PAY_TO_LOCATION_PAY_TYPE: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_PAY_TYPE}`,
  PAY_TO_LOCATION_ADDRESS2: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_ADDRESS_2}`,
  PAY_TO_LOCATION_START_DATE: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_START_DATE}`,
  PAY_TO_LOCATION_LOCATION_NAME: `${SYSTEM_OBJECT_PAY_TO_LOCATION}.${FIELD_LOCATION_NAME}`,
  // payroll
  PAYROLL_DATE_TO: `${SYSTEM_OBJECT_PAYROLL}.${FIELD_DATE_TO}`,
  PAYROLL_DATE_FROM: `${SYSTEM_OBJECT_PAYROLL}.${FIELD_DATE_FROM}`,
  PAYROLL_NUMBER: `${SYSTEM_OBJECT_PAYROLL}.${FIELD_PAYROLL_NUMBER}`,
  // containers
  CONTAINER_NUMBERS: `${FIELD_CONTAINERS}.${FIELD_CONTAINER_NUMBER}`,
  STOP_CONTAINER_NUMBERS: `${FIELD_CONTAINERS}.${FIELD_CONTAINER}.${FIELD_CONTAINER_NUMBER}`,
  CONTAINER_NUMBERS_WITH_INITIAL: `${FIELD_CONTAINERS}.${FIELD_CONTAINER_NUMBER_WITH_INITIAL}`,
  EVENT_CONTAINERS_CONTAINER_NUMBER: `${FIELD_LOAD_STOPS}.${FIELD_CONTAINERS}.${FIELD_CONTAINER}.${
    FIELD_CONTAINER_NUMBER}`,
  EVENT_CONTAINERS_CONTAINER_NUMBER_WITH_INITIAL: `${FIELD_LOAD_STOPS}.${FIELD_CONTAINERS}.${
    FIELD_CONTAINER}.${FIELD_CONTAINER_NUMBER_WITH_INITIAL}`,
  // events items
  EVENTS_ITEMS_ITEM_TYPE: `${FIELD_LOAD_STOPS}.${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_TYPE}`,
  EVENTS_ITEMS_ODOMETER_UOM: `${FIELD_LOAD_STOPS}.${SYSTEM_LIST_ITEMS}.${FIELD_ODOMETER_UOM}`,
  EVENTS_ITEMS_WHEELBASE_UOM: `${FIELD_LOAD_STOPS}.${SYSTEM_LIST_ITEMS}.${FIELD_WHEELBASE_UOM}`,
  EVENTS_ITEMS_ITEM_FREIGHT_CLASS: `${FIELD_LOAD_STOPS}.${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_FREIGHT_CLASS}`,
  EVENTS_ITEMS_ITEM_DIMENSIONS_UOM: `${FIELD_LOAD_STOPS}.${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_DIMENSIONS_UOM}`,
  // INTEGRATION INFO
  INTEGRATION_INFO_INTEGRATION_ID: `${FIELD_INTEGRATION_INFO}.${FIELD_INTEGRATION_ID}`,
  INTEGRATION_INFO_INTEGRATION_TYPE: `${FIELD_INTEGRATION_INFO}.${FIELD_INTEGRATION_TYPE}`,
  INTEGRATION_INFO_LAST_MODIFIED_DATE: `${FIELD_INTEGRATION_INFO}.${FIELD_LAST_MODIFIED_DATE}`,
  INTEGRATION_INFO_EXTERNAL_DIVISION_NAME: `${FIELD_INTEGRATION_INFO}.${FIELD_EXTERNAL_DIVISION_NAME}`,
  // LOAD
  LOAD_GUID: `${FIELD_LOAD}.${FIELD_GUID}`,
  LOAD_TYPE: `${FIELD_LOAD}.${FIELD_TYPE}`,
  LOAD_STATUS: `${FIELD_LOAD}.${FIELD_STATUS}`,
  LOAD_EVENT_COUNT: `${FIELD_LOAD}.${FIELD_EVENT_COUNT}`,
  LOAD_INVOICE_COUNT: `${FIELD_LOAD}.${FIELD_INVOICE_COUNT}`,
  LOAD_BRANCH_NAME: `${FIELD_LOAD}.${FIELD_BRANCH_DOT_BRANCH_NAME}`,
  LOAD_STORED_TOTAL_WEIGHT: `${FIELD_LOAD}.${FIELD_STORED_TOTAL_WEIGHT}`,
  LOAD_STORED_TOTAL_VOLUME: `${FIELD_LOAD}.${FIELD_STORED_TOTAL_VOLUME}`,
  LOAD_STORED_TOTAL_DISTANCE: `${FIELD_LOAD}.${FIELD_STORED_TOTAL_DISTANCE}`,
  LOAD_PRIMARY_REFERENCE_VALUE: `${FIELD_LOAD}.${FIELD_PRIMARY_REFERENCE_VALUE}`,
  LOAD_BRANCH_ACCOUNT_NAME: `${FIELD_LOAD}.${FIELD_BRANCH}.${FIELD_ACCOUNT_NAME}`,
  // LOAD FIRST/LAST EVENTS/BILL TO
  LOAD_FIRST_EVENT_LATE_DATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_LATE_DATE}`,
  LOAD_FIRST_EVENT_EARLY_DATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EARLY_DATE}`,
  LOAD_FIRST_EVENT_PICKUP_NUMBER: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_STOP_NUMBER}`,
  LOAD_FIRST_EVENT_COMPLETE_DATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  LOAD_FIRST_EVENT_LOCATION_ZIP: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_ZIP}`,
  LOAD_FIRST_EVENT_LOCATION_CITY: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_CITY}`,
  LOAD_FIRST_EVENT_APPOINTMENT_EARLY_DATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${
    FIELD_APPOINTMENT_EARLY_DATE}`,
  LOAD_FIRST_EVENT_LOCATION_STATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_STATE}`,
  LOAD_FIRST_EVENT_LOCATION_COUNTRY: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_COUNTRY}`,
  LOAD_FIRST_EVENT_LOCATION_COMMENTS: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_COMMENTS}`,
  LOAD_FIRST_EVENT_LOCATION_NAME: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_NAME}`,
  LOAD_FIRST_EVENT_LOCATION_ADDRESS1: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_ADDRESS_1}`,
  LOAD_FIRST_EVENT_LOCATION_ADDRESS2: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_ADDRESS_2}`,
  LOAD_FIRST_EVENT_LOCATION_TEMPLATE_ID: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_TEMPLATE_ID}`,
  LOAD_FIRST_EVENT_LOCATION_TYPE: `${FIELD_LOAD}.${SYSTEM_OBJECT_FIRST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  LOAD_LAST_EVENT_LATE_DATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_LATE_DATE}`,
  LOAD_LAST_EVENT_EARLY_DATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EARLY_DATE}`,
  LOAD_LAST_EVENT_COMPLETE_DATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${FIELD_EVENT_COMPLETE_DATE}`,
  LOAD_LAST_EVENT_LOCATION_ZIP: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_ZIP}`,
  LOAD_LAST_EVENT_LOCATION_CITY: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_CITY}`,
  LOAD_LAST_EVENT_APPOINTMENT_EARLY_DATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${
    FIELD_APPOINTMENT_EARLY_DATE}`,
  LOAD_LAST_EVENT_LOCATION_STATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_STATE}`,
  LOAD_LAST_EVENT_LOCATION_COUNTRY: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_COUNTRY}`,
  LOAD_LAST_EVENT_LOCATION_COMMENTS: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_COMMENTS}`,
  LOAD_LAST_EVENT_LOCATION_NAME: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_NAME}`,
  LOAD_LAST_EVENT_LOCATION_ADDRESS1: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_ADDRESS_1}`,
  LOAD_LAST_EVENT_LOCATION_ADDRESS2: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_ADDRESS_2}`,
  LOAD_LAST_EVENT_LOCATION_TEMPLATE_ID: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_TEMPLATE_ID}`,
  LOAD_LAST_EVENT_LOCATION_TYPE: `${FIELD_LOAD}.${SYSTEM_OBJECT_LAST_EVENT}.${SYSTEM_OBJECT_LOCATION}.${
    FIELD_LOCATION_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  LOAD_BILL_TO_ZIP: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_ZIP}`,
  LOAD_BILL_TO_CITY: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_CITY}`,
  LOAD_BILL_TO_EMAIL: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_EMAIL}`,
  LOAD_BILL_TO_PHONE: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_PHONE}`,
  LOAD_BILL_TO_STATE: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_STATE}`,
  LOAD_BILL_TO_EMAILS: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_EMAILS}`,
  LOAD_BILL_TO_COUNTRY: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_COUNTRY}`,
  LOAD_BILL_TO_COMMENTS: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_COMMENTS}`,
  LOAD_BILL_TO_ADDRESS1: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_ADDRESS_1}`,
  LOAD_BILL_TO_ADDRESS2: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_ADDRESS_2}`,
  LOAD_BILL_TO_PAYMENT_TERM: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_PAYMENT_TERM}`,
  LOAD_BILL_TO_EMAILS_FIELD: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_EMAILS_FIELD}`,
  LOAD_BILL_TO_CONTACT_NAME: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_CONTACT_NAME}`,
  LOAD_BILL_TO_LOCATION_NAME: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${FIELD_LOCATION_NAME}`,
  LOAD_BILL_TO_LOCATION_TYPE: `${FIELD_LOAD}.${SYSTEM_OBJECT_BILL_TO}.${SYSTEM_DROPDOWN_LOCATION_TYPE}.${
    FIELD_DISPLAYED_VALUE}`,
  // LOAD RATE
  LOAD_RATE_TOTAL: `${FIELD_LOAD}.${SYSTEM_OBJECT_RATE}.${FIELD_TOTAL}`,
  LOAD_RATE_MODE: `${FIELD_LOAD}.${SYSTEM_OBJECT_RATE}.${FIELD_MODE}.${FIELD_DISPLAYED_VALUE}`,
  LOAD_RATE_MAIN_CHARGES_TOTAL: `${FIELD_LOAD}.${SYSTEM_OBJECT_RATE}.${FIELD_MAIN_CHARGES_TOTAL}`,
  LOAD_RATE_FUEL_CHARGES_TOTAL: `${FIELD_LOAD}.${SYSTEM_OBJECT_RATE}.${FIELD_FUEL_CHARGES_TOTAL}`,
  LOAD_RATE_ADDITIONAL_CHARGES_TOTAL: `${FIELD_LOAD}.${SYSTEM_OBJECT_RATE}.${FIELD_ADDITIONAL_CHARGES_TOTAL}`,
  LOAD_RATE_NORMALIZED_TOTAL: `${FIELD_LOAD}.${SYSTEM_OBJECT_RATE}.${SYSTEM_OBJECT_NORMALIZED_TOTAL}.${
    FIELD_TOTAL}`,
  // CLOS RATE
  CLOS_RATE_MAIN_CHARGES_TOTAL: `${SYSTEM_LIST_CLOS}.${SYSTEM_OBJECT_RATE}.${FIELD_MAIN_CHARGES_TOTAL}`,
  CLOS_RATE_FUEL_CHARGES_TOTAL: `${SYSTEM_LIST_CLOS}.${SYSTEM_OBJECT_RATE}.${FIELD_FUEL_CHARGES_TOTAL}`,
  CLOS_RATE_ADDITIONAL_CHARGES_TOTAL: `${SYSTEM_LIST_CLOS}.${SYSTEM_OBJECT_RATE}.${FIELD_ADDITIONAL_CHARGES_TOTAL}`,
  // FLEET ASSIGNMENT
  FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_FULL_NAME}`,
  FLEET_ASSIGNMENT_SECONDARY_DRIVER_FULL_NAME: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
      SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_FULL_NAME}`,
  FLEET_ASSIGNMENT_PR_DRIVER_FLEET_VENDOR_MC_NUMBER: `${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_MC_NUMBER}`,
  // TEL FLEET/CARRIER ASSIGNMENT
  TEL_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_FLEET_LOGIN_ID}`,
  TEL_FLEET_ASSIGNMENT_PR_DRIVER_DRIVER_TYPE: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_DRIVER_TYPE}`,
  TEL_FLEET_ASSIGNMENT_PR_DRIVER_EMAIL: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_FLEET_EMAIL}`,
  TEL_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_FLEET_FIRST_NAME}`,
  TEL_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_FLEET_LAST_NAME}`,
  TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_FLEET_LOGIN_ID}`,
  TEL_FLEET_ASSIGNMENT_SEC_DRIVER_DRIVER_TYPE: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_DRIVER_TYPE}`,
  TEL_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_FLEET_EMAIL}`,
  TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_FLEET_FIRST_NAME}`,
  TEL_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_FLEET_LAST_NAME}`,
  TEL_FLEET_ASSIGNMENT_TRUCK_UNIT_ID: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_TRUCK}.${FIELD_TRUCK_UNIT_ID}`,
  TEL_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    FIELD_TRUCK}.${SYSTEM_OBJECT_FLEET_VENDOR}.${FIELD_NAME}`,
  TEL_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_LIST_TRAILERS}.${FIELD_TRUCK_UNIT_ID}`,
  TEL_FLEET_ASSIGNMENT_PR_DRIVER_FULL_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_PRIMARY_DRIVER}.${FIELD_FULL_NAME}`,
  TEL_FLEET_ASSIGNMENT_SEC_DRIVER_FULL_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_FLEET_ASSIGNMENT}.${
    SYSTEM_OBJECT_SECONDARY_DRIVER}.${FIELD_FULL_NAME}`,
  TEL_CARRIER_ASSIGNMENT_PICKUP_NUMBER: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_PICKUP_NUMBER}`,
  TEL_CARRIER_ASSIGNMENT_TRACKING_NUMBER: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_TRACKING_NUMBER}`,
  TEL_CARRIER_ASSIGNMENT_SNAPSHOT_NAME: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_ROUTE_CARRIER_SNAPSHOT}.${FIELD_NAME}`,
  TEL_CARRIER_ASSIGNMENT_SNAPSHOT_SCAC: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_ROUTE_CARRIER_SNAPSHOT}.${FIELD_CARRIER_SCAC}`,
  TEL_CARRIER_ASSIGNMENT_SNAPSHOT_US_DOT_NUMBER: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_ROUTE_CARRIER_SNAPSHOT}.${FIELD_US_DOT_NUMBER}`,
  TEL_CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_ROUTE_CARRIER_SNAPSHOT}.${FIELD_CARRIER_MC_NUMBER}`,
  TEL_CARRIER_ASSIGNMENT_TRUCK: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${FIELD_TRUCK}`,
  TEL_CARRIER_ASSIGNMENT_TRAILER: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${FIELD_TRAILER}`,
  TEL_CARRIER_ASSIGNMENT_PRIMARY_DRIVER: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER}`,
  TEL_CARRIER_ASSIGNMENT_SECONDARY_DRIVER: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER}`,
  TEL_CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_PRIMARY_DRIVER_PHONE}`,
  TEL_CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE: `${FIELD_TEL}.${SYSTEM_OBJECT_CARRIER_ASSIGNMENT}.${
    FIELD_SECONDARY_DRIVER_PHONE}`,
  // CLO ITEMS
  CLO_ITEMS_VIN: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_VIN}`,
  CLO_ITEMS_BAY: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_BAY}`,
  CLO_ITEMS_YEAR: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_YEAR}`,
  CLO_ITEMS_MAKE: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_MAKE}`,
  CLO_ITEMS_MODEL: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_MODEL}`,
  CLO_ITEMS_COLOR: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_COLOR}`,
  CLO_ITEMS_ITEM_ID: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_ID}`,
  CLO_ITEMS_ODOMETER: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_ODOMETER}`,
  CLO_ITEMS_WHEELBASE: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_WHEELBASE}`,
  CLO_ITEMS_ITEM_TYPE: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_TYPE}`,
  CLO_ITEMS_SUB_MODEL: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_SUB_MODEL}`,
  CLO_ITEMS_ORIGIN_STATE: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_ORIGIN_STATE}`,
  CLO_ITEMS_ODOMETER_UOM: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_ODOMETER_UOM}`,
  CLO_ITEMS_MANUFACTURER: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_MANUFACTURER}`,
  CLO_ITEMS_WHEELBASE_UOM: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_WHEELBASE_UOM}`,
  CLO_ITEMS_LICENSE_PLATE: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_LICENSE_PLATE}`,
  CLO_ITEMS_ORIGIN_COUNTRY: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_ORIGIN_COUNTRY}`,
  CLO_ITEMS_FREIGHT_CLASS: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_FREIGHT_CLASS}`,
  CLO_ITEMS_TEMPERATURE_TYPE: `${FIELD_CLO}.${SYSTEM_LIST_ITEMS}.${FIELD_ITEM_TEMPERATURE_TYPE}`,
  // last status message
  LAST_STATUS_MESSAGE_STATUS_REASON_CODE: `${SYSTEM_OBJECT_LAST_STATUS_MESSAGE}.${
    SYSTEM_DROPDOWN_STATUS_REASON_CODE}.${FIELD_DISPLAYED_VALUE}`,
  // invoices
  INVOICES_TOTAL: `${SYSTEM_LIST_INVOICES}.${FIELD_TOTAL}`,
  INVOICES_NUMBER: `${SYSTEM_LIST_INVOICES}.${FIELD_INVOICE_NUMBER}`,
  INVOICES_FACTORING_FEE: `${SYSTEM_LIST_INVOICES}.${FIELD_FACTORING_FEE}`,
  INVOICES_STATUS: `${SYSTEM_LIST_INVOICES}.${FIELD_STATUS}.${FIELD_DISPLAYED_VALUE}`,
  INVOICES_MASTER_INVOICE_GUID: `${SYSTEM_LIST_INVOICES}.${SYSTEM_OBJECT_MASTER_INVOICE}.${FIELD_GUID}`,
  // fleet invoices
  FLEET_INVOICES_NORMALIZED_TOTAL: `${SYSTEM_LIST_FLEET_INVOICES}.${
    SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_TOTAL}`,
  FLEET_INVOICES_NORMALIZED_CURRENCY: `${SYSTEM_LIST_FLEET_INVOICES}.${
    SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_CURRENCY}`,
  // carrier invoices
  CARRIER_INVOICES_TOTAL: `${SYSTEM_LIST_CARRIER_INVOICES}.${FIELD_TOTAL}`,
  CARRIER_INVOICES_DATE: `${SYSTEM_LIST_CARRIER_INVOICES}.${FIELD_INVOICE_DATE}`,
  CARRIER_INVOICES_NUMBER: `${SYSTEM_LIST_CARRIER_INVOICES}.${FIELD_INVOICE_NUMBER}`,
  CARRIER_INVOICES_INTEGRATION_DATE: `${SYSTEM_LIST_CARRIER_INVOICES}.${FIELD_INTEGRATED_DATE}`,
  CARRIER_INVOICES_STATUS: `${SYSTEM_LIST_CARRIER_INVOICES}.${FIELD_STATUS}.${FIELD_DISPLAYED_VALUE}`,
  CARRIER_INVOICES_CHECK_DATE: `${SYSTEM_LIST_CARRIER_INVOICES}.${FIELD_PAYMENTS}.${FIELD_INVOICE_CHECK_DATE}`,
  CARRIER_INVOICES_CHECK_NUMBER: `${SYSTEM_LIST_CARRIER_INVOICES}.${
    FIELD_PAYMENTS}.${FIELD_CHECK_NUMBER}`,
  CARRIER_INVOICES_DEPOSIT_DATE: `${SYSTEM_LIST_CARRIER_INVOICES}.${
    FIELD_PAYMENTS}.${FIELD_INVOICE_DEPOSIT_DATE}`,
  CARRIER_INVOICES_NORMALIZED_TOTAL: `${SYSTEM_LIST_CARRIER_INVOICES}.${
    SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_TOTAL}`,
  CARRIER_INVOICES_NORMALIZED_CURRENCY: `${SYSTEM_LIST_CARRIER_INVOICES}.${
    SYSTEM_OBJECT_NORMALIZED_TOTAL}.${FIELD_CURRENCY}`,
  // payments
  PAYMENTS_CHECK_AMOUNT: `${FIELD_PAYMENTS}.${FIELD_CHECK_AMOUNT}`,
  PAYMENTS_CHECK_DATE: `${FIELD_PAYMENTS}.${FIELD_INVOICE_CHECK_DATE}`,
  PAYMENTS_CHECK_NUMBER: `${FIELD_PAYMENTS}.${FIELD_INVOICE_CHECK_NUMBER}`,
  PAYMENTS_DEPOSIT_DATE: `${FIELD_PAYMENTS}.${FIELD_INVOICE_DEPOSIT_DATE}`,
  PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER: `${FIELD_PAYMENTS}.${FIELD_INVOICE_ACH_PAYMENT_CONFIRMATION_NUMBER}`,
  // customer details
  CUSTOMER_DETAILS_NAME: `${SYSTEM_OBJECT_CUSTOMER_DETAILS}.${FIELD_NAME}`,
  CUSTOMER_DETAILS_FEDERAL_EIN: `${SYSTEM_OBJECT_CUSTOMER_DETAILS}.${FIELD_FEDERAL_EIN}`,
  CUSTOMER_DETAILS_CONTACT_NUMBER: `${SYSTEM_OBJECT_CUSTOMER_DETAILS}.${FIELD_CONTACT_NUMBER}`,
  // created by user
  CREATED_BY_USER_EMAIL_ID: `${SYSTEM_OBJECT_CREATED_BY_USER}.${FIELD_EMAIL_ID}`,
  CREATED_BY_USER_LAST_NAME: `${SYSTEM_OBJECT_CREATED_BY_USER}.${FIELD_LAST_NAME}`,
  CREATED_BY_USER_FIRST_NAME: `${SYSTEM_OBJECT_CREATED_BY_USER}.${FIELD_FIRST_NAME}`,
  CREATED_BY_USER_CONTACT_NUMBER: `${SYSTEM_OBJECT_CREATED_BY_USER}.${FIELD_CONTACT_NUMBER}`,
  // confirmed by user
  CONFIRMED_BY_USER_EMAIL_ID: `${SYSTEM_OBJECT_CONFIRMED_BY_USER}.${FIELD_EMAIL_ID}`,
  CONFIRMED_BY_USER_LAST_NAME: `${SYSTEM_OBJECT_CONFIRMED_BY_USER}.${FIELD_LAST_NAME}`,
  CONFIRMED_BY_USER_FIRST_NAME: `${SYSTEM_OBJECT_CONFIRMED_BY_USER}.${FIELD_FIRST_NAME}`,
  CONFIRMED_BY_USER_CONTACT_NUMBER: `${SYSTEM_OBJECT_CONFIRMED_BY_USER}.${FIELD_CONTACT_NUMBER}`,
  // los subscription order
  LOS_SUBSCRIPTION_ORDER_PLAN_NAME: `${SYSTEM_OBJECT_LOS_SUBSCRIPTION_ORDER}.${FIELD_PLAN_NAME}`,
  // load board shipments
  LOAD_BOARD_SHIPMENTS_STATUS: `${SYSTEM_OBJECT_LOAD_BOARD_SHIPMENTS}.${FIELD_STATUS}`,
  LOAD_BOARD_SHIPMENTS_SOURCE: `${SYSTEM_OBJECT_LOAD_BOARD_SHIPMENTS}.${FIELD_SOURCE}`,
  // cost allocation
  COST_ALLOCATION_MARGIN: `${SYSTEM_OBJECT_COST_ALLOCATION}.${FIELD_MARGIN}`,
  COST_ALLOCATION_CLO_COST: `${SYSTEM_OBJECT_COST_ALLOCATION}.${FIELD_CLO_COST}`,
  COST_ALLOCATION_TEL_COST: `${SYSTEM_OBJECT_COST_ALLOCATION}.${FIELD_TEL_COST}`,
  COST_ALLOCATION_CLO_TOTAL_SPENDING: `${SYSTEM_OBJECT_COST_ALLOCATION}.${FIELD_CLO}.${FIELD_TOTAL_SPENDING}`,
  COST_ALLOCATION_CLO_RATE_TOTAL: `${SYSTEM_OBJECT_COST_ALLOCATION}.${FIELD_CLO}.${FIELD_RATE}.${FIELD_TOTAL}`,
  COST_ALLOCATION_CLO_STORED_TOTAL_DISTANCE: `${SYSTEM_OBJECT_COST_ALLOCATION}.${
    FIELD_CLO}.${FIELD_STORED_TOTAL_DISTANCE}`,
  COST_ALLOCATION_CLO_PRIMARY_REFERENCE_VALUE: `${SYSTEM_OBJECT_COST_ALLOCATION}.${
    FIELD_CLO}.${FIELD_PRIMARY_REFERENCE_VALUE}`,
  // fleet service issues
  FLEET_SERVICE_ISSUES_GUID: `${FIELD_ISSUES}.${FIELD_GUID}`,
  FLEET_SERVICE_ISSUES_ID: `${FIELD_ISSUES}.${FIELD_ISSUE_ID}`,
  FLEET_SERVICE_ISSUES_STATUS: `${FIELD_ISSUES}.${FIELD_STATUS}`,
  FLEET_SERVICE_ISSUES_SUMMARY: `${FIELD_ISSUES}.${FIELD_SUMMARY}`,
  FLEET_SERVICE_ISSUES_PRIORITY: `${FIELD_ISSUES}.${FIELD_PRIORITY}`,
  FLEET_SERVICE_ISSUES_DESCRIPTION: `${FIELD_ISSUES}.${FIELD_DESCRIPTION}`,
  // work order
  WORK_ORDER_NAME: `${SYSTEM_OBJECT_WORK_ORDER}.${FIELD_NAME}`,
  WORK_ORDER_STATUS: `${SYSTEM_OBJECT_WORK_ORDER}.${FIELD_STATUS}`,
  WORK_ORDER_DUE_DATE: `${SYSTEM_OBJECT_WORK_ORDER}.${FIELD_DUE_DATE}`,
  WORK_ORDER_ACTUAL_COMPLETED_DATE: `${SYSTEM_OBJECT_WORK_ORDER}.${FIELD_ACTUAL_COMPLETED_DATE}`,
  WORK_ORDER_EXPECTED_COMPLETED_DATE: `${SYSTEM_OBJECT_WORK_ORDER}.${FIELD_EXPECTED_COMPLETED_DATE}`,
  WORK_ORDER_SERVICE_VENDOR_NAME: `${SYSTEM_OBJECT_WORK_ORDER}.${FIELD_SERVICE_VENDOR}.${FIELD_NAME}`,
  // inspection
  INSPECTION_NAME: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_NAME}`,
  INSPECTION_TYPE: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_TYPE}`,
  INSPECTION_STATUS: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_STATUS}`,
  INSPECTION_SOURCE: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_SOURCE}`,
  INSPECTION_END_DATE: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_END_DATE}`,
  INSPECTION_START_DATE: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_START_DATE}`,
  INSPECTION_CREATED_BY: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_CREATED_BY}`,
  INSPECTION_DESCRIPTION: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_DESCRIPTION}`,
  INSPECTION_CREATED_DATE: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_CREATED_DATE}`,
  INSPECTION_TEMPLATE_NAME: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_TEMPLATE_NAME}`,
  INSPECTION_TRUCKING_METHOD: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_TRUCKING_METHOD}`,
  INSPECTION_LAST_MODIFIED_BY: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_LAST_MODIFIED_BY}`,
  INSPECTION_LAST_MODIFIED_DATE: `${SYSTEM_OBJECT_INSPECTION}.${FIELD_LAST_MODIFIED_DATE}`,
  // truck specification
  TRUCK_SPECIFICATION_GVWT: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_GVWT}`,
  TRUCK_SPECIFICATION_WIDTH: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_WIDTH}`,
  TRUCK_SPECIFICATION_HEIGHT: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_HEIGHT}`,
  TRUCK_SPECIFICATION_LENGTH: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_LENGTH}`,
  TRUCK_SPECIFICATION_SLEEPER: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_SLEEPER}`,
  TRUCK_SPECIFICATION_MPG_LOW: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_MPG_LOW}`,
  TRUCK_SPECIFICATION_MPG_HIGH: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_MPG_HIGH}`,
  TRUCK_SPECIFICATION_WIDTH_UOM: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_WIDTH_UOM}`,
  TRUCK_SPECIFICATION_FUEL_TYPE: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_FUEL_TYPE}`,
  TRUCK_SPECIFICATION_LENGTH_UOM: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_LENGTH_UOM}`,
  TRUCK_SPECIFICATION_HEIGHT_UOM: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_HEIGHT_UOM}`,
  TRUCK_SPECIFICATION_AXLE_COUNT: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_AXLE_COUNT}`,
  TRUCK_SPECIFICATION_AXLE_CONFIG: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_AXLE_CONFIG}`,
  TRUCK_SPECIFICATION_TARE_WEIGHT: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_TARE_WEIGHT}`,
  TRUCK_SPECIFICATION_GVWT_WEIGHT_TYPE: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_GVWT_WEIGHT_TYPE}`,
  TRUCK_SPECIFICATION_TARE_WEIGHT_TYPE: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_TARE_WEIGHT_TYPE}`,
  TRUCK_SPECIFICATION_FIFTH_WHEEL_WIDTH: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_FIFTH_WHEEL_WIDTH}`,
  TRUCK_SPECIFICATION_FIFTH_WHEEL_HEIGHT: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_FIFTH_WHEEL_HEIGHT}`,
  TRUCK_SPECIFICATION_SPECIFICATION_CLASS: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_SPECIFICATION_CLASS}`,
  TRUCK_SPECIFICATION_FIFTH_WHEEL_WIDTH_TYPE: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_FIFTH_WHEEL_WIDTH_TYPE}`,
  TRUCK_SPECIFICATION_FIFTH_WHEEL_HEIGHT_TYPE: `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_FIFTH_WHEEL_HEIGHT_TYPE}`,
  TRUCK_SPECIFICATION_DOOR_DIMENSION_DOOR_WIDTH:
    `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_DOOR_DIMENSION}.${FIELD_DOOR_WIDTH}`,
  TRUCK_SPECIFICATION_DOOR_DIMENSION_DOOR_HEIGHT:
    `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_DOOR_DIMENSION}.${FIELD_DOOR_HEIGHT}`,
  TRUCK_SPECIFICATION_DOOR_DIMENSION_DOOR_WIDTH_UOM:
    `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_DOOR_DIMENSION}.${FIELD_DOOR_WIDTH_UOM}`,
  TRUCK_SPECIFICATION_DOOR_DIMENSION_DOOR_HEIGHT_UOM:
    `${SYSTEM_OBJECT_TRUCK_SPECIFICATION}.${FIELD_DOOR_DIMENSION}.${FIELD_DOOR_HEIGHT_UOM}`,
  // trailer specification
  TRAILER_SPECIFICATION_GVWT: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_GVWT}`,
  TRAILER_SPECIFICATION_CUBE: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_CUBE}`,
  TRAILER_SPECIFICATION_CUBE_TYPE: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_CUBE_TYPE}`,
  TRAILER_SPECIFICATION_AXLE_COUNT: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_AXLE_COUNT}`,
  TRAILER_SPECIFICATION_AXLE_CONFIG: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_AXLE_CONFIG}`,
  TRAILER_SPECIFICATION_TARE_WEIGHT: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_TARE_WEIGHT}`,
  TRAILER_SPECIFICATION_DECK_HEIGHT: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_DECK_HEIGHT}`,
  TRAILER_SPECIFICATION_BULK_COMP_COUNT: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_BULK_COMP_COUNT}`,
  TRAILER_SPECIFICATION_DECK_HEIGHT_TYPE: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_DECK_HEIGHT_TYPE}`,
  TRAILER_SPECIFICATION_BULK_COMP_VOLUME: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_BULK_COMP_VOLUME}`,
  TRAILER_SPECIFICATION_WEIGHT_TYPE_GVWT: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_WEIGHT_TYPE_GVWT}`,
  TRAILER_SPECIFICATION_WEIGHT_TARE_TYPE: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_WEIGHT_TARE_TYPE}`,
  TRAILER_SPECIFICATION_TEMPERATURE_CONTROL: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_TEMPERATURE_CONTROL}`,
  TRAILER_SPECIFICATION_BULK_COMP_VOLUME_TYPE: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_BULK_COMP_VOLUME_TYPE}`,
  TRAILER_SPECIFICATION_DOOR_DIMENSION_WIDTH_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_DOOR_DIMENSION}.widthUOM`,
  TRAILER_SPECIFICATION_WELL_DIMENSION_HEIGHT_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_WELL_DIMENSION}.heightUOM`,
  TRAILER_SPECIFICATION_WELL_DIMENSION_LENGTH_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_WELL_DIMENSION}.lengthUOM`,
  TRAILER_SPECIFICATION_DOOR_DIMENSION_HEIGHT_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_DOOR_DIMENSION}.heightUOM`,
  TRAILER_SPECIFICATION_EXTERNAL_DIMENSION_WIDTH_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_EXTERNAL_DIMENSION}.widthUOM`,
  TRAILER_SPECIFICATION_INTERNAL_DIMENSION_WIDTH_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_INTERNAL_DIMENSION}.widthUOM`,
  TRAILER_SPECIFICATION_EXTERNAL_DIMENSION_HEIGHT_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_EXTERNAL_DIMENSION}.heightUOM`,
  TRAILER_SPECIFICATION_EXTERNAL_DIMENSION_LENGTH_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_EXTERNAL_DIMENSION}.lengthUOM`,
  TRAILER_SPECIFICATION_INTERNAL_DIMENSION_HEIGHT_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_INTERNAL_DIMENSION}.heightUOM`,
  TRAILER_SPECIFICATION_INTERNAL_DIMENSION_LENGTH_UOM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_INTERNAL_DIMENSION}.lengthUOM`,
  TRAILER_SPECIFICATION_REFRIGERATION_MODEL:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_MODEL}`,
  TRAILER_SPECIFICATION_REFRIGERATION_HOURS:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_HOURS}`,
  TRAILER_SPECIFICATION_REFRIGERATION_SERIES:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_SERIES}`,
  TRAILER_SPECIFICATION_DOOR_DIMENSION_WIDTH:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_DOOR_DIMENSION}.${FIELD_WIDTH}`,
  TRAILER_SPECIFICATION_REFRIGERATION_CARB_ID:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_CARB_ID}`,
  TRAILER_SPECIFICATION_WELL_DIMENSION_LENGTH:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_WELL_DIMENSION}.${FIELD_LENGTH}`,
  TRAILER_SPECIFICATION_WELL_DIMENSION_HEIGHT:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_WELL_DIMENSION}.${FIELD_HEIGHT}`,
  TRAILER_SPECIFICATION_DOOR_DIMENSION_HEIGHT:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_DOOR_DIMENSION}.${FIELD_HEIGHT}`,
  TRAILER_SPECIFICATION_REFRIGERATION_TRUATCM_ID:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_TRUATCM_ID}`,
  TRAILER_SPECIFICATION_REFRIGERATION_HOURS_DATE:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_HOURS_DATE}`,
  TRAILER_SPECIFICATION_EXTERNAL_DIMENSION_WIDTH:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_EXTERNAL_DIMENSION}.${FIELD_WIDTH}`,
  TRAILER_SPECIFICATION_INTERNAL_DIMENSION_WIDTH:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_INTERNAL_DIMENSION}.${FIELD_WIDTH}`,
  TRAILER_SPECIFICATION_DOOR_TYPE_DISPLAYED_VALUE:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_DOOR_TYPE}.${FIELD_DISPLAYED_VALUE}`,
  TRAILER_SPECIFICATION_INTERNAL_DIMENSION_HEIGHT:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_INTERNAL_DIMENSION}.${FIELD_HEIGHT}`,
  TRAILER_SPECIFICATION_REFRIGERATION_OUTPUT_YEAR:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_OUTPUT_YEAR}`,
  TRAILER_SPECIFICATION_EXTERNAL_DIMENSION_HEIGHT:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_EXTERNAL_DIMENSION}.${FIELD_HEIGHT}`,
  TRAILER_SPECIFICATION_EXTERNAL_DIMENSION_LENGTH:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_EXTERNAL_DIMENSION}.${FIELD_LENGTH}`,
  TRAILER_SPECIFICATION_INTERNAL_DIMENSION_LENGTH:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_INTERNAL_DIMENSION}.${FIELD_LENGTH}`,
  TRAILER_SPECIFICATION_REFRIGERATION_COOLANT_TYPE:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_COOLANT_TYPE}`,
  TRAILER_SPECIFICATION_REFRIGERATION_TEMP_RANGE_TO:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_TEMP_RANGE_TO}`,
  TRAILER_SPECIFICATION_REFRIGERATION_SERIAL_NUMBER:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_SERIAL_NUMBER}`,
  TRAILER_SPECIFICATION_REFRIGERATION_TEMP_RANGE_FROM:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_TEMP_RANGE_FROM}`,
  TRAILER_SPECIFICATION_BODY_MATERIAL_DISPLAYED_VALUE:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_BODY_MATERIAL}.${FIELD_DISPLAYED_VALUE}`,
  TRAILER_SPECIFICATION_REFRIGERATION_TEMP_RANGE_TYPE:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_REFRIGERATION}.${FIELD_TEMP_RANGE_TYPE}`,
  TRAILER_SPECIFICATION_SPECIFICATION_CLASS_DISPLAYED_VALUE:
    `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${FIELD_SPECIFICATION_CLASS}.${FIELD_DISPLAYED_VALUE}`,
  TRAILER_SPECIFICATION_REFRIGERATION_MANUFACTURER_DISPLAYED_VALUE: `${SYSTEM_OBJECT_TRAILER_SPECIFICATION}.${
    FIELD_REFRIGERATION}.${FIELD_REFRIGERATION_MANUFACTURER}.${FIELD_DISPLAYED_VALUE}`,
};

// SYSTEM STATUSES

// rate
export const RATE_STATUS_DRAFT = 'DRAFT';
export const RATE_STATUS_QUOTED = 'QUOTED';
export const RATE_STATUS_PENDING = 'PENDING';
export const RATE_STATUS_EXPIRED = 'EXPIRED';
export const RATE_STATUS_DECLINED = 'DECLINED';
export const RATE_STATUS_ACCEPTED = 'ACCEPTED';
export const RATE_STATUS_CANCELED = 'CANCELED';
// load
export const LOAD_STATUS_RATED = 'RATED';
export const LOAD_STATUS_QUOTE = 'QUOTE';
export const LOAD_STATUS_QUOTED = 'QUOTED';
export const LOAD_STATUS_PLANNED = 'PLANNED';
export const LOAD_STATUS_CANCELED = 'CANCELED';
export const LOAD_STATUS_DELIVERED = 'DELIVERED';
export const LOAD_STATUS_BOOKED_STATUS = 'BOOKED';
export const LOAD_STATUS_DISPATCHED = 'DISPATCHED';
export const LOAD_STATUS_IN_TRANSIT = 'IN_TRANSIT';
export const LOAD_STATUS_UNSCHEDULED = 'UNSCHEDULED';
export const LOAD_STATUS_DISPATCH_EXPIRED = 'DISPATCH_EXPIRED';
export const LOAD_STATUS_DISPATCH_REJECTED = 'DISPATCH_REJECTED';
// edi
export const EDI_STATUS_ERROR = 'ERROR';
export const EDI_STATUS_PENDING = 'PENDING';
export const EDI_STATUS_ACCEPTED = 'ACCEPTED';
export const EDI_STATUS_DECLINED = 'DECLINED';
// invoice edi
export const INVOICE_EDI_STATUS_ERROR = 'ERROR';
export const INVOICE_EDI_STATUS_NO_MATCH = 'NO_MATCH';
export const INVOICE_EDI_STATUS_PROCESSED = 'PROCESSED';
export const INVOICE_EDI_STATUS_TRANSACTION_UNASSIGNED = 'TRANSACTION_UNASSIGNED';
// quotes
export const QUOTES_STATUS_ERROR = 'ERROR';
export const QUOTES_STATUS_PENDING = 'PENDING';
export const QUOTES_STATUS_ACCEPTED = 'ACCEPTED';
export const QUOTES_STATUS_DECLINED = 'DECLINED';
export const QUOTES_STATUS_REQUESTED = 'REQUESTED';
// order request
export const ORDER_REQUEST_STATUS_PENDING = 'PENDING';
// load board shipment
export const LOAD_BOARD_SHIPMENT_STATUS_POSTED = 'POSTED';
export const LOAD_BOARD_SHIPMENT_STATUS_REMOVED = 'REMOVED';
export const LOAD_BOARD_SHIPMENT_STATUS_COVERED = 'COVERED';
// document
export const DOCUMENT_STATUS_CURRENT = 'CURRENT';
export const DOCUMENT_STATUS_HISTORICAL = 'HISTORICAL';
// invoice integration
export const INVOICE_INTEGRATION_STATUS_SENT = 'SENT';
export const INVOICE_INTEGRATION_STATUS_EXPORTED = 'EXPORTED';
export const INVOICE_INTEGRATION_STATUS_EXTRACTED = 'EXTRACTED';
export const INVOICE_INTEGRATION_STATUS_EXPORT_FAILED = 'EXPORT_FAILED';
export const INVOICE_INTEGRATION_STATUS_EXPORTED_TO_FACTORING = 'EXPORTED_TO_FACTORING';
// access
export const ACCESS_DENIED = 'access_denied';
// invoice status
export const INVOICE_SYSTEM_STATUS_APPROVED = 'APPROVED';
export const INVOICE_SYSTEM_STATUS_REJECTED = 'REJECTED';
export const INVOICE_SYSTEM_STATUS_COMPLETED = 'COMPLETED';
export const INVOICE_SYSTEM_STATUS_READY_FOR_BILLING = 'READY_FOR_BILLING';
// advance payment
export const ADVANCE_PAYMENT_STATUS_BLOCK = 'BLOCK';
export const ADVANCE_PAYMENT_STATUS_CANCEL = 'CANCEL';
export const ADVANCE_PAYMENT_STATUS_ACTIVATE = 'ACTIVATE';

export const TEL_STATUS_SEQUENCE_MAP = {
  [LOAD_STATUS_PLANNED]: 1,
  [LOAD_STATUS_RATED]: 2,
  [LOAD_STATUS_DISPATCHED]: 3,
  [LOAD_STATUS_DISPATCH_REJECTED]: 4,
  [LOAD_STATUS_DISPATCH_EXPIRED]: 5,
  [LOAD_STATUS_CANCELED]: 6,
  [LOAD_STATUS_BOOKED_STATUS]: 7,
  [LOAD_STATUS_IN_TRANSIT]: 8,
  [LOAD_STATUS_DELIVERED]: 9,
};

export const statusLocaleMap = {
  [LOAD_STATUS_QUOTE]: 'titles:quote',
  [LOAD_STATUS_CANCELED]: 'titles:canceled',
  [RATE_STATUS_CANCELED]: 'titles:canceled',
  [STOP_STATUS_TYPE_ARRIVED]: 'titles:arrived',
  [LOAD_STATUS_DISPATCHED]: 'titles:dispatched',
  [LOAD_STATUS_DISPATCHED]: 'titles:dispatched',
  [LOAD_STATUS_RATED]: 'titles:load-status-rated',
  [RATE_STATUS_DRAFT]: 'titles:rate-status-draft',
  [INVOICE_INTEGRATION_STATUS_SENT]: 'titles:sent',
  [STOP_STATUS_TYPE_COMPLETED]: 'titles:completed',
  [LOAD_STATUS_QUOTED]: 'titles:load-status-quoted',
  [STOP_STATUS_TYPE_CHECKED_IN]: 'titles:checked-in',
  [RATE_STATUS_EXPIRED]: 'titles:rate-status-expired',
  [LOAD_STATUS_PLANNED]: 'titles:load-status-planned',
  [RATE_STATUS_PENDING]: 'titles:rate-status-pending',
  [INVOICE_SYSTEM_STATUS_APPROVED]: 'titles:approved',
  [INVOICE_SYSTEM_STATUS_REJECTED]: 'titles:rejected',
  [RATE_STATUS_ACCEPTED]: 'titles:rate-status-accepted',
  [RATE_STATUS_DECLINED]: 'titles:rate-status-declined',
  [INVOICE_SYSTEM_STATUS_COMPLETED]: 'titles:completed',
  [LOAD_STATUS_DELIVERED]: 'titles:load-status-delivered',
  [INVOICE_INTEGRATION_STATUS_EXPORTED]: 'titles:exported',
  [LOAD_STATUS_IN_TRANSIT]: 'titles:load-status-in-transit',
  [LOAD_STATUS_DISPATCH_EXPIRED]: 'titles:dispatch-expired',
  [INVOICE_INTEGRATION_STATUS_EXTRACTED]: 'titles:extracted',
  [LOAD_STATUS_DISPATCH_REJECTED]: 'titles:dispatch-rejected',
  [LOAD_STATUS_UNSCHEDULED]: 'titles:load-status-unscheduled',
  [LOAD_STATUS_BOOKED_STATUS]: 'titles:load-status-booked-status',
  [INVOICE_INTEGRATION_STATUS_EXPORT_FAILED]: 'titles:export-failed',
  [INVOICE_SYSTEM_STATUS_READY_FOR_BILLING]: 'titles:ready-for-billing',
  [INVOICE_INTEGRATION_STATUS_EXPORTED_TO_FACTORING]: 'titles:exported-to-factoring',
};

export const districtMap = {
  [ACCESSORIAL_DISTRICT_TYPE_US]: 'US',
  [ACCESSORIAL_DISTRICT_TYPE_MIDWEST]: 'Midwest',
  [ACCESSORIAL_DISTRICT_TYPE_EAST_COST]: 'East Cost',
  [ACCESSORIAL_DISTRICT_TYPE_GULF_COAST]: 'Gulf Coast',
  [ACCESSORIAL_DISTRICT_TYPE_WEST_COAST]: 'West Coast',
  [ACCESSORIAL_DISTRICT_TYPE_CALIFORNIA]: 'California',
  [ACCESSORIAL_DISTRICT_TYPE_NEW_ENGLAND]: 'New England',
  [ACCESSORIAL_DISTRICT_TYPE_ROCKY_MOUNTAIN]: 'Rocky Mountain',
  [ACCESSORIAL_DISTRICT_TYPE_LOWER_ATLANTIC]: 'Lower Atlantic',
  [ACCESSORIAL_DISTRICT_TYPE_CENTRAL_ATLANTIC]: 'Central Atlantic',
  [ACCESSORIAL_DISTRICT_TYPE_WEST_COAST_EXCEPT_CALIFORNIA]: 'West Coast Except California',
};

export const loadStatusesMap = {
  [LOAD_TYPE_CLO]: [
    LOAD_STATUS_QUOTE,
    LOAD_STATUS_UNSCHEDULED,
    LOAD_STATUS_PLANNED,
    LOAD_STATUS_BOOKED_STATUS,
    LOAD_STATUS_IN_TRANSIT,
    LOAD_STATUS_DELIVERED,
    LOAD_STATUS_CANCELED,
  ],
  [LOAD_TYPE_TEL]: [
    LOAD_STATUS_PLANNED,
    LOAD_STATUS_RATED,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_DISPATCH_EXPIRED,
    LOAD_STATUS_DISPATCH_REJECTED,
    LOAD_STATUS_CANCELED,
    LOAD_STATUS_BOOKED_STATUS,
    LOAD_STATUS_IN_TRANSIT,
    LOAD_STATUS_DELIVERED,
  ],
};

export const TES_STATUSES_TO_SEND_QUOTE_REQUEST = [
  LOAD_STATUS_RATED,
  LOAD_STATUS_PLANNED,
  LOAD_STATUS_DISPATCH_EXPIRED,
  LOAD_STATUS_DISPATCH_REJECTED,
];

export const EDI_STATUSES_TO_ACCEPT_DECLINE = [
  EDI_STATUS_PENDING,
];

export const QUOTES_STATUSES_TO_ACCEPT_DECLINE = [
  QUOTES_STATUS_ERROR,
  QUOTES_STATUS_PENDING,
  QUOTES_STATUS_REQUESTED,
];

export const TEL_STATUSES_TO_CANCEL = [
  LOAD_STATUS_BOOKED_STATUS,
  LOAD_STATUS_DISPATCHED,
];

export const TEL_STATUSES_TO_DISPATCH = [
  LOAD_STATUS_RATED,
  LOAD_STATUS_DISPATCH_REJECTED,
];

export const TEL_STATUS_CHECK = [
  LOAD_STATUS_IN_TRANSIT,
  LOAD_STATUS_BOOKED_STATUS,
];

export const TEL_NEGATIVE_STATUSES = [
  LOAD_STATUS_DISPATCH_EXPIRED,
  LOAD_STATUS_DISPATCH_REJECTED,
];
