// home
export const ROUTE_PATH_HOME = '/';
// auth
export const ROUTE_PATH_LOGIN = '/login';
export const ROUTE_PATH_NAL_LOGIN = '/nal_login';
export const ROUTE_PATH_NEW_PASSWORD = '/new_password';
export const ROUTE_PATH_RESET_PASSWORD = '/reset_password';
export const ROUTE_PATH_PRIVACY_POLICY = '/privacy_policy';
export const ROUTE_PATH_BOSH_LOS_REDIRECT = '/bosch_los_redirect';
export const ROUTE_PATH_BOSH_LOS_CALLBACK = '/bosch_los_callback';
export const ROUTE_PATH_NEW_PASSWORD_MOBILE = '/new_password_mobile';
export const ROUTE_PATH_TERMS_AND_CONDITIONS = '/terms_and_conditions';
export const ROUTE_PATH_USER_TERMS_AND_CONDITIONS = '/user_terms_and_conditions';
export const ROUTE_PATH_NEW_PASSWORD_MOBILE_SUCCESS_PAGE = '/new_password_mobile_success_page';
// oauth2 popup
export const ROUTE_PATH_OAUTH2_POPUP = '/oauth2';
// user
export const ROUTE_PATH_PROFILE = '/profile';
export const ROUTE_PATH_USERS_NEW = '/users/new';
export const ROUTE_PATH_USERS_LIST = '/users/list';
export const ROUTE_PATH_USERS_SETTINGS = '/users/:guid/settings';
// role
export const ROUTE_PATH_ROLES_NEW = '/roles/new';
export const ROUTE_PATH_ROLES_LIST = '/roles/list';
export const ROUTE_PATH_ROLES_EDIT = '/roles/:guid/edit';
// branch
export const ROUTE_PATH_BRANCH_NEW = '/branch/new';
export const ROUTE_PATH_BRANCH_LIST = '/branch/list';
export const ROUTE_PATH_BRANCH_EDIT = '/branch/:guid/edit';
export const ROUTE_PATH_BRANCH_DETAIL = '/branch/details/:guid';
// reference
export const ROUTE_PATH_REFERENCES_LIST = '/reference/list';
// sequence
export const ROUTE_PATH_SEQUENCES_LIST = '/sequences/list';
// location
export const ROUTE_PATH_LOCATIONS_LIST = '/locations/list';
// item
export const ROUTE_PATH_ITEMS_LIST = '/items/list';
// configuration
export const ROUTE_PATH_CONFIG = '/config';
export const ROUTE_PATH_CONFIG_UI = '/config/ui';
export const ROUTE_PATH_CONFIG_TEL = '/config/tel';
export const ROUTE_PATH_CONFIG_CLO = '/config/clo';
export const ROUTE_PATH_CONFIG_AUDIT = '/config/audit';
export const ROUTE_PATH_CONFIG_TRUCK = '/config/truck';
export const ROUTE_PATH_CONFIG_RATING = '/config/rating';
export const ROUTE_PATH_CONFIG_DRIVER = '/config/driver';
export const ROUTE_PATH_CONFIG_GENERAL = '/config/general';
export const ROUTE_PATH_CONFIG_TRAILER = '/config/trailer';
export const ROUTE_PATH_CONFIG_CARRIER = '/config/carrier';
export const ROUTE_PATH_CONFIG_OPTIONAL = '/config/optional';
export const ROUTE_PATH_CONFIG_PASSWORD = '/config/password';
export const ROUTE_PATH_CONFIG_INVOICES = '/config/invoices';
export const ROUTE_PATH_CONFIG_TEMPLATES = '/config/templates';
export const ROUTE_PATH_CONFIG_MOBILEAPP = '/config/mobileapp';
export const ROUTE_PATH_CONFIG_INTEGRATION = '/config/integration';
export const ROUTE_PATH_CONFIG_FLEET_GENERAL = '/config/fleet-general';
export const ROUTE_PATH_CONFIG_COMMUNICATION = '/config/communication';
export const ROUTE_PATH_CONFIG_SERVICE_VENDOR = '/config/service-vendor';
export const ROUTE_PATH_CONFIG_TASK_MANAGEMENT = '/config/task-management';
export const ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT = '/config/claim-management';
// fleet
export const ROUTE_PATH_FLEET = '/fleet';
export const ROUTE_PATH_FLEET_MAP = '/fleet/locations';
export const ROUTE_PATH_FLEET_TRUCKS_LIST = '/fleet/trucks/list';
export const ROUTE_PATH_FLEET_VENDOR_LIST = '/fleet/vendor/list';
export const ROUTE_PATH_FLEET_DRIVERS_LIST = '/fleet/drivers/list';
export const ROUTE_PATH_FLEET_TRAILERS_LIST = '/fleet/trailers/list';
export const ROUTE_PATH_FLEET_TRUCKS_EDIT = '/fleet/truck/:guid/edit';
export const ROUTE_PATH_FLEET_VENDOR_EDIT = '/fleet/vendor/:guid/edit';
export const ROUTE_PATH_FLEET_DRIVERS_EDIT = '/fleet/driver/:guid/edit';
export const ROUTE_PATH_FLEET_SERVICE_ISSUES_LIST = '/fleet/issues/list';
export const ROUTE_PATH_FLEET_TRAILERS_EDIT = '/fleet/trailer/:guid/edit';
export const ROUTE_PATH_GEO_FENCING_LOCATION = '/fleet/geo_fencing_location';
export const ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST = '/fleet/shared_component/list';
export const ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST = '/fleet/equipment_service/list';
export const ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST = '/fleet/driver_payroll_accessorial/list';
export const ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST = '/fleet/vendor_payroll_accessorial/list';
// fleet-profile
export const ROUTE_PATH_FLEET_PROFILE_TRUCK = '/fleet_profile/truck/:guid';
export const ROUTE_PATH_FLEET_PROFILE_DRIVER = '/fleet_profile/driver/:guid';
export const ROUTE_PATH_FLEET_PROFILE_VENDOR = '/fleet_profile/vendor/:guid';
export const ROUTE_PATH_FLEET_PROFILE_TRAILER = '/fleet_profile/trailer/:guid';
// carrier
export const ROUTE_PATH_CARRIERS_LIST = '/carriers/list';
export const ROUTE_PATH_CARRIERS_ACCEPT = '/carrier/accept';
export const ROUTE_PATH_CARRIER_EDIT = '/carriers/:guid/edit';
export const ROUTE_PATH_CARRIERS_DECLINE = '/carrier/decline';
export const ROUTE_PATH_CARRIERS_RESPONSE = '/carrier/response';
export const ROUTE_PATH_CARRIER_PROFILE = '/carrier_profile/:guid';
export const ROUTE_PATH_CARRIERS_ADD_MESSAGE_PAGE = '/tel/forCarrier';
// service vendor
export const ROUTE_PATH_SERVICE_VENDOR_LIST = '/service-vendor/list';
export const ROUTE_PATH_SERVICE_VENDOR_EDIT = '/service-vendor/:guid/edit';
// carrier rating
export const ROUTE_PATH_CARRIER_RATING_REPORT = '/carrierRating/report';
// route
export const ROUTE_PATH_CLO = '/route/clo/:guid';
export const ROUTE_PATH_TEL = '/route/tel/:guid';
export const ROUTE_PATH_CLO_LIST = '/routes/clo/list';
export const ROUTE_PATH_TEL_LIST = '/routes/tel/list';
export const ROUTE_PATH_LOAD_PLANNER = '/load/planner';
export const ROUTE_PATH_ROUTE_BUILDER = '/route/builder';
export const ROUTE_PATH_ROUTES_LIST = '/routes/:loadType/list';
export const ROUTE_PATH_LOAD = '/route/:type/:guid/:activeTab';
export const ROUTE_PATH_ROUTE_MASS_CREATE_REPORT = '/route/massCreateReport/list';
// new routes
export const ROUTE_PATH_EDI_LIST = '/edi/list';
export const ROUTE_PATH_ROUTE_LOAD = '/route/load/:guid';
export const ROUTE_PATH_ROUTE_ORDER = '/route/order/:guid';
export const ROUTE_PATH_CARRIER_EDI_LIST = '/carrier_edi/list';
export const ROUTE_PATH_DISPATCH_BOARD_TEL = '/dispatch/board/tel';
export const ROUTE_PATH_DISPATCH_BOARD_CLO = '/dispatch/board/clo';
// clo
export const ROUTE_PATH_CLO_REPORT_LIST = '/clo/list';
// tel
export const ROUTE_PATH_TEL_REPORT_LIST = '/tel/list';
// route
export const ROUTE_PATH_ROUTE_REPORT_LIST = '/route/list';
// invoice/payroll
// TODO: use ROUTE_PATH_PAYROLLS instead of ROUTE_PATH_DRIVER_PAYROLL_LIST
export const ROUTE_PATH_PAYROLLS = '/payrolls';
export const ROUTE_PATH_DRIVER_INVOICES = '/invoice';
export const ROUTE_PATH_DRIVER_PAYROLL_LIST = '/payrolls';
export const ROUTE_PATH_DRIVER_INVOICES_LIST = '/invoice/drivers';
export const ROUTE_PATH_VENDOR_INVOICES_LIST = '/invoice/vendors';
export const ROUTE_PATH_CARRIER_INVOICES_LIST = '/invoice/carriers';
export const ROUTE_PATH_PAYROLL_REPORT_LIST = '/driverPayroll/list';
export const ROUTE_PATH_CUSTOMER_INVOICES_LIST = '/invoice/customers';
export const ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST = '/master_invoices';
export const ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST = '/invoice/service_vendor';
// report
export const ROUTE_PATH_REPORTS_LIST = '/reports/list';
export const ROUTE_PATH_REPORTS_DRAFT = '/reports/draft';
export const ROUTE_PATH_REPORTS_EDIT = '/reports/:guid/edit';
export const ROUTE_PATH_REPORTS_ROUTE_DRAFT = '/reports/route/draft';
export const ROUTE_PATH_REPORTS_ROUTE_EDIT = '/reports/route/:guid/edit';
// styling
export const ROUTE_PATH_STYLING = '/styling';
export const ROUTE_PATH_SPLASH_SCREEN_SETTINGS = '/splash-screen-settings';
// chart
export const ROUTE_PATH_CHARTS_NEW = '/charts/new';
export const ROUTE_PATH_CHARTS_LIST = '/charts/list';
export const ROUTE_PATH_CHARTS_EDIT = '/charts/:guid/edit';
// notification
export const ROUTE_PATH_NOTIFICATIONS = '/notifications';
// loadboard
export const ROUTE_PATH_LOAD_BOARD = '/loadboard';
// import
export const ROUTE_PATH_IMPORT = '/import';
// intl
export const ROUTE_PATH_INTL = '/intl';
// load
export const ROUTE_PATH_DO_NEW = '/do/new';
export const ROUTE_PATH_LITE_DO_NEW = '/order/new';
export const ROUTE_PATH_LOAD_DETAILS = '/load/details';
export const ROUTE_PATH_DO_NEW_FROM_BY_SOURCE_TYPE = '/do/new/:sourceType/:guid';
export const ROUTE_PATH_LITE_DO_NEW_BY_SOURCE_TYPE = '/order/new/:sourceType/:guid';
// dashboard
export const ROUTE_PATH_DASHBOARD = '/dashboard';
export const ROUTE_PATH_ANALYTICS = '/analytics';
export const ROUTE_PATH_DASHBOARDS = '/dashboards';
export const ROUTE_PATH_DASHBOARDS_PAYROLL = '/dashboards-payroll';
// fuel-cards
export const ROUTE_PATH_FUEL_CARDS = '/fuel_cards';
// toll charges
export const ROUTE_PATH_TOLL_CHARGES = '/toll_charges';
// ifta report
export const ROUTE_PATH_IFTA_REPORT = '/ifta_report';
// templates
export const ROUTE_PATH_TEMPLATES = '/templates';
export const ROUTE_PATH_TEMPLATES_DO_LIST = '/templates/do';
export const ROUTE_PATH_TEMPLATES_ROUTE_LIST = '/templates/route';
export const ROUTE_PATH_TEMPLATE_DO_DETAILS = '/template/:guid/edit';
export const ROUTE_PATH_TEMPLATES_INSPECTION = '/templates/inspection';
export const ROUTE_PATH_TEMPLATE_CONTAINER_LIST = '/templates/containers';
export const ROUTE_PATH_CONTAINER_TYPE_LIST = '/templates/container_types';
export const ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS = '/template/:guid/update';
export const ROUTE_PATH_TEMPLATES_COMPENSATION_LIST = '/templates/compensation';
// load-planner-template
export const ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST = '/load_planner_template';
// contact-book
export const ROUTE_PATH_CONTACT_BOOK = '/contact_book';
// shared-accessorials
export const ROUTE_PATH_SHARED_ACCESSORIALS = '/shared_accessorials';
// average-fuel-price
export const ROUTE_PATH_AVERAGE_FUEL_PRICE = '/average_fuel_price';
// carrier contracts
export const ROUTE_PATH_CARRIER_CONTRACTS = '/carrier_contracts';
export const ROUTE_PATH_CARRIER_CONTRACT_DETAILS = '/carrier_contract/details/:guid';
// customer contracts
export const ROUTE_PATH_CUSTOMER_CONTRACTS = '/customer_contracts';
export const ROUTE_PATH_CUSTOMER_CONTRACT_DETAILS = '/customer_contract/details/:guid';
// quotes
export const ROUTE_PATH_QUOTE = '/quotes';
// order quotes
export const ROUTE_PATH_DO_QUOTE = '/do/quote';
export const ROUTE_PATH_ORDER_QUOTES = '/order_quotes';
export const ROUTE_PATH_ORDER_QUOTE_ACCEPT = '/orderQuote/accept';
export const ROUTE_PATH_ORDER_QUOTE_DECLINE = '/orderQuote/decline';
// geo fencing zone rating
export const ROUTE_PATH_GEO_FENCING_ZONE = '/geo_fencing_zone';
// geo fencing zone report
export const ROUTE_PATH_GEO_FENCING_ZONE_REPORT = '/geo_fencing_zone_report';
// drivers card
export const ROUTE_PATH_DRIVERS_CARD = '/drivers_card';
// dispatch group
export const ROUTE_PATH_DISPATCH_GROUP = '/dispatch_group';
// public clo
export const ROUTE_PATH_PUBLIC_CLO_LIST = '/public_clo/list';
// normalize currency list
export const ROUTE_PATH_NORMALIZE_CURRENCY_LIST = '/normalize_currency/list';
// commission assignment
export const ROUTE_PATH_COMMISSION_ASSIGNMENT = '/commission_assignment';
// customer portal
export const ROUTE_PATH_CUSTOMER_PORTAL = '/customer_portal';
// import report
export const ROUTE_PATH_IMPORT_REPORT = '/import_report';
// charge report
export const ROUTE_PATH_CLO_RATE_CHARGE_REPORT = '/clo_rate_charge_report';
export const ROUTE_PATH_TEL_RATE_CHARGE_REPORT = '/tel_rate_charge_report';
// carrier quotes
export const ROUTE_PATH_CARRIER_QUOTE_ACCEPT = '/carrierQuote/accept';
export const ROUTE_PATH_CARRIER_QUOTE_DECLINE = '/carrierQuote/decline';
// task-management
export const ROUTE_PATH_TASK_MANAGEMENT_BOARD = '/task_management/board';
export const ROUTE_PATH_TASK_MANAGEMENT_REPORT = '/task_management/report';
export const ROUTE_PATH_TASK_MANAGEMENT_BOARD_LIST = '/task_management/board_list';
// rate shop
export const ROUTE_PATH_RATE_SHOP = '/rate_shop';
// los subscription
export const ROUTE_PATH_LOS_SUBCRIPTION_LIST = '/los_subscription/list';
// available driver
export const ROUTE_PATH_AVAILABLE_DRIVER_LIST = '/available_driver/list';
// claim management
export const ROUTE_PATH_CLAIM_MANAGEMENT_LIST = '/claim_management/list';
export const ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS = '/claim_management/claim_details/:guid';
// work order
export const ROUTE_PATH_WORK_ORDER_LIST = '/work_order/list';
// Components and Assets for development
export const ROUTE_PATH_DEV_ICONS = '/dev/icons';
export const ROUTE_PATH_DEV_COMPONENTS = '/dev/components';
// inspections
export const ROUTE_PATH_INSPECTIONS = '/inspections';
